import React from "react";
import { StateApp } from "../States"
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import { goToLogin, } from "./login.components";


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...StateApp, isLoading: true, }
  }

  componentDidMount() {
    const route = (window.location.pathname.split('/'))[1]
    const { navigate } = this.props.router
    let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false)
    if (auth) {
      auth = parseInt(JSON.parse(localStorage.ws_santori_auth).type)
    }
    let authName = ''

    switch (auth) {
      case 1:
        authName = 'admin'
        break;
      case 2:
        authName = 'pos'
        break;
      case 3:
        authName = 'supervisor'
        break;
      case 4:
        authName = 'contabilidad'
        break;
      default:
        authName = 'login'
        break;
    }
    if (authName !== route) {
      setTimeout(() => {
        navigate(`/${authName}`)
      }, 500)
    } else {
      this.setState({ isLoading: false, })
    }
  }

  render() {
    const { isLoading, user, pass } = this.state
    if (isLoading) {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
            <svg fill='none' className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
              <path clipRule='evenodd'
                d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                fill='currentColor' fillRule='evenodd' />
            </svg>
            <div>Cargando...</div>
          </div>
        </div>
      )
    }

    return (
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              className="mx-auto h-20 w-auto"
              src="https://app.santori.com.mx/img/logo-santori.png"
              alt="Santori SA De CV"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
              Inicia sesión
            </h2>
          </div>
          <form className="space-y-6" action="#" method="POST" onSubmit={(ev) => goToLogin(ev, this)}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Usuario
                </label>
                <input
                  id="email-address"
                  name="text"
                  type="text"
                  autoComplete="text"
                  value={user}
                  onChange={(ev) => { this.setState({ user: ev.target.value, }) }}
                  required={false}
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Usuario"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Contraseña
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={pass}
                  onChange={(ev) => { this.setState({ pass: ev.target.value, }) }}
                  required={false}
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Contraseña"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm leading-6">
                <p className="cursor-pointer font-semibold text-indigo-600 hover:text-indigo-500">
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Iniciar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(Login)