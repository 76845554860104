import * as C from './sup.components';

export const render__homeSup = (state, setState, _this) => {
    return (
        <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
            <C.Saludo state={state} setState={setState} _this={_this} />
            <C.StatsWidgets state={state} setState={setState} _this={_this} />
            <C.Meta state={state} setState={setState} _this={_this} />
            <C.ListaVendedores state={state} setState={setState} _this={_this} />

            <C.Notificacion state={state} setState={setState} _this={_this} />
        </C.Dashboard>
    )
}

export const render__ventaDiaSup = (state, setState, _this) => {
    return (
        <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
            <C.Saludo state={state} setState={setState} _this={_this} />
            <C.VentaDelDia state={state} setState={setState} _this={_this} />

            <C.Notificacion state={state} setState={setState} _this={_this} />
        </C.Dashboard>
    )
}