import React                                               from "react";
import { StateApp }                                        from "../States"
import { useLocation, useNavigate, useParams, }            from "react-router-dom";


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = StateApp
  }

  componentDidMount() {
    const { navigate } = this.props.router
    
    setTimeout(() => {

      localStorage.removeItem('ws_santori_auth')
      navigate('/login')


    }, 3000)
  }

  render() {
		return (
			<div className="flex items-center justify-center w-full h-full">
        <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
          <svg fill='none' className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
            <path clipRule='evenodd'
              d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
              fill='currentColor' fillRule='evenodd' />
          </svg>           
          <div>Cerrando tu sesión, un momento...</div>
        </div>
      </div>
		);
	}
}


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter( Login )