import { HTTP_POST, } from "../http";

export const goToLogin = (ev, _this) => {
	ev.preventDefault();

	const { navigate } = _this.props.router
	const { user, pass } = _this.state

	const url = `login/get`
	const data = { user, pass }
	HTTP_POST(url, data, response => {
		if (response.data.auth === 0) {
			alert('Las credenciales ingresadas son incorrectas')
			return false
		}

		localStorage.setItem('ws_santori_auth', JSON.stringify(response.data))
		window.location = '/'
	})
}