/*
import axios from "axios";
export const HTTP_POST = (url, data, call) => {
  const URL_PROVIDER = 'https://app.santori.com.mx/REST/Controller'

  axios.defaults.withCredentials = true;

  axios.post(`${URL_PROVIDER}?opt=${url}`, data, {
    method: "POST",
    headers: {
      method: "POST",
      mode: "cors",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      credentials: true,
    },
    withCredentials: false,
    cors: {
      origin: true,
      credentials: true
    },
    credentials: true,
  })
  .then(response => response.json())
  .then(call)
  .catch(() => { call('error') })

}
*/

export const HTTP_POST = (url, data, call) => {
  const URL_PROVIDER = 'https://app.santori.com.mx/REST/Controller.php'
  // const URL_PROVIDER = 'https://app.santori.com.mx/REST/newController'
  // console.log('session', localStorage.ws_santori_auth, typeof localStorage.ws_santori_auth)
  const session = (typeof localStorage.ws_santori_auth !== 'undefined' ? JSON.parse(localStorage.ws_santori_auth) : {})


  fetch(`${URL_PROVIDER}?opt=${url}`,
    {
      method: "POST",
      mode: "cors",
      // headers: {
      //   mode: "cors",
      //   credentials: "include",
      // },
      body: JSON.stringify({ ...data, session: session }),
    }
  )
    .then(response => response.json())
    .then(call)
    .catch(() => { call('error') })
}

export const HTTP_UPLOAD = (url, data, call) => {
  const URL_PROVIDER = 'https://app.santori.com.mx/REST/Controller.php'
  const session = (typeof localStorage.ws_santori_auth !== 'undefined' ? JSON.parse(localStorage.ws_santori_auth) : {})

  // data.append("session", session)

  fetch(`${URL_PROVIDER}?opt=${url}`,
    {
      method: "POST",
      mode: "cors",
      body: data,
    }
  )
    .then(response => response.json())
    .then(call)
    .catch(() => { call('error') })
}
