import { HTTP_POST, } from "../http";
import moment from 'moment'
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/20/solid'
export const textoFormaDePago = (forma_pago) => {
  if (forma_pago === 'contado')
    return 'Pago de contado'
  else if (forma_pago === '15')
    return 'Crédito a 15 días'
  if (forma_pago === '30')
    return 'Crédito a 30 días'
  if (forma_pago === 'otro')
    return 'A convenir del vendedor'
  else
    return ''

}

export const textFormaPagoCobranza = (forma_pago) => {
  if (forma_pago === 'efectivo')
    return 'Efectivo'
  else if (forma_pago === 't_credito')
    return 'T. Crédito'
  else if (forma_pago === 't_debito')
    return 'T. Débito'
  else if (forma_pago === 'SPEI')
    return 'Transferencia E.'
  else if (forma_pago === 'deposito')
    return 'Depósito'
  else if (forma_pago === 'sie')
    return 'Convenio SIE'
  else
    return ''

}

export const number_format = (str, long) => {
  if (str == null) str = 0;
  return parseFloat(str)
    .toFixed(typeof long === 'undefined' ? 2 : long)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const calculadorToneladas = (cantidad) => {
  if (!cantidad)
    return "0 TON"
  return `${(cantidad < 1000) ? (parseFloat(cantidad).toFixed(2) + ' KG') : ((cantidad / 1000).toFixed(2) + ' TON')}`
}

export const handleAccionarSlider = (state, setState, _this, slider, opt) => {
  setState({ [slider]: opt })

}

export const input__dinamico__cambiaValor = (state, setState, _this, opt, value) => {
  const { sidebarInputs } = state
  setState({ [opt]: value })
}

export const autorizarVenta = (state, setState, _this) => {
  if (!window.confirm('¿Estás seguro que deseas autorizar esta orden de venta?'))
    return false

  const url = `administrador/autorizarOrdenVenta`
  const data = { id_orden: state.id_orden_selected }
  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    setState({
      slideInformacionVenta: false,
      arrProductosVentas: [],
      arrPagos: [],
      id_orden_selected: '',
    })

    obtenerHistorialDeVentasPorAutorizar(state, setState, _this)
    mostrarNotificacion(
      state, setState, _this,
      {
        icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
        titulo: 'Venta autorizada',
        descripcion: 'La venta fue autorizada con éxito, se ha notificado al vendedor para su posterior carga de cobranza.',
        timeout: 5000
      }
    )
  })
}

export const mostrarNotificacion = (state, setState, _this, notificacion) => {
  setState({ notificacion })
  setTimeout(() => {
    setState({
      notificacion: {},
    })
  }, notificacion.timeout)
}

export const initData = (state, setState, _this, fecha_progreso) => {
  const url = `administrador/obtenerMiInformacionDeUsuario`
  const data = { fecha_progreso: fecha_progreso ? fecha_progreso : state.fecha_progreso }
  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    let { arrProgresoCorporativo, } = response

    setState({
      internet: true,
      arrProgresoCorporativo
    })
  })
}

export const obtenerHistorialDeVentasPorAutorizar = (state, setState, _this) => {
  const url = `administrador/obtenerHistorialDeVentasPorAutorizar`
  const data = {}
  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    setState({
      internet: true,
      arrListaVentasPorAutorizar: response.data
    })
  })
}

export const obtenerInformacionConfiguraciones = (state, setState, _this) => {
  const url = `administrador/obtenerInformacionConfiguraciones`
  const data = {}
  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    setState({
      internet: true,
      ...response
    })
  })
}

export const obtenerInformacionOrden = (state, setState, _this, id_orden) => {
  let { nuevaVenta } = state
  const url = `pos/obtenerInformacionOrden`
  const data = { id_orden }
  setState({ slideInformacionVenta: true })
  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false
    const { productos, pagos, requiere_auth, } = response

    setState({ arrProductosVentas: productos, arrPagos: pagos, id_orden_selected: id_orden, requiere_auth, })
  })
}

export const realizarAccionSobreOrden = (typeAuth, id_orden) => {
  const url = `administrador/realizarAccionSobreOrden`
  const data = { typeAuth, id_orden }

  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false
  })
}

export const actualizarCostoProducto = (_this, infoProducto) => {
  const url = `administrador/actualizarCostoProducto`
  const data = { ...infoProducto, }

  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    _this.setState({
      infoProducto: {},
      openSlideActualizarProducto: false
    })
    window.location.reload()
  })
}

export const obtenerSucursales = (state, setState, _this) => {
  const url = `administrador/obtenerSucursales`
  const data = {}

  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    setState({
      arrSucursales: response.sucursales,
    })
  })
}

export const obtenerInventariosYStockPorSucursal = (state, setState, _this, value) => {
  const url = `administrador/obtenerInventariosYStockPorSucursal`
  const data = { id_sucursal: value, }

  setState({ id_sucursal_selected: value, arrGeneral: [] })

  HTTP_POST(url, data, response => {
    if (response === 'error')
      return false

    setState({
      ...response
    })
  })
}

export const registrarEntradaAlmacen = (state, setState, _this, call) => {
  const { arrGeneral, conceptoEntradaAlmacen, observacionEntradaAlmacen, } = state

  let canRegister = false,
    arrProductosEntradaAlmacen = []

  arrGeneral.map(t => {
    if (t.nueva_cantidad) {
      arrProductosEntradaAlmacen.push(t)
      canRegister = true
    }
  })

  if (canRegister && conceptoEntradaAlmacen !== "") {

    const url = `administrador/nuevaEntradaAlmacen`
    const data = { id_sucursal: state.id_sucursal_selected, arrProductosEntradaAlmacen, concepto: conceptoEntradaAlmacen, observacion: observacionEntradaAlmacen, }

    HTTP_POST(url, data, response => {
      if (response === 'error')
        return false

      setState({
        conceptoEntradaAlmacen: '',
        observacionEntradaAlmacen: '',
      })
      obtenerInventariosYStockPorSucursal(state, setState, _this, state.id_sucursal_selected)
      call(true)
    })
  } else {
    mostrarNotificacion(
      state, setState, _this,
      {
        icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
        titulo: 'Aviso',
        descripcion: 'No haz ingresado la cantidad de ningún producto.',
        timeout: 5000
      }
    )
  }

}

export const obtenerCreditos = (state, setState, _this) => {
  const url = `administrador/obtenerListadoDeCreditosPorVendedor`
  const data = {}
  HTTP_POST(url, data, response => {
    if (typeof response === 'error')
      return false

    setState({
      arrCreditos: response.arrVendedores
    })
  })
}

export const obtenerSolicitudCreditoCliente = (state, setState, _this, id_cliente) => {
  const url = `administrador/obtenerSolicitudCreditoCliente`
  const data = { id_cliente, }
  HTTP_POST(url, data, response => {
    if (typeof response === 'error')
      return false

    setState({
      // arrCreditos: response.arrVendedores
      arrSolicitud: response.solicitud_credito
    })
  })
}

export const fichaCliente__registraCambioCredito = (state, setState, _this) => {
  const { limite_credito, id_cliente, id_vendedor } = state

  if (id_vendedor !== '') {
    // Se consultan sus métricas de datos rápidos
    const url = `administrador/modificarLineaCreditoVendedor`
    const data = { id_vendedor, credito: limite_credito }

    HTTP_POST(url, data, response => {
      setState({ id_vendedor: '', limite_credito: 0, slideAumentarCredito: false, })
      mostrarNotificacion(
        state, setState, _this,
        {
          icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
          titulo: 'Crédito actualizado',
          descripcion: 'Se modificó el límite de crédito exitosamente.',
          timeout: 5000
        }
      )

      obtenerCreditos(state, setState, _this)
    })
  } else if (id_cliente !== '') {
    // Se consultan sus métricas de datos rápidos
    const url = `administrador/modificarLineaCredito`
    const data = { id_cliente, credito: limite_credito }

    HTTP_POST(url, data, response => {
      setState({ id_cliente: '', limite_credito: 0, slideAumentarCredito: false, })
      mostrarNotificacion(
        state, setState, _this,
        {
          icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
          titulo: 'Crédito actualizado',
          descripcion: 'Se modificó el límite de crédito exitosamente.',
          timeout: 5000
        }
      )

      obtenerCreditos(state, setState, _this)
    })
  }
}

export const autorizarLineaCredito = (state, setState, _this, opt) => {
  const { id_cliente } = state

  // Se consultan sus métricas de datos rápidos
  const url = `administrador/autorizarLineaCredito`
  const data = { id_cliente, opt }

  HTTP_POST(url, data, response => {
    setState({ id_cliente: '', })
    mostrarNotificacion(
      state, setState, _this,
      {
        icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
        titulo: 'Solicitud actualizada',
        descripcion: opt === 3 ? 'Se autorizó la línea de crédito' : 'Línea de crédito no autorizada',
        timeout: 5000
      }
    )

    obtenerCreditos(state, setState, _this)
  })
}