import * as C from './cont.components';

export const render__homeCont = (state,setState,_this) => {
    return(
        <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
            <C.Saludo /> 
            <C.HistorialVentas state={state} setState={setState} _this={_this} />
            <C.SlideOverFacturarVenta state={state} setState={setState} _this={_this} />
            {/* <C.Meta /> */}
        </C.Dashboard>
    )
}