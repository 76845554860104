import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import moment from 'moment'
import * as F from './pos.functions';
import * as view from './pos.views';
import { Dialog, Menu, Transition } from '@headlessui/react';

import {
	Bars3Icon,
	BellIcon,
	Cog6ToothIcon,
	HomeIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, } from '@heroicons/react/20/solid'

moment.locale('es');

const navigation = [
	{ name: 'Inicio', href: '#', icon: HomeIcon, current: true },
	{ name: 'Mis ventas', href: '#', icon: HomeIcon, current: false },
	{ name: 'Requisiciones', href: '#', icon: HomeIcon, current: false },
]

const makeProcessing = () => {
	return (
		<div className="flex items-center justify-center w-full h-full">
			<div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
				<svg fill='none' className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
					<path clipRule='evenodd'
						d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
						fill='currentColor' fillRule='evenodd' />
				</svg>
				<div>Procesando información...</div>
			</div>
		</div>
	)
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class _PosIndex extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		F.initData(this.props.initState, this.props.initsetState, this.props)
	}

	render() {
		const { navigate } = this.props.router
		return (
			<div>
				{view.render__homePos(this.props.initState, this.props.initsetState, this)}
			</div>
		);
	}
}

class _PosBuscarCliente extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// F.initData(this)
	}

	render() {
		const { navigate } = this.props.router
		return (
			<div>
				{view.render__buscarCliente(this.props.initState, this.props.initsetState, this)}
			</div>
		);
	}
}

class _PosFichaCliente extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })
		}, 1000)

		const { initState, initsetState, } = this.props
		const { arrInputsDireccionEntrega, inputsNuevaRazonSocial } = initState;
		F.obtenerFichaCliente(initState, initsetState, this, this.props.router.params.id_cliente);
		arrInputsDireccionEntrega.id_cliente = this.props.router.params.id_cliente;
		inputsNuevaRazonSocial.id_cliente = this.props.router.params.id_cliente;
		initsetState({ arrInputsDireccionEntrega, inputsNuevaRazonSocial })

	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__fichaCliente(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

class _PosCarrito extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {
		const { initState, initsetState, } = this.props
		F.getProductos(initState, initsetState, this);

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })
		}, 1000)

		F.obtenerFichaCliente(initState, initsetState, this, this.props.router.params.id_cliente);
	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__carrito(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

class _PosCarritoCantidades extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })
		}, 1000)

		const { initState, initsetState, } = this.props
		const { id_cliente } = initState

		if (id_cliente === '') {
			setTimeout(() => {
				this.props.router.navigate('/pos/carrito/' + this.props.router.params.id_cliente)
			}, 1000)
		}
	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__carritoCantidades(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

class _PosCarritoCobranza extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })
		}, 1000)

		const { initState, initsetState, } = this.props
		const { id_cliente } = initState

		if (id_cliente === '') {
			setTimeout(() => {
				this.props.router.navigate('/pos/carrito/' + this.props.router.params.id_cliente)
			}, 1000)
		}
	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__carritoCobranza(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

class _PosClientes extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })
		}, 1000)

		const { initState, initsetState, } = this.props
		const { id_cliente } = initState

		F.obtenerListaclientes(this.props.state, this.props.initsetState, this.props._this);
	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__clientes(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

class _PosVentas extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isProcessing: false }
	}

	componentDidMount() {
		const { initState, initsetState, } = this.props
		const { id_cliente } = initState

		this.setState({ isProcessing: true })
		setTimeout(() => {
			this.setState({ isProcessing: false })

		}, 1000)

		F.obtenerListaVentas(initState, initsetState, this.props._this);
	}

	render() {
		if (this.state.isProcessing)
			return makeProcessing()


		return (
			<div>
				{view.render__ventas(this.props.initState, this.props.initsetState, this)}
			</div>
		)
	}

}

const Route_Pos = withRouter(_PosIndex)
const Route_PosBuscarCliente = withRouter(_PosBuscarCliente)
const Route_PosFichaCliente = withRouter(_PosFichaCliente)
const Route_PosCarrito = withRouter(_PosCarrito);
const Route_PosCarritoCantidades = withRouter(_PosCarritoCantidades);
const Route_PosCarritoCobranza = withRouter(_PosCarritoCobranza);
const Route_PosClientes = withRouter(_PosClientes);
const Route_PosVentas = withRouter(_PosVentas);

export {
	Route_Pos,
	Route_PosBuscarCliente,
	Route_PosFichaCliente,
	Route_PosCarrito,
	Route_PosCarritoCantidades,
	Route_PosCarritoCobranza,
	Route_PosClientes,
	Route_PosVentas,
}












