import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import * as F from './sup.functions';
import { Dialog, Menu, Transition } from '@headlessui/react'
import moment from 'moment'
import {
	Bars3Icon,
	BellIcon,
	CalendarIcon,
	Cog6ToothIcon,
	FolderIcon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
	ExclamationTriangleIcon,
	AcademicCapIcon,
	BanknotesIcon,
	ReceiptRefundIcon,
	UserCircleIcon,
	ScaleIcon,
	ChartBarIcon,
	ChartPieIcon,
	DocumentTextIcon,
	DocumentCheckIcon,
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, BuildingOfficeIcon, CreditCardIcon, HandThumbUpIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/20/solid'
import { textoFormaDePago } from "../PoS/pos.functions";


const userNavigation = [
	{ name: 'Perfil', href: '#' },
	{ name: 'Cerrar sesión', href: '/logout' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			session: {},
			sidebarOpen: false,
			navigation: [
				{ name: 'Inicio', href: '#', icon: HomeIcon, current: true, to: '/supervisor' },
				{ name: 'Venta del día', href: '#', icon: FolderIcon, current: false, to: '/supervisor/venta' },
			]
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);
		const { _this } = this.props;
		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			}, () => {
				let rutaActual = _this.props.router.location;
				const { navigation } = this.state;
				let newNav = navigation.map(item => ({
					...item,
					current: item.to === rutaActual.pathname ? true : false,
				}));
				this.setState({ navigation: newNav })
			})
		}
	}

	setSideBarOpen = (status) => this.setState({ sidebarOpen: status })

	render() {
		const { sidebarOpen, navigation } = this.state;
		const { children } = this.props
		const { session } = this.state;

		return (
			<>
				<div className={'dark:bg-slate-900'}>
					<Transition.Root show={sidebarOpen} as={Fragment}>
						<Dialog as="div" className="relative z-50 lg:hidden" onClose={() => this.setSideBarOpen(false)}>
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-gray-900/80" />
							</Transition.Child>

							<div className="fixed inset-0 flex">
								<Transition.Child
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transition ease-in-out duration-300 transform"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
										<Transition.Child
											as={Fragment}
											enter="ease-in-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in-out duration-300"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
												<button type="button" className="-m-2.5 p-2.5" onClick={() => this.setSideBarOpen(false)}>
													<span className="sr-only">Close sidebar</span>
													<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
												</button>
											</div>
										</Transition.Child>
										{/* Sidebar component, swap this element with another sidebar if you like */}
										<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
											<div className="flex h-16 shrink-0 items-center">
												<img
													className="h-8 w-auto"
													src="https://app.santori.com.mx/img/logo-santori.png"
													alt="Santori SA De CV"
												/>
											</div>
											<nav className="flex flex-1 flex-col">
												<ul role="list" className="flex flex-1 flex-col gap-y-7">
													<li>
														<ul role="list" className="-mx-2 space-y-1">
															{navigation.map((item) => (
																<li key={item.name}>

																	<NavLink
																		to={item.to}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-red-600 dark:text-red-900'
																				: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden="true"
																		/>
																		{item.name}
																	</NavLink>																</li>
															))}
														</ul>
													</li>

													<li className="mt-auto">
														<a
															href="#"
															className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
														>
															<Cog6ToothIcon
																className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
																aria-hidden="true"
															/>
															Configuraciones
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition.Root>

					{/* Static sidebar for desktop */}
					<div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
							<div className="flex h-16 shrink-0 items-center">
								<img
									className="h-8 w-auto"
									src="https://app.santori.com.mx/img/logo-santori.png"
									alt="Santori SA De CV"
								/>
							</div>
							<nav className="flex flex-1 flex-col">
								<ul role="list" className="flex flex-1 flex-col gap-y-7">
									<li>
										<ul role="list" className="-mx-2 space-y-1">
											{navigation.map((item) => (
												<li key={item.name}>
													<NavLink
														to={item.to}
														className={classNames(
															item.current
																? 'bg-gray-50 text-red-600 dark:text-red-900'
																: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
														)}
													>
														<item.icon
															className={classNames(
																item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</NavLink>
												</li>
											))}
										</ul>
									</li>
									<li className="mt-auto">
										<a
											href="#"
											className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
										>
											<Cog6ToothIcon
												className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
												aria-hidden="true"
											/>
											Configuraciones
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>

					<div className="lg:pl-72">
						<div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 dark:bg-slate-800 dark:border-gray-800">
							<button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => this.setSideBarOpen(true)}>
								<span className="sr-only">Abrir menú</span>
								<Bars3Icon className="h-6 w-6" aria-hidden="true" />
							</button>

							{/* Separator */}
							<div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

							<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
								<div className="relative flex flex-1"></div>
								<div className="flex items-center gap-x-4 lg:gap-x-6">
									<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</button>

									{/* Separator */}
									<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

									{/* Profile dropdown */}
									<Menu as="div" className="relative">
										<Menu.Button className="-m-1.5 flex items-center p-1.5">
											<span className="sr-only">Abrir menú de usuario</span>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 h-8 w-8 dark:text-white"><path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
											<span className="hidden lg:flex lg:items-center">
												<span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200" aria-hidden="true">
													{session.nombre} {session.apellidos}
												</span>
												<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
											</span>
										</Menu.Button>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-gray-900">
												{userNavigation.map((item) => (
													<Menu.Item key={item.name}>
														{({ active }) => (
															<a
																href={item.href}
																className={classNames(
																	active ? 'bg-gray-50 dark:bg-gray-500' : '',
																	'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-200'
																)}
															>
																{item.name}
															</a>
														)}
													</Menu.Item>
												))}
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>
						</div>

						<main className={this.props.paddingMain}>
							<div className={this.props.padding}>
								{children}
							</div>
						</main>
					</div>
				</div>
			</>
		)
	}
}

export class Saludo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			session: {},
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);

		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			})
		}

	}

	render() {
		const { session } = this.state;
		let hourOfDay = moment().format('H')

		let bienvenida = ''
		if (hourOfDay < 12)
			bienvenida = 'Buenos días'
		else if (hourOfDay >= 12 && hourOfDay < 19)
			bienvenida = 'Buenas tardes'
		else if (hourOfDay >= 19 && hourOfDay <= 23)
			bienvenida = 'Buenas noches'

		return (
			<div className="mb-3">
				<h1 className="text-lg font-semibold dark:text-gray-300">{bienvenida}, {session.nombre} {session.apellidos}</h1>
				<hr className="mt-4 mb-4" />
			</div>
		)
	}
}

export class StatsWidgets extends React.Component {
	render() {
		const { ordenes_pendientes, por_cobrar, tendencia, toneladas_al_dia, toneladas_autorizadas, toneladas_no_autorizadas, facturas, } = this.props.state

		return (
			<div>
				<h3 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-gray-200">Actividad de este mes</h3>
				<p className="text-md pt-2 leading-6 text-gray-900 dark:text-gray-200">Se consideran únicamente todas las operaciones autorizadas por el administrador.</p>

				<div class="grid grid-cols-12 gap-6 mt-5 mb-5">
					<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
						<div
							class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

							<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
								<div class="flex">
									<ChartBarIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
								</div>
								<div class="mt-6 text-3xl font-medium leading-8 dark:text-white">{F.calculadorToneladas(toneladas_autorizadas)}</div>
								<p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200">Pendiente de autorizar: {F.calculadorToneladas(toneladas_no_autorizadas)}</p>
								<div class="mt-1 text-base text-slate-500 dark:text-gray-200">TON vendidas</div>
							</div>

						</div>
					</div>

					<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
						<div
							class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

							<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
								<div class="flex">
									<ChartPieIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
								</div>
								<div class="mt-6 text-3xl font-medium leading-8 dark:text-white">{F.number_format(tendencia, 2)}%</div>
								<p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200 opacity-0">-</p>
								<div class="mt-1 text-base text-slate-500 dark:text-gray-200">Tendencia de ventas</div>
							</div>

						</div>
					</div>

					<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
						<div
							class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

							<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
								<div class="flex">
									<BanknotesIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
								</div>
								<div class="mt-6 text-3xl font-medium leading-8 dark:text-white">${F.number_format(por_cobrar, 2)}</div>
								<p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200 opacity-0">-</p>
								<div class="mt-1 text-base text-slate-500 dark:text-gray-200">Por cobrar (créditos)</div>
							</div>

						</div>
					</div>

					<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
						<div
							class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

							<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
								<div class="flex">
									<DocumentTextIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
								</div>
								<div class="mt-6 text-3xl font-medium leading-8 dark:text-white">{F.number_format(ordenes_pendientes, 0)}</div>
								<p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200 opacity-0">-</p>
								<div class="mt-1 text-base text-slate-500 dark:text-gray-200">Ventas pendientes de aut.</div>
							</div>

						</div>
					</div>

					<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
						<div
							class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

							<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
								<div class="flex">
									<DocumentCheckIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
								</div>
								<div class="mt-6 text-3xl font-medium leading-8 dark:text-white">{F.number_format(facturas?.total_facturadas, 0)} de {F.number_format(facturas?.total, 0)}</div>
								<p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200 opacity-0">-</p>
								<div class="mt-1 text-base text-slate-500 dark:text-gray-200">Facturas realizadas</div>
							</div>

						</div>
					</div>


				</div>
			</div>
		)
	}
}


export class Meta extends React.Component {

	render() {

		const { arrMisMetas } = this.props.state

		return (

			<section className="py-3">
				<div>
					<div className="py-4 bg-white rounded dark:bg-slate-800 shadow-[0px_3px_5px_#0000000b]">
						<div className="px-6 pb-6 border-b border-blue-50 dark:border-slate-700">
							<h3 className="text-xl font-bold dark:text-slate-100">Mis metas</h3>
							<p className="text-sm text-gray-500 dark:text-slate-400">A continuación se muestra tu progreso</p>
						</div>
						<div class="space-y-2.5 border-t border-stroke p-4 dark:border-strokedark md:p-6 xl:p-7.5">
							{

								arrMisMetas.map((t, e) => {
									let semaforo = '',
										valor = parseInt(t.valor) > 100 ? 100 : parseInt(t.valor)
									if (valor < 35)
										semaforo = 'red'
									else if (valor >= 35 && valor < 60)
										semaforo = 'yellow'
									else
										semaforo = 'green'

									const textClass = `text-${semaforo}-500`,
										bg50Class = `bg-${semaforo}-50`,
										bg500Class = `bg-${semaforo}-500`

									return (
										<div class="items-center sm:flex">
											<div class="flex w-[35%] items-center gap-2">
												<p class="font-medium text-black dark:text-white">{t.nombre}</p>
											</div>
											<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 bg-gray-100">
												<div class={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white ${bg500Class}`} style={{ width: valor + '%' }}>
													{valor}%
												</div>
											</div>
										</div>
									)
								})
							}
						</div>

					</div>
				</div>
			</section >

		)

	}
}

export class ListaVendedores extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this, } = this.props
		// const { listado } = this.props;
		const { arrVendedores } = state

		return (
			<section className="py-3">
				<div>
					<div className="py-4 bg-white rounded dark:bg-slate-800 shadow-[0px_3px_5px_#0000000b]">
						<div className="px-6 pb-2">
							<h3 className="text-xl font-bold dark:text-slate-100">Mis vendedores</h3>
							<p className="text-sm text-gray-500 dark:text-slate-400">A continuación se muestran tus vendedores asignados a tu sucursal</p>
						</div>
					</div>
				</div>

				<div class="space-y-2.5 md:p-2 xl:p-7.5">

					{
						arrVendedores.map((t, e) => {
							let porcentajeDia = (t.toneladas_al_dia / t.meta_ton_dia) * 100
							let porcentajeMes = (t.toneladas_autorizadas / t.meta_ton_mes) * 100

							return (

								<div class="grid grid-cols-0 gap-0 mt-1 mb-5">
									<div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
										<div
											class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-95 hover:scale-100 ease-in-out duration-300">

											<div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default rounded">
												<div
													class="flex flex-col items-center p-5 border-b lg:flex-row border-slate-200/60 dark:border-darkmode-400">
													<div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
														<img
															alt=""
															class="rounded-full"
															src="https://acyn.wsoft.com.mx/agentes/img/profile.png" />
													</div>
													<div class="mt-3 text-center lg:ml-2 lg:mr-auto lg:text-left lg:mt-0">
														<a href="" class="font-medium text-2xl dark:text-gray-300">{t.nombre} {t.apellidos}</a>
														<div class="text-red-500 dark:text-red-300 text-xs mt-0.5">
															<div class="cursor-pointer flex text-xs font-medium text-danger">
																Tendencia de ventas: {F.number_format(t.tendencia, 0)}%
															</div>
														</div>
													</div>
													<div class="flex mt-3 -ml-2 lg:ml-0 lg:justify-end lg:mt-0 opacity-0">
														Más información
													</div>
												</div>


												<div className="box">
													<div class="flex flex-wrap items-center justify-center p-5 lg:flex-nowrap">
														<div class="w-full mb-4 mr-auto lg:w-1/2 lg:mb-0 mx-4">
															<div class="flex text-xs text-slate-500">
																<div class="mr-auto dark:text-gray-300">Meta del día</div>
																<div>{F.number_format(porcentajeDia, 0)}%</div>
															</div>
															<div class="w-full bg-slate-200 rounded dark:bg-black/20 h-1 mt-2">
																<div
																	style={{ background: 'red', width: `${porcentajeDia > 100 ? 100 : porcentajeDia}%`, }}
																	class="h-full rounded text-xs text-white flex justify-center items-center w-1/4 bg-green"
																	role="progressbar"
																	aria-valuenow="80"
																	aria-valuemin="0"
																	aria-valuemax="100"></div>
															</div>
														</div>
														<div class="w-full mb-4 mr-auto lg:w-1/2 lg:mb-0 mx-4">
															<div class="flex text-xs text-slate-500">
																<div class="mr-auto">Meta del mes</div>
																<div>{F.number_format(porcentajeMes, 0)}%</div>
															</div>
															<div class="w-full bg-slate-200 rounded dark:bg-black/20 h-1 mt-2">
																<div
																	style={{ background: 'red', width: `${porcentajeMes > 100 ? 100 : porcentajeMes}%`, }}
																	class="h-full rounded text-xs text-white flex justify-center items-center w-1/4 bg-green"
																	role="progressbar"
																	aria-valuenow="60"
																	aria-valuemin="0"
																	aria-valuemax="100"></div>
															</div>
														</div>
													</div>
												</div>

												<div class="col-span-12 intro-y md:col-span-6">
													<div class="grid grid-cols-2 gap-4 px-2">
														<div
															class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700 mb-6">
															<p class="text-sm text-gray-600 dark:text-gray-400">Pagos faltantes por cotejar</p>
															<p class="text-base font-medium text-navy-700 dark:text-white">{F.number_format(t.count_sin_cotejar, 0)}</p>
														</div>
														<div
															class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700 mb-6">
															<p class="text-sm text-gray-600 dark:text-gray-400">Toneladas por autorizar</p>
															<p class="text-base font-medium text-navy-700 dark:text-white">{F.calculadorToneladas(t.toneladas_no_autorizadas)}</p>
														</div>

														<div
															class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
															<p class="text-sm text-gray-600 dark:text-gray-400">Toneladas producidas</p>
															{this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">{F.calculadorToneladas(t.toneladas_autorizadas)}</p>)}
														</div>
														<div
															class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
															<p class="text-sm text-gray-600 dark:text-gray-400">Por cobrar (créditos)</p>
															{this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">${F.number_format(t.por_cobrar, 2)}</p>)}
														</div>
														<div
															class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
															<p class="text-sm text-gray-600 dark:text-gray-400">Ventas pendientes de autorización</p>
															{this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">{F.number_format(t.ordenes_pendientes, 0)}</p>)}
														</div>

													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							)
						}
						)
					}

				</div>
			</section >
		)
	}

	badgePorcentaje(porcentaje, children) {
		return (
			<div class="cursor-pointer flex text-xs font-medium">
				{children}
				<span>
					<div class="cursor-pointer flex ml-2 text-md relative font-medium text-black dark:text-white">
						{porcentaje}%
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							class="stroke-[1.3] w-4 h-4 ml-0.5">
							{
								porcentaje > 50
									? <polyline points="18 15 12 9 6 15"></polyline>
									: <polyline points="6 9 12 15 18 9"></polyline>
							}
						</svg>
					</div>
				</span>
			</div>
		)
	}
}

export class VentaDelDia extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id_orden_pago: ''
		}
	}

	componentDidMount() {
		const { state, setState, _this } = this.props;
		if (state.arrOrdenesVenta.length === 0)
			F.getOrdenesDeVenta(state, setState, _this)
	}

	render() {
		const { state, setState, _this } = this.props;
		const { arrVendedores, arrOrdenesVenta, id_vendedor, fecha_pagos } = state;

		return (
			<React.Fragment>
				<h3 className="text-base font-semibold leading-6 text-gray-900 mb-3">Venta del día</h3>
				<form className="bg-white p-5 rounded-md shadow-sm mb-3 dark:bg-gray-800">
					<div className="mb-3">
						<label htmlFor="input_historial_pago" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
							Fecha de pagos
						</label>
						<div className="mt-2">
							<input
								type="date"
								name="input_historial_pago"
								id="input_historial_pago"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								placeholder=""
								value={fecha_pagos}
								onChange={(e) => {
									setState({
										fecha_pagos: e.target.value
									})
								}}
							/>
						</div>
					</div>
					<button
						onClick={() => { F.getOrdenesDeVenta(state, setState, _this) }}
						type="button"
						className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Buscar
					</button>
				</form>
				<h3 className="text-base font-semibold leading-6 text-gray-900 mb-3">Historial de pagos</h3>
				<div className="w-full overflow-x-auto shadow-md sm:rounded-lg mt-5" style={{ minHeight: '250px' }}>
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400" style={{ minHeight: 500, }}>
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr>
								<th scope="col" className="px-6 py-3">
									Nombre del cliente
								</th>
								<th scope="col" className="px-6 py-3">
									Forma de pago
								</th>
								<th scope="col" className="px-6 py-3">
									Cantidad abonada
								</th>
								<th scope="col" className="px-6 py-3">
									Comprobante
								</th>
								<th scope="col" className="px-6 py-3">
									Fecha de pago
								</th>
								<th scope="col" className="px-6 py-3">
									Pago cotejado
								</th>
								<th scope="col" className="px-6 py-3">
									Opciones
								</th>
							</tr>
						</thead>
						<tbody>

							{
								arrOrdenesVenta.length > 0 ?
									arrOrdenesVenta.map((t, e) => (
										<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
											<th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
												{t.nombre} {t.apellidos}
											</th>
											<td className="px-6 py-4">
												{F.textFormaPagoCobranza(t.forma_pago)}
											</td>
											<td className="px-6 py-4">
												${F.number_format(t.cantidad)}
											</td>
											<td className="px-6 py-4">
												{t.venta_cotejada == 1 && <a href={`https://app.santori.com.mx/REST/docs/${t.id_orden_pago}`} target={'_blank'}>Ver comprobante</a>}
											</td>
											<td className="px-6 py-4">
												{moment(t.created_at).format("DD/MM/YYYY HH:mm")}
											</td>
											<td className="px-6 py-4">
												{t.venta_cotejada == 1 ? 'Si' : 'No'}
											</td>
											<td className="flex items-center px-6 py-4">
												<div className="relative">
													<Menu as="div" className="relative inline-block text-left">
														<div>
															<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
																Opciones
																<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
															</Menu.Button>
														</div>

														<Transition
															as={Fragment}
															enter="transition ease-out duration-100"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95"
														>
															<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																<div className="py-1">
																	<Menu.Item>
																		{({ active }) => (
																			<a
																				href="#"
																				className={classNames(
																					active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																					'block px-4 py-2 text-sm'
																				)}
																				onClick={() => {
																					this.setState(
																						{ id_orden_pago: t.id_orden_pago },
																						setState({ alertConfirmarComprobante: true })
																					)

																				}}
																			>
																				Confirmar comprobante
																			</a>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({ active }) => (
																			<a
																				href="#"
																				className={classNames(
																					active ? 'bg-gray-100 text-red-900' : 'text-red-800',
																					'block px-4 py-2 text-sm font-bold'
																				)}
																				onClick={() => {
																					setState({
																						alertConfirmarOrden: true,
																						pre_orden_eliminar: { ...t, type: 1 }
																					})

																				}}
																			>
																				Eliminar pago
																			</a>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({ active }) => (
																			<a
																				href="#"
																				className={classNames(
																					active ? 'bg-gray-100 text-red-900' : 'text-red-800',
																					'block px-4 py-2 text-sm font-bold'
																				)}
																				onClick={() => {
																					setState({
																						alertConfirmarOrden: true,
																						pre_orden_eliminar: { ...t, type: 2 }
																					})

																				}}
																			>
																				Eliminar venta
																			</a>
																		)}
																	</Menu.Item>
																</div>
															</Menu.Items>
														</Transition>
													</Menu>
												</div>
											</td>
										</tr>
									))
									:
									<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
										<th colSpan={6} className="text-center">No hay resultados</th>
									</tr>
							}


						</tbody>
					</table>
				</div>

				{/* MODAL CONFIRMAR COMPROBANTE */}
				<Transition.Root show={state.alertConfirmarComprobante} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={() => setState({ alertConfirmarComprobante: false })}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
											</div>
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
													Confirmar comprobante
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														¿Estás seguro que deseas confirmar el comprobante? Para confirmar es importante que adjuntes una evidencia de recibo.
													</p>

													<div className="mt-4 flex text-sm leading-6 text-gray-600">
														<label
															htmlFor={`input-upload-comprobante`}
															className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
														>
															<span>Subir archivo</span>
															<input id={`input-upload-comprobante`} name={`input-upload-comprobante`} type="file" className="sr-only" multiple={false}
																onChange={(ev) => {
																	const input_comprobante = {
																		value: this.state.id_orden_pago,
																		file: ev.target.files[0]
																	}
																	setState({ input_comprobante, })
																}} />
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
												onClick={() => F.actualizarVentaCotejada(state, setState, _this, this.state.id_orden_pago)}
											>
												Si, Confirmar
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
												onClick={() => setState({ alertConfirmarComprobante: false })}
											>
												Cancelar
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* MODAL ELIMINAR ORDEN */}
				<Transition.Root show={state.alertConfirmarOrden} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={() => setState({ alertConfirmarOrden: false })}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
											</div>
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
													Confirmar {state.pre_orden_eliminar == 1 ? 'pago' : 'orden de venta'}
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														¿Estás seguro que deseas realizar esta acción? <b className="text-red-700">No se puede deshacer</b>
													</p>
												</div>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
												onClick={() => {
													F.eliminaOrdenDePagoPorTipo(state, setState, _this, state.pre_orden_eliminar)
												}}
											>
												Si, Confirmar
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
												onClick={() => setState({ alertConfirmarOrden: false })}
											>
												Cancelar
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>

			</React.Fragment>
		)
	}
}

export class Notificacion extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this, } = this.props
		const { notificacion, } = state

		return (
			<div
				ariaLive="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={notificacion.titulo ? true : false}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										{notificacion.icono}
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-medium text-gray-900">{notificacion.titulo}</p>
										<p className="mt-1 text-sm text-gray-500">{notificacion.descripcion}</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => {
												setState({ notificacion: {} })
											}}
										>
											<span className="sr-only">Cerrar</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		)
	}
}