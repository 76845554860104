import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import * as F from './clientes.functions';
import { Dialog, Menu, Transition } from '@headlessui/react'
import moment from 'moment'
import {
	Bars3Icon,
	BellIcon,
	XMarkIcon,
	BanknotesIcon,
	Cog6ToothIcon,
	FolderIcon,
	HomeIcon,
	ChartBarIcon,
	ChartPieIcon,
	DocumentTextIcon,
	DocumentCheckIcon,

	PaperClipIcon,
	ShieldCheckIcon,
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, BuildingOfficeIcon, CreditCardIcon, HandThumbUpIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/20/solid'

import SignaturePad from 'react-signature-pad-wrapper'


const userNavigation = [
	{ name: 'Perfil', href: '#' },
	{ name: 'Cerrar sesión', href: '/logout' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			session: {},
			sidebarOpen: false,
			navigation: [
				{ name: 'Inicio', href: '#', icon: HomeIcon, current: true, to: '/supervisor' },
				{ name: 'Venta del día', href: '#', icon: FolderIcon, current: false, to: '/supervisor/venta' },
			]
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);
		const { _this } = this.props;
		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			}, () => {
				let rutaActual = _this.props.router.location;
				const { navigation } = this.state;
				let newNav = navigation.map(item => ({
					...item,
					current: item.to === rutaActual.pathname ? true : false,
				}));
				this.setState({ navigation: newNav })
			})
		}
	}

	setSideBarOpen = (status) => this.setState({ sidebarOpen: status })

	render() {
		const { sidebarOpen, navigation } = this.state;
		const { children } = this.props
		const { session } = this.state;

		return (
			<div className={'pt-0'}>
				<div className={'dark:bg-slate-900 container mx-auto'}>
					<main className={'py-3 mt-3'}>
						<div className={this.props.padding}>
							{children}
						</div>
					</main>
				</div>
			</div>
		)
	}
}

export class Saludo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			session: {},
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);

		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			})
		}

	}

	render() {
		const { session } = this.state;
		let hourOfDay = moment().format('H')

		let bienvenida = ''
		if (hourOfDay < 12)
			bienvenida = 'Buenos días'
		else if (hourOfDay >= 12 && hourOfDay < 19)
			bienvenida = 'Buenas tardes'
		else if (hourOfDay >= 19 && hourOfDay <= 23)
			bienvenida = 'Buenas noches'

		return (
			<div className="mb-3">
				<h1 className="text-lg font-semibold dark:text-gray-300">{bienvenida}, {session.nombre} {session.apellidos}</h1>
				<hr className="mt-4 mb-4" />
			</div>
		)
	}
}

export class StatsWidgets extends React.Component {
	render() {
		const { state, setState, _this } = this.props
		const { ficha, arrSolicitud, } = state
		const { id_cliente, } = _this.props.router.params

		return (
			<div>
				<h3 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-gray-200">Mi perfil de cliente</h3>
				<p className="text-md pt-2 leading-6 text-gray-900 dark:text-gray-200">Se consideran únicamente todas las operaciones autorizadas por el administrador.</p>

				<section aria-labelledby="applicant-information-title">
					<div className="bg-white dark:bg-slate-800 shadow sm:rounded-lg mt-10">
						<div className="px-4 py-5 sm:px-6">
							<h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
								{ficha?.nombre_completo}
							</h2>
							<p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-300">Ficha rápida de tu cliente.</p>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Nombre del cliente</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.nombre}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Apellido paterno</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.a_paterno}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Apellido materno</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.a_materno}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Teléfono</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.telefono}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Correo electrónico</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.correo}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Género</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.genero === '1' ? 'Masculino' : 'Femenino'}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Límite de crédito</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{F.formateaCantidad(ficha?.credito)}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">¿Es cliente relevante?</dt>
									<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{ficha?.prioridad === '1' ? 'Si' : 'No'}</dd>
								</div>
							</dl>
						</div>
					</div>
				</section>


				<div>
					<div className="px-4 sm:px-0 my-5">
						<h3 className="text-base font-semibold leading-7 text-gray-900">Solicitud de crédito</h3>
						<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Rellena la siguiente información para evaluar la capacidad de crédito.</p>
					</div>
					<div className="mt-6 border-t border-gray-100">

						<dl className="bg-white divide-y divide-gray-100">
							{
								arrSolicitud.map((t, e) => {
									return (
										<div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
											<dt className="text-sm font-medium leading-6 text-gray-900">{t.nombre}</dt>
											<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
												{
													t.type === 'select'
														? <select
															onChange={(ev) => {
																arrSolicitud[e].value = ev.target.value;
																setState({
																	arrSolicitud,
																})
															}}
															value={t.value}
															className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
														>
															{t.options.map((t0) => <option value={t0.id}>{t0.value}</option>)}
														</select>
														: t.type === 'file'
															? <div className="mt-4 flex text-sm leading-6 text-gray-600">
																<label
																	htmlFor={`file-upload-${e}`}
																	className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
																>
																	{
																		t.value === ""
																			? <span>Subir archivo</span>
																			: <span>Cambiar archivo</span>
																	}
																	<input id={`file-upload-${e}`} name={`file-upload-${e}`} type="file" className="sr-only" multiple={false}
																		onChange={(ev) => {
																			arrSolicitud[e].value = `${id_cliente}_${t.id}`
																			arrSolicitud[e].file = ev.target.files[0]
																			setState({ arrSolicitud, })
																		}} />
																</label>
															</div>
															: <input
																className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																type={t.type}
																value={t.value}
																onChange={(ev) => {
																	arrSolicitud[e].value = ev.target.value;
																	setState({
																		arrSolicitud,
																	})
																}}
															/>
												}
											</dd>
										</div>
									)
								})
							}

							<button
								type="button"
								className="inline-flex items-right right-0 gap-x-1.5 my-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								onClick={(e) => {
									e.preventDefault();

									F.registrarSolicitudCredito(state, setState, _this, 1)
								}}
							>
								Enviar solicitud
								<CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
							</button>

						</dl>
					</div>
				</div>

			</div >
		)
	}
}

export class FirmarSolicitudCredito extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this } = this.props

		return (
			<>
				<div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
					<div className="px-4 py-5 sm:px-6">
						<p className="font-normal text-center text-xl">Firmar solicitud de crédito</p>
					</div>
					<div className="px-4 py-5 sm:p-6" style={{
						margin: 5,
						boxShadow: 'rgb(148 28 128 / 30%) 0px 0px 29px 0px, rgb(148 28 128 / 58%) 0px 0px 0px 2px',
					}}>
						<SignaturePad ref={"mySignature"} options={{ minWidth: 2, maxWidth: 2, penColor: 'rgb(0, 0, 0)', }} height={500} />
					</div>
					<div className="px-4 py-4 sm:px-6">
						<p className="text-gray-400">Al firmar la solicitud aceptas que realicemos la evaluación de tu crédito con <b>buró de crédito</b>, también serán registradas las coordenadas del lugar donde realizaste la firma del contrato celebrado.</p>

						<button
							type="button"
							className="inline-flex items-right right-0 gap-x-1.5 my-5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
							onClick={(e) => {
								e.preventDefault();
								console.log(this.refs.mySignature)
								this.refs.mySignature.clear()
								// this.limpiar()
							}}
						>
							Limpiar
							<XMarkIcon className="h-5 w-5" aria-hidden="true" />
						</button>

						<button
							type="button"
							className="inline-flex items-right right-0 gap-x-1.5 my-5 rounded-md bg-green-600 px-3 py-2 mx-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
							onClick={(e) => {
								e.preventDefault();

								const base64String = this.refs.mySignature.toDataURL()
								F.firmarSolicitudCredito(state, setState, _this, base64String, 1)


							}}
						>
							Firmar ahora
							<CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
						</button>

					</div>
				</div>
			</>
		)
	}

	limpiar() {
		// this.signaturePad.clear();
		this.signaturePad.isEmpty();

	}
}

export class SolicitudEnProceso extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this } = this.props
		const { solicitud_credito, credito } = state.ficha

		return (
			<>
				<div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-20">
					<div className="px-4 py-5 sm:px-6">
						<p className="font-normal text-center text-xl">Solicitud de crédito</p>
					</div>
					<div className="px-4 py-5 sm:p-6 text-center" style={{
						margin: 5,
					}}>
						<img src="https://app.santori.com.mx/img/logo-santori.png" style={{ width: 200, margin: '0 auto', }} />
						{solicitud_credito === "1" && <p className="text-lg text-center font-light"></p>}
						{solicitud_credito === "2" && <p className="text-lg text-center font-light">Tu trámite se encuentra en proceso de verificación. Espera instrucciones.</p>}
						{solicitud_credito === "3" && <p className="text-lg text-center font-light">Tu trámite <b>fue autorizado</b> con el crédito total de: <b>${F.number_format(credito, 2)}</b></p>}
						{solicitud_credito === "4" && <p className="text-lg text-center font-light">Tu trámite no <b>fue autorizado</b>, podrás intentar en unos meses nuevamente la solicitud de crédito.</p>}

					</div>
				</div>
			</>
		)
	}

	limpiar() {
		// this.signaturePad.clear();
		this.signaturePad.isEmpty();

	}
}

export class Notificacion extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this, } = this.props
		const { notificacion, } = state

		return (
			<div
				ariaLive="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={notificacion.titulo ? true : false}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										{notificacion.icono}
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-medium text-gray-900">{notificacion.titulo}</p>
										<p className="mt-1 text-sm text-gray-500">{notificacion.descripcion}</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => {
												setState({ notificacion: {} })
											}}
										>
											<span className="sr-only">Cerrar</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		)
	}
}
