import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import moment from 'moment'
import * as F from './clientes.functions';
import * as view from './clientes.views';

moment.locale('es');

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class _Index extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		F.initData(this.props.initState, this.props.initsetState, this)
	}

	render() {
		const { navigate, location } = this.props.router

		return (
			<div>
				{
					location.pathname.indexOf('/firmar') !== -1 ? view.render__firmar(this.props.initState, this.props.initsetState, this)
						: location.pathname.indexOf('/enProceso') !== -1 ? view.render__enProceso(this.props.initState, this.props.initsetState, this)
							: view.render__fichaCliente(this.props.initState, this.props.initsetState, this)
				}
			</div>
		);
	}
}

const Route = withRouter(_Index)

export {
	Route
}