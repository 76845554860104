import moment from 'moment'

/**
 * MODULE: [APP - app.js]
 */

export const StateApp = {
	notificacion: {},
	auth: null,
}

/**
 * MODULE: [Clientes]
 */

export const StateClientes = {
	notificacion: {},
	auth: null,

	id_cliente: '',
	ficha: {},
	inputsSolicitudCredito: {},
	slideSolicitudCredito: false,

	arrSolicitud: [
		{ id: 'ine', nombre: 'INE', value: '', type: 'file' },
		{ id: 'curp', nombre: 'CURP', value: '', type: 'file' },
		{ id: 'rfc', nombre: 'RFC', value: '', type: 'file' },
		{ id: 'hoja_cumplimiento', nombre: 'Hoja de cumplimiento', value: '', type: 'file' },

		{ nombre: 'Nombre del cliente o razon social', value: '', type: 'text' },
		{ nombre: 'Nombre del cliente representante legal', value: '', type: 'text' },
		{ nombre: 'Direccion fiscal', value: '', type: 'text' },
		{ nombre: 'Colonia', value: '', type: 'text' },
		{ nombre: 'Ciudad', value: '', type: 'text' },
		{ nombre: 'Estado', value: '', type: 'text' },
		{ nombre: 'Codigo postal', value: '', type: 'text' },
		{ nombre: 'Nacionalidad', value: '', type: 'text' },
		{ nombre: 'RFC', value: '', type: 'text' },
		{ nombre: 'Telefono fijo', value: '', type: 'number' },
		{ nombre: 'Celular', value: '', type: 'number' },
		{ nombre: 'Correo electronico', value: '', type: 'email' },
		{ nombre: 'Fecha de constitucion', value: '', type: 'date' },
		{ nombre: 'Escritura', value: '', type: 'text' },
		{ nombre: 'Notaria', value: '', type: 'text' },
		{ nombre: 'Notario', value: '', type: 'text' },
		{ nombre: 'Folio mercantil', value: '', type: 'number' },
		{ nombre: 'Nombre de los socios', value: '', type: 'text' },
		{ nombre: 'Giro / Actividad preponderante', value: '', type: 'text' },
		{ nombre: 'El negocio esta ubicado en:', value: '', type: 'select', options: [{ id: 'casa', value: 'Casa', }, { id: 'local', value: 'Local', }, { id: 'oficina', value: 'Oficina', }, { id: 'bodega', value: 'Bodega', }, { id: 'departamento', value: 'Departamento', }] },
		{ nombre: 'El espacio donde esta el negocio es', value: '', type: 'select', options: [{ id: 'propio', value: 'Propio', }, { id: 'rentado', value: 'Rentado', }, { id: 'comodato', value: 'Comodato', }, { id: 'otro', value: 'Otro', },] },
		{ nombre: '¿En que ciudades tiene sucursales?', value: '', type: 'text' },
		{ nombre: 'CURP', value: '', type: 'text' },
		{ nombre: 'Tiempo de operaciones', value: '', type: 'text' },
		{ nombre: 'Direccion particular', value: '', type: 'text' },
		{ nombre: 'Codigo postal', value: '', type: 'number' },
		{ nombre: 'Ciudad', value: '', type: 'text' },
		{ nombre: 'Estado', value: '', type: 'text' },
		{ nombre: 'Entre Calle', value: '', type: 'text' },
		{ nombre: 'Y Calle', value: '', type: 'text' },
		{ nombre: 'Telefono', value: '', type: 'number' },
	]
}

/**
 * MODULE: [LOGIN]
 */

export const StateLogin = {
	notificacion: {},
	user: '',
	pass: '',
}


/**
 * MODULE: [POS]
 */

export const StatePos = {
	notificacion: {},
	id_cliente: '',
	sidebarOpen: false,
	sliderNuevoCliente: false,
	sidebarInputs: {},

	view: 'tabla',
	facturas: {},

	// Header
	progresoHeader: [],
	arrMisMetas: [],

	auth: (typeof localStorage.ws_santori_auth !== 'undefined' ? JSON.parse(localStorage.ws_santori_auth) : false),

	internet: false,

	modulo: 'home',

	session: {},
	progreso: {},


	inputProducto: '',
	arrProductos: [],
	arrProductosResults: [],

	arrRegistroNuevaUbicacion: { tipo_persona: 2, rfc: '', razon_social: '', correo: '', calle: '', no_exterior: '', no_interior: '', colonia: '', cp: '', localidad: '', municipio: '', estado: '', },
	arrInputsDireccionEntrega: {
		id_cliente: '',
		calle: '',
		no_exterior: '',
		no_interior: '',
		colonia: '',
		cp: '',
		localidad: '',
		municipio: '',
		estado: '',
		lat: '',
		lng: '',
		observaciones: ''
	},
	wizard: [
		{ id: 'Paso 1', name: 'Elige a tu cliente', description: "Primero debes indicar a qué cliente se hará la venta.", href: '#', status: 'current' },
		{ id: 'Paso 2', name: 'Visualiza su ficha', description: "Visualiza la ficha de tu cliente, puedes editar su información o validar que se encuentre al día.", href: '#', status: 'upcoming' },
		{ id: 'Paso 3', name: 'Añade al carrito', description: "Elige los productos que comprará tu cliente.", href: '#', status: 'upcoming' },
		{ id: 'Paso 4', name: 'Confirma cantidades', description: "Registra las cantidades por pagar de tu cliente.", href: '#', status: 'upcoming' },
	],

	nuevaVenta: {
		typeBusqueda: null,
		nombreCliente: '',
		telefonoCliente: '',
		// Ubicaciones fiscales del cliente
		arrUbicaciones: [],
		ubicacionEntrega: 'por_definir',
		index: -1,
		requiereAutorizacion: false,
		fRecibe: '',
		arrCarrito: [],
		arrRecibosCobrados: [],
	},

	modalDatosCliente: {},

	slideUbicacionFiscal: false,
	slideAumentarCredito: false,
	slideCotizacionesPendientes: false,
	slideUltimasVentas: false,
	slideDireccionesEntrega: false,
	slideDireccionesFiscales: false,
	limite_credito: '',


	inputsNuevaRazonSocial: {
		tipo_persona: '1',
		regimen_fiscal: '',
		rfc: '',
		razon_social: '',
		correo: '',
		calle: '',
		no_exterior: '',
		no_interior: '',
		colonia: '',
		cp: '',
		localidad: '',
		municipio: '',
		estado: '',
		observaciones: '',
	},

	//Clientes
	arrClientes: [],

	//Ventas
	arrVentas: [],

	// fichaCliente
	arrVentas: [],
	arrVentasSinAutorizar: [],
	arrProductosVentas: [],
	arrPagos: [],
	arrDireccionesEntrega: [],
	arrDireccionesFiscales: [],

	// Módulo de caja, mis ventas
	visualizarMisVentas: {
		verProductos: true,
		verCobranza: false,
		verEnvios: false,


		fechaInicio: moment().format('YYYY-MM-DD'),
		fechaFin: moment().format('YYYY-MM-DD'),
		arrVentas: [],
		indexVenta: -1,

		modalDatosCobro: {
			fPago: '',
			cantidad: '',
			observacion: '',
		},
		modalDatosEnvio: {
			id_producto: '',
			cantidad_solicitada: 0,
			fecha_solicitada: moment().format('YYYY-MM-DDTHH:mm'),
		},
		arrProductosVenta: [],
		arrCobranzaVenta: [],
		arrEnviosVenta: [],
	},

	fechaInicioVentas: moment().format('YYYY-MM-DD'),

}


/**
 * MODULE: [SUPERVISOR]
 */

export const StateSupervisor = {
	notificacion: {},
	internet: false,

	modulo: 'home',
	progresoHeader: [],
	arrMisMetas: [],

	facturas: {},

	session: {},
	progreso: {},

	arrVentas: [],
	arrCobranza: [],
	arrFacturacion: [],

	fechaInicio: moment().format('YYYY-MM-DD'),
	fechaFin: moment().format('YYYY-MM-DD'),
	fecha_pagos: moment().format('YYYY-MM-DD'),
	vistaGeneral: 'ventas',
	visualizarMisVentas: {},
	alertConfirmarComprobante: false,
	alertConfirmarOrden: false,
	arrVendedores: [],
	id_vendedor: '',
	arrOrdenesVenta: [],
	showNotificacion: false,
	titleNotificacion: '',
	messageNotificacion: '',

	input_comprobante: {},


}


/**
 * MODULE: [ADMINISTRADOR]
 */

export const StateAdministrador = {
	notificacion: {},
	internet: false,

	modulo: 'home',

	session: {},
	progreso: {},
	fechaInicio: moment().format('YYYY-MM-DD'),
	fechaFin: moment().format('YYYY-MM-DD'),
	fecha_progreso: moment().format('YYYY-MM-DD'),

	vistaGeneral: 'usuarios',
	arrMisMetas: [],

	arrUsuarios: [],
	arrProductos: [],
	arrVentas: [],

	arrProgresoCorporativo: {},
	arrListaVentasPorAutorizar: [],

	arrProductosVentas: [],
	arrPagos: [],
	slideInformacionVenta: false,
	openSlideActualizarProducto: false,

	view: 'productos',

	conceptoEntradaAlmacen: '',
	observacionEntradaAlmacen: '',

	arrGeneral: [],
	arrSucursales: [],
	arrStock: [],
	arrKGVendidos: [],

	arrCreditos: [],
	index_vendedor_creditos: '',
	id_cliente: '',
	id_vendedor: '',
	arrSolicitud: [],

}

/*
* MODULE: [CONTABILIDAD]
*/
export const StateContabilidad = {
	notificacion: {},

	internet: false,
	modulo: 'contabilidad',
	fecha: moment().format('YYYY-MM-DD'),
	session: {},
	id_sucursal: '',
	arrSucursales: [],
	arrHistorialOrdenesVenta: [],
	openSliderFacturarVenta: false,
	id_orden: '',
	arrDireccionesFiscales: [],
	inputsFacturarVenta: {
		uso_cfdi: '',
		id_razon: '',
		id_orden: '',
		id_cliente: '',
		tipo_persona: '1',
		tipo_comprobante_fiscal: 'I',
		forma_pago: '01',
		metodo_pago: 'PPD',
		regimen_fiscal: '1',
		rfc: '',
		razon_social: '',
		correo: '',
		calle: '',
		no_exterior: '',
		no_interior: '',
		colonia: '',
		cp: '',
		localidad: '',
		municipio: '',
		estado: '',
		observaciones: '',
	}
}

























