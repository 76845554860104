import { HTTP_POST, } from "../http";
import moment from 'moment'

export const number_format = (str, long) => {
    if (str == null) str = 0;
    return parseFloat(str)
        .toFixed(typeof long === 'undefined' ? 2 : long)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const initData = (_this) => {
    const url = `contador/obtenerSucursales`
    const data = {}
    HTTP_POST(url, data, response => {
        if (response === 'error')
            return false
        _this.props.initsetState({
            arrSucursales: response.data
        })
    })
}

export const getHistorialOrdenesVentas = (state, setState, _this) => {
    const url = `contador/obtenerHistorialOrdenesVentas`
    const { fecha, id_sucursal } = state
    const data = { fecha, id_sucursal, }
    HTTP_POST(url, data, response => {
        if (response === "error")
            return false;
        setState({
            arrHistorialOrdenesVenta: response.data
        })
    })
}

export const handleAccionarSlider = (state, setState, _this, slider, opt) => {
    setState({ [slider]: opt })
}

export const handleChangeInputMulti = (state, setState, _this, param, input, value) => {
    setState(prevState => ({
        ...prevState,
        [param]: {
            ...prevState[param],
            [input]: value
        }
    }))
}
/**
 * Función para obtener direcciones fiscales
 */
export const obtenerDireccionesFiscales = (state, setState, _this, id_cliente) => {
    const { initState, initsetState } = _this.props;
    const url = `pos/obtenerDireccionesFiscales`;
    const data = { id_cliente }
    HTTP_POST(url, data, response => {
        if (response === 'error') {
            return false;
        }
        initsetState({
            arrDireccionesFiscales: response.data
        })
    })
}

export const selectRazonSocial = (state, setState, _this, id_razon) => {
    if (id_razon === '')
        return false
    const { inputsFacturarVenta, arrDireccionesFiscales } = state;
    inputsFacturarVenta.id_razon = id_razon
    const item = arrDireccionesFiscales.find(direccion => direccion.id_razon === id_razon)
    inputsFacturarVenta.correo = item.correo
    inputsFacturarVenta.observaciones = item.observaciones
    setState({ inputsFacturarVenta, })
}

/**
 * Función para facturar una venta
 */
export const facturarVenta = (state, setState, _this, opt) => {
    let { inputsFacturarVenta } = state;
    if (opt === 1) {
        // Evaluamos si ya eligió una opción
        if (inputsFacturarVenta.id_razon === "") {
            alert('Por favor, elige una razón social o realiza una "factura libre"');
            return false;
        } else {
            // Propagamos la información del input
            const { arrDireccionesFiscales } = state;
            const item = arrDireccionesFiscales.find(direccion => direccion.id_razon === inputsFacturarVenta.id_razon)
            inputsFacturarVenta = { ...inputsFacturarVenta, ...item }
        }
    } else {

        if (inputsFacturarVenta.tipo_comprobante_fiscal === '') {
            alert('El campo comprobante fiscal es requerido');
            return false;
        }
        if (inputsFacturarVenta.forma_pago === '') {
            alert('El campo forma de pago es requerido');
            return false;
        }
        if (inputsFacturarVenta.metodo_pago === '') {
            alert('El campo metodo de pago es requerido');
            return false;
        }
        if (inputsFacturarVenta.tipo_persona === '') {
            alert('El campo tipo de persona es requerido');
            return false;
        }
        if (inputsFacturarVenta.regimen_fiscal === '') {
            alert('El campo régimen fiscal es requerido');
            return false;
        }
        if (inputsFacturarVenta.rfc === '') {
            alert('El campo RFC es requerido');
            return false;
        }
        if (inputsFacturarVenta.razon_social === '') {
            alert('El campo razón social es requerido');
            return false;
        }
        if (inputsFacturarVenta.calle === '') {
            alert('El campo calle es requerido');
            return false;
        }
        if (inputsFacturarVenta.no_exterior === '') {
            alert('El campo número exterior es requerido');
            return false;
        }
        if (inputsFacturarVenta.colonia === '') {
            alert('El campo colonia es requerido');
            return false;
        }
        if (inputsFacturarVenta.cp === '') {
            alert('El campo CP es requerido');
            return false;
        }
        if (inputsFacturarVenta.localidad === '') {
            alert('El campo localidad es requerido');
            return false;
        }
        if (inputsFacturarVenta.municipio === '') {
            alert('El campo municipio es requerido');
            return false;
        }
        if (inputsFacturarVenta.estado === '') {
            alert('El campo estado es requerido');
            return false;
        }
    }

    const url = `facturama/crearFactura`;
    const data = { ...inputsFacturarVenta }

    HTTP_POST(url, data, response => {
        if (response === 'error') {
            return false;
        }

        if (response.error) {
            alert(response.error)
        } else {
            getHistorialOrdenesVentas(state, setState, _this)
            alert('Venta facturada correctamente');
            setState({
                inputsFacturarVenta: { id_razon: '', id_orden: '', tipo_persona: '1', tipo_comprobante_fiscal: 'I', uso_cfdi: '', forma_pago: '01', metodo_pago: 'PPD', regimen_fiscal: '1', rfc: '', razon_social: '', correo: '', calle: '', no_exterior: '', no_interior: '', colonia: '', cp: '', localidad: '', municipio: '', estado: '', observaciones: '', },
                openSliderFacturarVenta: false,
            })
        }
    })
}
