import { HTTP_POST, } from "../http";
import moment from 'moment'
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

export const mostrarNotificacion = (state, setState, _this, notificacion) => {
	setState({ notificacion })
	setTimeout(() => {
		setState({
			notificacion: {},
		})
	}, notificacion.timeout)
}

export const initData = (state, setState, _this) => {
	const url = `pos/obtenerMiInformacionDeUsuario`
	const data = {}

	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false

		let { arrProductos, data, ordenes_pendientes, por_cobrar, tendencia, toneladas_al_dia, toneladas_autorizadas, toneladas_no_autorizadas, facturas, meta_ton_mes, meta_ton_dia, } = response

		localStorage.setItem('ws_santori_productos',
			JSON.stringify(response.arrProductos)
		)

		setState({
			internet: true,
			arrProductos,
			session: data,
			ordenes_pendientes,
			por_cobrar,
			tendencia,
			toneladas_al_dia,
			toneladas_autorizadas,
			toneladas_no_autorizadas,
			facturas,
			arrMisMetas: [
				{
					nombre: 'Meta del día (TON)',
					valor: (toneladas_al_dia / meta_ton_dia) * 100,
					// cantidad_pesos: '7000',
					// cantidad_kg: '700000',
				},
				{
					nombre: 'Meta del mes (TON)',
					valor: (toneladas_autorizadas / meta_ton_mes) * 100,
					// cantidad_pesos: '8000',
					// cantidad_kg: '800000',
				}
			]
		})
	})
}

export const buscarCliente = (state, setState, _this, opt, value) => {
	const { nuevaVenta } = state

	if (opt === 3) {
		// Buscar cliente

		nuevaVenta.buscandoCliente = true
		delete nuevaVenta.listado
		setState({ nuevaVenta })
		const { nombreCliente, telefonoCliente, typeBusqueda, } = nuevaVenta

		const url = `pos/nuevaVentaBuscaCliente`
		const data = { nombreCliente, telefonoCliente, typeBusqueda, }
		HTTP_POST(url, data, response => {

			nuevaVenta.buscandoCliente = false
			nuevaVenta.listado = response.data
			setState({ nuevaVenta })
		})

		return false
	}

	nuevaVenta.typeBusqueda = opt
	nuevaVenta.nombreCliente = opt === 1 ? value : ''
	nuevaVenta.telefonoCliente = opt === 2 ? value : ''
	setState({ nuevaVenta, })
}

// Elige el cliente a realizar la venta

export const elegirCliente = (state, setState, _this) => {
	const { id_cliente } = _this.props.router.params
	if (typeof id_cliente === 'undefined') {
		_this.props.router.navigate('/pos')
	}
}

export const fichaCliente__aumentarLimite = (_this, bool) => {
	_this.setState({ aumentarLimite: bool })
}

export const select__fichaCliente__cambiaLimite = (_this, val) => {
	_this.setState({ limiteCredito: val })
}

export const fichaCliente__registraCambioCredito = (state, setState, _this) => {
	const { limite_credito, nuevaVenta } = state
	const { id_cliente } = _this.props.router.params
	// const { listado, index } = nuevaVenta

	// const item = listado[index];
	// Se consultan sus métricas de datos rápidos
	const url = `pos/modificarLineaCredito`
	const data = { id_cliente, credito: limite_credito }

	HTTP_POST(url, data, response => {
		nuevaVenta.ficha.credito = limite_credito;
		// // listado[index].credito = limiteCredito
		setState({ nuevaVenta, limite_credito: 0, slideAumentarCredito: false, })
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
				titulo: 'Crédito actualizado',
				descripcion: 'Se modificó el límite de crédito exitosamente.',
				timeout: 5000
			}
		)
	})
}



export const eliminaUbicacionCliente = (_this, id_ubicacion, indexUbicacion) => {
	if (!window.confirm('¿Estás seguro que deseas eliminar esta ubicación?\n')) {
		return false
	}

	const { nuevaVenta } = _this.state
	const { listado, index } = nuevaVenta

	// nuevaVenta.arrUbicaciones.splice(indexUbicacion, 1);

	const item = listado[index]
	// Se consultan sus métricas de datos rápidos
	const url = `pos/eliminarUbicacionFiscal`
	const data = { id_cliente: item.id_cliente, id_ubicacion }
	HTTP_POST(url, data, response => {
		listado[index].arrUbicaciones = response.ubicaciones
		_this.setState({ nuevaVenta, })
	})
}

export const handleAbrirModalNuevoCliente = (state, setState, _this) => {
	const modalDatosCliente = {
		tipo_persona: 2,
		rfc: '',
		nombre: '',
		a_paterno: '',
		a_materno: '',
		telefono: '',
		correo: '',
		genero: 1,
		credito: 0,
		solicitud_credito: 0,
		prioridad: 0,
	}
	setState({ modalDatosCliente, })
}

export const fichaCliente__registrarNuevaUbicacionFiscal = (state, setState, _this, opt) => {
	if (opt === 1)
		setState({ showModalNuevaUbicacion: true, })
	else {
		const { arrRegistroNuevaUbicacion, nuevaVenta, } = state

		const { listado, index } = nuevaVenta

		// Registramos la nueva dirección fiscal del cliente y actualizamos el array
		const url = `pos/registrarNuevaDireccionFiscal`
		const data = { id_cliente: listado[index].id_cliente, ...arrRegistroNuevaUbicacion }
		HTTP_POST(url, data, response => {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
					titulo: 'Ficha actualizada',
					descripcion: 'Ubicación registrada con éxito.',
					timeout: 5000
				}
			)
			listado[index].arrUbicaciones = response.ubicaciones
			setState({ nuevaVenta, showModalNuevaUbicacion: false, arrRegistroNuevaUbicacion: { tipo_persona: 2, rfc: '', razon_social: '', correo: '', calle: '', no_exterior: '', no_interior: '', colonia: '', cp: '', localidad: '', municipio: '', estado: '', }, })
		})
	}
}

export const registrarNuevoCliente = (state, setState, _this) => {
	const { modalDatosCliente, nuevaVenta, } = state

	const url = `pos/registrarNuevoCliente`
	const data = { ...modalDatosCliente, }
	HTTP_POST(url, data, response => {
		nuevaVenta.listado = [response.data]
		nuevaVenta.index = 0
		setState({ nuevaVenta })
		handleAccionarSlider(state, setState, _this, "sliderNuevoCliente", false)
		_this.props.router.navigate('/pos/fichaCliente/' + response.data.id_cliente)

		if (modalDatosCliente.solicitud_credito === "1")
			window.open('https://app.santori.com.mx/clientes/' + response.data.id_cliente, '_blank')
	})
}


export const select__nuevoCliente__cambiaValor = (state, setState, _this, opt, val) => {
	const { modalDatosCliente } = state
	modalDatosCliente[opt] = val
	setState({ modalDatosCliente })
}


export const elegirProductoAlCarrito = (state, setState, _this, type, index) => {
	const { arrProductos, arrProductosResults, } = state

	// Type 1 => Producto elegido sin buscar
	if (type === 1)
		arrProductos[index].selected = !arrProductos[index].selected
	else if (type === 2) {
		// Type 2 => Producto elegido desde búsqueda
		const indexFilter = arrProductosResults[index].indexFilter
		const selected = arrProductosResults[index].selected
		arrProductosResults[index].selected = !selected
		arrProductos[indexFilter].selected = !selected
	}
	setState({ arrProductos, arrProductosResults })
}

export const input__buscarProducto = (state, setState, _this, val) => {
	let { arrProductos } = state;

	setState({ inputProducto: val })
	let countLimit = 0

	arrProductos = arrProductos.filter((producto, index) => {
		if (countLimit <= 10) {
			if (typeof producto.producto !== 'undefined' && producto.producto) {
				const producto_dato_completo = `${producto.clave} ${producto.cve_prod} ${producto.cve_unid} ${producto.fecha_venta} ${producto.id_producto} ${producto.iva} ${producto.costo_compra} ${producto.costo_venta} ${producto.producto} ${producto.unidad}`

				if (producto_dato_completo.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
					arrProductos[index].indexFilter = index
					countLimit++
					return true
				}
			}
		}
	});

	setState({ arrProductosResults: arrProductos });
}


export const continuarAlCarrito = (state, setState, _this) => {
	const { nuevaVenta, arrProductos } = state
	const { index } = nuevaVenta
	const { id_cliente } = _this.props.router.params

	let requiereAutorizacion = false
	let arrCarrito = []
	arrProductos.map((t, e) => {
		if (t.selected) {

			if (t.costo_venta === '' || t.costo_venta === 'variable') {
				requiereAutorizacion = true
			} else {
				// Por default elegimos costo_venta de contado
				t.precio_venta_usuario = t.costo_venta
			}

			t.cantidad_solicitada = ''
			t.fecha_solicitada = moment().format('YYYY-MM-DDTHH:mm')
			t.indexFilter = e

			arrCarrito.push(t)
		}
	})

	nuevaVenta.fPago = 'contado'
	nuevaVenta.arrCarrito = arrCarrito
	nuevaVenta.requiereAutorizacion = requiereAutorizacion

	// Tenemos que traernos las direcciones del cliente

	const url = `pos/obtenerDireccionEntrega`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		nuevaVenta.arrUbicaciones = response.data
		setState({ nuevaVenta })
	})

	setState({ subModulo: 'confirmarCantidades', arrProductosResults: [], inputProducto: '', nuevaVenta })

	_this.props.router.navigate('/pos/carrito/cantidades/' + id_cliente)
}

export const obtenerDireccionEntregaCarrito = (state, setState, _this) => {
	const { nuevaVenta, arrProductos } = state
	const { id_cliente } = _this.props.router.params


	const url = `pos/obtenerDireccionEntrega`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		nuevaVenta.arrUbicaciones = response.data
		setState({ nuevaVenta })
	})
}

export const regresarABuscarClientes = (_this) => {
	_this.setState({ subModulo: 'buscarCliente', })
}

export const regresarAElegirProductos = (state, setState, _this) => {
	const { nuevaVenta } = state

	if (window.confirm('¿Estás seguro que deseas regresar?\nPerderás las cantidades registradas en el carrito.')) {
		const { id_cliente } = _this.props.router.params
		nuevaVenta.arrCarrito = []
		setState({ nuevaVenta })
		_this.props.router.navigate('/pos/carrito/' + id_cliente)
	}
}

export const regresarACarrito = (_this) => {
	_this.setState({ subModulo: 'confirmarCantidades', })
}

export const regresarAMenu = (_this) => {
	_this.setState({ modulo: 'home', subModulo: '', })
}

export const regresarAHistorialMisVentas = (_this) => {
	_this.setState({ subModulo: 'visualizarMisVentas', })
}


export const eliminarProductoDeCarrito = (state, setState, _this, index, indexCarrito) => {
	const { nuevaVenta, arrProductos, } = state

	// Quitamos del carrito
	nuevaVenta.arrCarrito.splice(indexCarrito, 1);

	// Quitamos de la lista de productos
	arrProductos[index].selected = false
	delete arrProductos[index].precio_venta_usuario
	delete arrProductos[index].cantidad
	delete arrProductos[index].cantidad_enviar

	setState({ arrProductos, nuevaVenta, })
}

export const seleccionaFormaDePago = (state, setState, _this, fPago) => {
	const { nuevaVenta, } = state

	nuevaVenta.fPago = fPago

	// Al cambiar la forma de pago todos los items deben cambiar su costo

	nuevaVenta.arrCarrito.map((t, e) => {
		nuevaVenta.arrCarrito[e].precio_venta_usuario
			= (fPago === 'contado') ? t.costo_venta
				: (fPago === '15') ? t.costo_venta_15
					: (fPago === '30') ? t.costo_venta_30
						: ''
	})

	if (fPago === 'otro') {
		nuevaVenta.requiereAutorizacion = true
	} else {
		nuevaVenta.requiereAutorizacion = false
	}

	setState({ nuevaVenta, })
}

export const comoRecibiraCompra = (state, setState, _this, fRecibe) => {
	const { nuevaVenta, } = state
	nuevaVenta.fRecibe = fRecibe
	setState({ nuevaVenta, })
}


export const input__cambiarCantidadSolicitada = (state, setState, _this, index, value) => {
	const { nuevaVenta } = state
	nuevaVenta.arrCarrito[index].cantidad_solicitada = value
	nuevaVenta.arrCarrito[index].cantidad_enviar = value
	setState({ nuevaVenta, })
}

export const input__cambiarCantidadEnviar = (state, setState, _this, index, value) => {
	const { nuevaVenta } = state

	if (parseFloat(value) > parseFloat(nuevaVenta.arrCarrito[index].cantidad_solicitada))
		nuevaVenta.arrCarrito[index].cantidad_enviar = nuevaVenta.arrCarrito[index].cantidad_solicitada
	else
		nuevaVenta.arrCarrito[index].cantidad_enviar = value

	setState({ nuevaVenta, })
}

export const input__cambiarFechaSolicitadaEnviar = (state, setState, _this, index, value) => {
	const { nuevaVenta } = state
	nuevaVenta.arrCarrito[index].fecha_solicitada = value
	setState({ nuevaVenta, })
}

export const input__cambiarCostoUnitario = (state, setState, _this, index, value) => {
	const { nuevaVenta } = state
	nuevaVenta.arrCarrito[index].precio_venta_usuario = value
	setState({ nuevaVenta, })
}

export const calculadorToneladas = (cantidad) => {
	return `${(cantidad < 1000) ? (parseFloat(cantidad).toFixed(2) + ' KG') : ((cantidad / 1000).toFixed(2) + ' TON')}`
}

export const calculadorPagoProducto = (cantidad_solicitada, precio_venta_usuario) => {
	if (cantidad_solicitada === '' || precio_venta_usuario === '')
		return ''

	const resultado = (cantidad_solicitada * precio_venta_usuario)
	return '$' + resultado.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const formateaTotalCarrito = (cantidad) => {
	return '$' + cantidad.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
export const formateaCantidad = (cantidad, noMostrarPesos) => {
	if (typeof cantidad !== 'undefined')
		return (noMostrarPesos ? '' : '$') + parseFloat(cantidad).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
	else
		return 0
}

export const carritoCompletado = (state, setState, _this) => {
	const { nuevaVenta } = state
	const { arrCarrito } = nuevaVenta

	let can = true
	arrCarrito.map((t, e) => {
		if (
			(t.precio_venta_usuario) === '' ||
			(t.cantidad_solicitada) === '' ||
			(t.cantidad_enviar) === ''
		)
			can = false
	})

	if (arrCarrito.length === 0)
		can = false

	return can
}

export const select__cambiaUbicacionEntrega = (state, setState, _this, value) => {
	const { nuevaVenta } = state
	nuevaVenta.ubicacionEntrega = value
	setState({ nuevaVenta })
}

export const convertirAKilos = (valor, tipo) => {
	if (tipo === 'TONELADA') {
		return valor * 1000;
	}

	if (tipo === 'SACO' || tipo === 'S50') {
		return valor * 50;
	}

	if (tipo === 'S25') {
		return valor * 25;
	}

	if (tipo === 'KILOGRAMO') {
		return valor;
	}

	return valor;
}

export const continuarPasoFinalCompra = (state, setState, _this) => {
	const { nuevaVenta, id_cliente, } = state
	const { arrCarrito } = nuevaVenta

	// Evaluamos si hay envíos que se quedarán en almacén para agendar
	// Fechas de entregas

	let irAFechasProximosEnvios = false
	arrCarrito.map((t, e) => {
		if (parseFloat(t.cantidad_solicitada) !== parseFloat(t.cantidad_enviar))
			irAFechasProximosEnvios = false
	})

	const url = `pos/obtenerFichaCliente`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false

		nuevaVenta.deuda = response.deuda
		nuevaVenta.credito = response.ficha.credito
		setState({ nuevaVenta, id_cliente, arrDireccionesEntrega: response.direcciones_entrega, arrDireccionesFiscales: response.direcciones_fiscales, })
	})

	_this.props.router.navigate('/pos/carrito/cobranza/' + id_cliente)
	return true

	if (irAFechasProximosEnvios) {
		// Iremos a registrar las próximas fechas de entrega
	} else {
		// Iremos a registrar cuánto nos está pagando
	}
}

export const handleChangeInput = (state, setState, _this, input, value) => {
	setState({
		[input]: value
	})
}

export const handleChangeInputMulti = (state, setState, _this, param, input, value) => {
	setState(prevState => ({
		...prevState,
		[param]: {
			...prevState[param],
			[input]: value
		}
	}))
}

export const handleCerrarModalNuevaUbicacion = (_this) => {
	_this.setState({ showModalNuevaUbicacion: false, })
}

export const handleCerrarModalNuevoCobro = (_this) => {
	_this.setState({ showModalNuevoCobro: false, })
}

export const handleCerrarModalNuevoEnvio = (_this) => {
	_this.setState({ showModalNuevoEnvio: false, })
}

export const rellenarNuevaUbicacionFiscal = (_this, opt, val) => {
	const { arrRegistroNuevaUbicacion } = _this.state
	arrRegistroNuevaUbicacion[opt] = val
	_this.setState({ arrRegistroNuevaUbicacion, })
}


// RECIBOS DE COBRO

export const agregarNuevoReciboDeCobro = (state, setState, _this) => {
	const { nuevaVenta } = state
	const { arrRecibosCobrados } = nuevaVenta
	arrRecibosCobrados.push({
		fPago: '',
		cantidad: '',
		observacion: '',
	})
	setState({ arrRecibosCobrados, })
}

export const select__cambiaFPagoCobro = (state, setState, _this, index, val) => {
	const { nuevaVenta } = state
	const { arrRecibosCobrados } = nuevaVenta
	arrRecibosCobrados[index].fPago = val
	setState({ arrRecibosCobrados, })
}

export const select__cambiaCantidadRecibida = (state, setState, _this, index, val) => {
	const { nuevaVenta } = state
	const { arrRecibosCobrados } = nuevaVenta
	arrRecibosCobrados[index].cantidad = val
	setState({ arrRecibosCobrados, })
}

export const select__cambiaObservacion = (state, setState, _this, index, val) => {
	const { nuevaVenta } = state
	const { arrRecibosCobrados } = nuevaVenta
	arrRecibosCobrados[index].observacion = val
	setState({ arrRecibosCobrados, })
}

export const registrarVenta = (state, setState, _this) => {
	const { nuevaVenta, id_cliente } = state
	let { id_orden, fPago, fRecibe, arrRecibosCobrados, arrCarrito, ubicacionEntrega, requiereAutorizacion, credito, deuda, } = nuevaVenta

	let totalPagar = 0,
		totalCobrado = 0,
		credito_superado = false

	arrCarrito.map((prod) => {
		totalPagar += (parseFloat(prod.cantidad_solicitada) * parseFloat(prod.precio_venta_usuario))
	})

	arrRecibosCobrados.map(recibos => {
		totalCobrado += parseFloat(recibos.cantidad)
	})

	const residualACredito = totalPagar - totalCobrado,
		cantidadNuevoCreditoRestante = (parseFloat(credito) - parseFloat(deuda)) - residualACredito
	if (cantidadNuevoCreditoRestante < 0) {
		requiereAutorizacion = true
		credito_superado = true
	}

	const arrCarritoLite = []

	arrCarrito.map((t) => {
		arrCarritoLite.push({
			id_producto: t.id_producto,
			producto: t.producto,
			unidad: t.unidad,
			iva: t.iva,
			precio_compra: t.costo_compra,
			precio_venta: t.costo_venta,
			precio_venta_usuario: t.precio_venta_usuario,
			cantidad_solicitada: t.cantidad_solicitada,
			cantidad_enviar: t.cantidad_enviar,
			fecha_solicitada: t.fecha_solicitada,
		})
	})

	const fecha_registro = moment().format("YYYY-MM-DDTHH:mm:ss")

	// Registramos la nueva venta exitosa!
	const url = `pos/registrarNuevaVenta`
	const data = {
		id_orden,
		id_cliente,
		fPago,
		fRecibe,
		arrCarrito: arrCarritoLite,
		arrRecibosCobrados,
		id_ubicacion: ubicacionEntrega,
		requiereAutorizacion,
		fecha_registro,
		credito_superado,
		credito,
		deuda,
	}

	HTTP_POST(url, data, response => {
		setState({
			nuevaVenta: {
				...nuevaVenta,
				requiereAutorizacion: false,
				fRecibe: '',
				arrCarrito: [],
				arrRecibosCobrados: [],
			}
		})

		if (response.data === 'ok') {
			// Eliminamos la venta offline en caso de que exista
			const id_pago_server = response.id_orden
			/*
			const exists_ventas_offline = typeof localStorage.ws_santori_ventas_offline
			if (exists_ventas_offline !== 'undefined') {
				const arrVentasOffline = JSON.parse(localStorage.getItem('ws_santori_ventas_offline'))
				// Evaluamos que esta venta no se esté duplicando
				const arrNuevoVentasOffline = []
				arrVentasOffline.map(venta => {
					if (venta.id_orden != id_orden) {
						arrNuevoVentasOffline.push(venta)
					}
				})
				localStorage.setItem('ws_santori_ventas_offline', JSON.stringify(arrNuevoVentasOffline))
			}
			*/

			_this.props.router.navigate('/pos/fichaCliente/' + id_cliente)

			setTimeout(() => {
				mostrarNotificacion(
					state, setState, _this,
					{
						icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
						titulo: 'Nueva venta',
						descripcion: '¡Venta realizada con éxito!',
						timeout: 5000
					}
				)
			}, 2000)

		} else if (response.status === 'error') {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
					titulo: 'Error en venta',
					descripcion: 'Lo sentimos, no tienes permisos para estar aquí.',
					timeout: 5000
				}
			)
			_this.props.router.navigate('/pos/fichaCliente/' + id_cliente)
			return false
		} else {
			// Registramos en local la venta
			const exists_ventas_offline = typeof localStorage.ws_santori_ventas_offline
			if (exists_ventas_offline === 'undefined') {
				const arrVentasOffline = []
				arrVentasOffline.push(data)
				localStorage.setItem('ws_santori_ventas_offline', JSON.stringify(arrVentasOffline))
			} else {
				const arrVentasOffline = JSON.parse(localStorage.getItem('ws_santori_ventas_offline'))
				// Evaluamos que esta venta no se esté duplicando
				arrVentasOffline.map(venta => {
					if (venta.id_orden == id_orden) {
						mostrarNotificacion(
							state, setState, _this,
							{
								icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
								titulo: 'Error en venta',
								descripcion: '¡Oh no!\nEsta venta ya se encuentra registrada para enviarse. En cuanto recuperes una conexión a internet se registrará.',
								timeout: 5000
							}
						)
						return false
					} else {
						arrVentasOffline.push(data)
						localStorage.setItem('ws_santori_ventas_offline', JSON.stringify(arrVentasOffline))
					}
					_this.props.router.navigate('/pos/fichaCliente/' + id_cliente)
				})
			}

		}

	})
}

export const uniqid = () => {
	// Obtener el tiempo actual en milisegundos
	const now = Date.now();

	// Generar un número aleatorio
	const random = Math.floor(Math.random() * 1000000);

	// Combinar ambos y convertir a hexadecimal
	return now.toString(16) + random.toString(16);
}


////////////////////////////////////////////////////////////////////////

// MODULO CAJA

////////////////////////////////////////////////////////////////////////


export const input__cambiarMisVentasFecha = (_this, input, value) => {
	const { visualizarMisVentas } = _this.state
	visualizarMisVentas[input] = value
	_this.setState({
		visualizarMisVentas
	})
}

export const obtenerHistorialDeVentas = (_this) => {
	const { visualizarMisVentas, } = _this.state
	const { fechaInicio, fechaFin, } = visualizarMisVentas

	const url = `pos/obtenerHistorialDeVentas`
	const data = { fechaInicio, fechaFin, }
	HTTP_POST(url, data, response => {
		visualizarMisVentas.arrVentas = response
		_this.setState({ visualizarMisVentas })
	})

}

export const textoFormaDePago = (forma_pago) => {
	if (forma_pago === 'contado')
		return 'Pago de contado'
	else if (forma_pago === '15')
		return 'Crédito a 15 días'
	if (forma_pago === '30')
		return 'Crédito a 30 días'
	if (forma_pago === 'otro')
		return 'A convenir del vendedor'
	else
		return ''

}


export const textFormaPagoCobranza = (forma_pago) => {
	if (forma_pago === 'efectivo')
		return 'Efectivo'
	else if (forma_pago === 't_credito')
		return 'T. Crédito'
	else if (forma_pago === 't_debito')
		return 'T. Débito'
	else if (forma_pago === 'SPEI')
		return 'Transferencia E.'
	else if (forma_pago === 'deposito')
		return 'Depósito'
	else if (forma_pago === 'sie')
		return 'Convenio SIE'
	else
		return ''

}

export const formateaFacturadoCobranza = (status) => {
	if (status === 1)
		return 'Sin facturar'
	else if (status === 2)
		return 'Facturado'
	else if (status === 0)
		return 'Cancelado'
	else
		return ''

}

export const visualizarCobranzaDeVenta = (_this, index, item) => {
	const { visualizarMisVentas, } = _this.state
	const { id_orden } = item

	const url = `pos/obtenerHistorialDeCobranzaDeVenta`
	const data = { id_orden, }



	HTTP_POST(url, data, response => {
		visualizarMisVentas.arrProductosVenta = response.productos
		visualizarMisVentas.arrCobranzaVenta = response.cobranza
		visualizarMisVentas.arrEnviosVenta = response.envios
		visualizarMisVentas.indexVenta = index
		_this.setState({
			subModulo: 'visualizarCobranzaDeVenta',
			visualizarMisVentas,
		})
	})

}

export const handleAbrirModalNuevoCobro = (_this) => {
	const { visualizarMisVentas } = _this.state
	visualizarMisVentas.modalDatosCobro = {
		fPago: '',
		cantidad: '',
		observacion: '',
	}
	_this.setState({ showModalNuevoCobro: true, visualizarMisVentas, })
}

export const handleAbrirModalNuevoEnvio = (_this) => {
	const { visualizarMisVentas } = _this.state
	visualizarMisVentas.modalDatosEnvio = {
		id_producto: '',
		cantidad_solicitada: 0,
		fecha_solicitada: moment().format('YYYY-MM-DDTHH:mm'),
	}
	_this.setState({ showModalNuevoEnvio: true, visualizarMisVentas, })
}

export const select__nuevoCobro__cambiaFPagoCobro = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosCobro } = visualizarMisVentas
	modalDatosCobro.fPago = val
	_this.setState({ visualizarMisVentas, })
}

export const select__nuevoCobro__cambiaCantidadRecibida = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosCobro } = visualizarMisVentas
	modalDatosCobro.cantidad = val
	_this.setState({ visualizarMisVentas, })
}

export const select__nuevoCobro__cambiaObservacion = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosCobro } = visualizarMisVentas
	modalDatosCobro.observacion = val
	_this.setState({ visualizarMisVentas, })
}

export const mostrarOcultarVistaHistoriales = (_this, state, newState) => {
	const { visualizarMisVentas } = _this.state
	visualizarMisVentas[state] = newState
	_this.setState({ visualizarMisVentas, })
}

export const select__cambiaProductoParaEnvio = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosEnvio } = visualizarMisVentas

	modalDatosEnvio.id_producto = val
	_this.setState({ visualizarMisVentas, })
}

export const select__modalEnvio__cambiaCantidadSolicitada = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosEnvio } = visualizarMisVentas
	modalDatosEnvio.cantidad_solicitada = val
	_this.setState({ visualizarMisVentas, })
}

export const select__modalEnvio__cambiaObservacion = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosEnvio } = visualizarMisVentas
	modalDatosEnvio.observacion = val
	_this.setState({ visualizarMisVentas, })
}

export const input__modalEnvio__cambiarFechaSolicitadaEnviar = (_this, val) => {
	const { visualizarMisVentas } = _this.state
	const { modalDatosEnvio } = visualizarMisVentas
	modalDatosEnvio.fecha_solicitada = val
	_this.setState({ visualizarMisVentas, })
}


export const obtenerCantidadDisponibleEnvio = (_this, modalDatosEnvio, arrProductosVenta, arrEnviosVenta) => {
	let cantidadDisponibleEnviar = 0,
		totalKGComprados = 0,
		totalKGEnviados = 0
	if (modalDatosEnvio.id_producto !== '') {
		// Buscamos la diferencia de KG
		totalKGComprados = 0
		totalKGEnviados = 0

		arrProductosVenta.map(prod1 => {
			if (prod1.id_producto === modalDatosEnvio.id_producto) {
				totalKGComprados = parseFloat(prod1.venta_kg)
			}
		})

		arrEnviosVenta.map(prod2 => {
			if (prod2.id_producto === modalDatosEnvio.id_producto) {
				totalKGEnviados += parseFloat(prod2.cantidad_solicitada)
			}
		})

		// Una vez que tenemos el KG total de producto y el total de kg enviados anteriormente
		// Descontamos vs lo que está simulando
		cantidadDisponibleEnviar = ((totalKGComprados - totalKGEnviados) - parseFloat(modalDatosEnvio.cantidad_solicitada))
	}
	return cantidadDisponibleEnviar
}


export const logout = (_this) => {

	const url = `login/logout`
	const data = {}
	HTTP_POST(url, data, response => {
		window.location = '/'
	})
}

export const irASeccion = (state, setState, _this, url) => {
	_this.props.router.navigate(url)
}


export const obtenerWizardPorZona = (wizard, step) => {
	wizard.map((item, index) => {
		if (index < step) wizard[index].status = "current"
		else
			wizard[index].status = ""
	})

	return wizard
}

export const handleAccionarSlider = (state, setState, _this, slider, opt) => {
	setState({ [slider]: opt })

	if (slider === 'sliderNuevoCliente' && opt === true) {
		handleAbrirModalNuevoCliente(state, setState, _this)
	}

}

export const irAFichaCliente = (state, setState, _this, id_cliente) => {
	_this.props.router.navigate('/pos/fichaCliente/' + id_cliente)
}

export const registrarNuevaSucursal = (state, setState, _this) => {
	const { sidebarInputs } = state
	const url = `administrador/nuevaSucursal`
	const data = { ...sidebarInputs, }
	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false

		const { status } = response

		setState({
			sidebarInputs: {},
		})

		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Sucursal registrada con éxito',
				timeout: 5000
			}
		)
	})
}

export const input__dinamico__cambiaValor = (state, setState, _this, opt, value) => {
	const { sidebarInputs } = state
	setState({ [opt]: value })
}

export const obtenerFichaCliente = (state, setState, _this, id_cliente) => {
	let { nuevaVenta } = state
	const url = `pos/obtenerFichaCliente`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false

		// Ojo: lo que hay en response debe ir dentro de nuevaVenta, propagalo así
		nuevaVenta = { ...nuevaVenta, ...response }

		setState({ nuevaVenta, id_cliente, arrDireccionesEntrega: response.direcciones_entrega, arrDireccionesFiscales: response.direcciones_fiscales, })
	})
}

export const obtenerOrdenesSinAutorizar = (state, setState, _this) => {
	let { nuevaVenta, id_cliente } = state

	setState({
		slideCotizacionesPendientes: true,
		id_orden_selected: null
	})

	const url = `pos/obtenerOrdenesSinAutorizar`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false

		setState({ arrVentasSinAutorizar: response, })
	})
}

export const obtenerInformacionOrden = (state, setState, _this, id_orden) => {
	let { nuevaVenta } = state
	const url = `pos/obtenerInformacionOrden`
	const data = { id_orden }
	HTTP_POST(url, data, response => {
		if (response === 'error')
			return false
		const { productos, pagos, requiere_auth, } = response

		setState({ arrProductosVentas: productos, arrPagos: pagos, id_orden_selected: id_orden, requiere_auth, })
	})
}

export const obtenerUltimasOrdenesPorCliente = (state, setState, _this, limit) => {
	setState({
		slideUltimasVentas: true,
		id_orden_selected: null
	})

	const { id_cliente } = state
	const url = `pos/obtenerUltimasOrdenesPorCliente`
	const data = { id_cliente, limit }
	HTTP_POST(url, data, response => {
		if (typeof response === 'error')
			return false

		setState({
			arrVentas: response,
		})
	})
}

export const NuevaSucursal = (state, setState, _this) => {
	const { } = state
	const url = `pos/nuevaSucursal`
	const data = { ...state }
	HTTP_POST(url, data, response => {
		if (typeof response === 'error')
			return false

		const { data } = response

		setState({
			data
		})
	})
}

/**
 * Función para obtener los productos al visualizar el carrito
 */
export const getProductos = (state, setState, _this) => {
	const { nuevaVenta } = state

	// Por cada que se ingrese a un nuevo carrito de compra
	// Se generará un id_orden único que se reemplazará hasta
	// Finalizar la venta exitosa
	nuevaVenta.id_orden = uniqid()
	setState({ nuevaVenta })

	const url = `pos/obtenerProductos`;
	const data = {}
	HTTP_POST(url, data, response => {
		if (response === 'error') {
			// Traemos los productos si no hay conexión
			_this.setState({
				arrProductos: JSON.parse(localStorage.getItem('ws_santori_productos')),
				internet: false,
			})
			return false
		}

		response.arrProductos.map((t, e) => {
			return t.selected = false
		})

		setState({
			arrProductos: response.arrProductos
		})
	})

}

/**
 * Función para obtener las direcciones fiscales
 */
export const obtenerDireccionesFiscales = (state, setState, _this) => {
	const { id_cliente } = state;
	// Tenemos que traernos las direcciones del cliente
	const url = `pos/obtenerDireccionesFiscales`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		setState({ arrDireccionesFiscales: response.data })
	})
}

/**
 * Función para obtener las direcciones de entrega
 */
export const obtenerDireccionesEntrega = (state, setState, _this) => {
	const { id_cliente } = state;
	// Tenemos que traernos las direcciones del cliente
	const url = `pos/obtenerDireccionEntrega`
	const data = { id_cliente }
	HTTP_POST(url, data, response => {
		setState({ arrDireccionesEntrega: response.data })
	})
}

/**
 * Función para registrar una nueva dirección de entrega
 */
export const registrarDireccionEntrega = (state, setState, _this, closeForm) => {
	const { arrInputsDireccionEntrega } = state;
	if (closeForm) {
		// Si viene de aquí entonces el id_cliente es de new
		const { id_cliente } = _this.props.router.params
		arrInputsDireccionEntrega.id_cliente = id_cliente
	}
	if (arrInputsDireccionEntrega.id_cliente === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo id_cliente',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.calle === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo calle',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.no_exterior === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo número exterior',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.colonia === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo colonia',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.cp === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo código postal',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.municipio === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo municipio',
				timeout: 5000
			}
		)
		return false;
	}
	if (arrInputsDireccionEntrega.estado === '') {
		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Es necesario el campo estado',
				timeout: 5000
			}
		)
		return false;
	}

	const url = 'pos/registrarNuevaDireccionEntrega';
	const data = { ...arrInputsDireccionEntrega }
	HTTP_POST(url, data, response => {
		if (typeof response.error !== 'undefined') {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <ShieldCheckIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
					titulo: 'Aviso',
					descripcion: 'Ha ocurrido un error',
					timeout: 5000
				}
			)
			return false
		}
		let reset = {
			...arrInputsDireccionEntrega,
			calle: '',
			no_exterior: '',
			no_interior: '',
			colonia: '',
			cp: '',
			localidad: '',
			municipio: '',
			estado: '',
			lat: '',
			lng: '',
			observaciones: ''
		}

		if (closeForm) {
			obtenerDireccionEntregaCarrito(state, setState, _this);
			setState({ arrInputsDireccionEntrega: reset, })
			handleAccionarSlider(state, setState, _this, "slideDireccionesEntrega", false)
		} else {
			obtenerDireccionesEntrega(state, setState, _this);
			setState({ arrInputsDireccionEntrega: reset, view: 'tabla', })
		}


		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Dirección registrada correctamente',
				timeout: 5000
			}
		)
	})
}

/**
 * Función para registrar una nueva razón social
 */
export const registrarNuevaRazonSocial = (state, setState, _this) => {
	const { inputsNuevaRazonSocial, id_cliente, } = state
	const url = `pos/registrarNuevaDireccionFiscal`
	const data = { ...inputsNuevaRazonSocial, id_cliente }
	HTTP_POST(url, data, response => {
		if (typeof response.error !== 'undefined') {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
					titulo: 'Aviso',
					descripcion: 'Ha ocurrido un error',
					timeout: 5000
				}
			)
			return false;
		}

		mostrarNotificacion(
			state, setState, _this,
			{
				icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
				titulo: 'Aviso',
				descripcion: 'Razón social registrada con éxito.',
				timeout: 5000
			}
		)

		let reset = {
			...inputsNuevaRazonSocial,
			tipo_persona: '1',
			regimen_fiscal: '',
			rfc: '',
			razon_social: '',
			correo: '',
			calle: '',
			no_exterior: '',
			no_interior: '',
			colonia: '',
			cp: '',
			localidad: '',
			municipio: '',
			estado: '',
			observaciones: '',
		}
		setState({ inputsNuevaRazonSocial: reset, view: 'tabla', })
		obtenerDireccionesFiscales(state, setState, _this);
	})
}

/**
 * Función para obtener la lista de clientes
 */
export const obtenerListaclientes = (state, setState, _this) => {
	const url = `pos/verListaClientes`
	const data = {
		init: '0',
		to: '100',
		order: '1',
	}
	HTTP_POST(url, data, response => {
		if (typeof response.error !== 'undefined') {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
					titulo: 'Aviso',
					descripcion: 'Ha ocurrido un error',
					timeout: 5000
				}
			)
			return false;
		}
		setState({
			arrClientes: response.data
		})
	})
}

/**
 * Función para obtener la lista de ventas
 */
export const obtenerListaVentas = (state, setState, _this) => {

	const url = `pos/obtenerHistorialDeVentas`;
	const data = {
		fechaInicio: state.fechaInicioVentas,
		fechaFin: state.fechaInicioVentas
	}
	HTTP_POST(url, data, response => {
		if (typeof response.error !== 'undefined') {
			mostrarNotificacion(
				state, setState, _this,
				{
					icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
					titulo: 'Aviso',
					descripcion: 'Ha ocurrido un error',
					timeout: 5000
				}
			)
			return false;
		}
		setState({
			arrVentas: response
		})
	})
}

export const number_format = (str, long) => {
	if (str == null) str = 0;
	return parseFloat(str)
		.toFixed(typeof long === 'undefined' ? 2 : long)
		.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const obtenerHistorialOrden = (state, setState, _this, venta) => {
	setState({
		id_orden_selected: venta.id_orden,
		slideUltimasVentasOnlyOrden: true,
	})

	obtenerInformacionOrden(state, setState, _this, venta.id_orden)
	handleAccionarSlider(state, setState, _this, "slideUltimasVentas", true)
}

export const registrarNuevoAbono = (state, setState, _this) => {
	const { sidebarInputs, id_orden_selected } = state

	const url = `pos/registrarNuevoPago`
	const data = {
		id_orden: id_orden_selected,
		fPago: sidebarInputs.fPago == "" ? "efectivo" : sidebarInputs.fPago,
		cantidad: sidebarInputs.cantidad,
		observacion: sidebarInputs.observacion,
	}

	HTTP_POST(url, data, response => {
		if (typeof response === 'error')
			return false

		const { } = response

		sidebarInputs.fPago = ''
		sidebarInputs.cantidad = ''
		sidebarInputs.observacion = ''
		setState({
			isRegisterAbono: false,
			sidebarInputs,
		})

		obtenerUltimasOrdenesPorCliente(state, setState, _this, 10)
		obtenerInformacionOrden(state, setState, _this, id_orden_selected)
	})
}