import React, { Fragment } from "react";
import * as F from './pos.functions';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { NavLink } from "react-router-dom";
import moment from "moment";
import {
	Bars3Icon,
	BellIcon,
	Cog6ToothIcon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
	AcademicCapIcon,
	ReceiptRefundIcon,
	UserCircleIcon,
	BanknotesIcon,

	ChartBarIcon,
	ChartPieIcon,
	DocumentTextIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, BuildingOfficeIcon, CreditCardIcon, HandThumbUpIcon, CheckIcon, DocumentCheckIcon, ArrowLeftIcon, ArrowRightIcon, PlusCircleIcon } from '@heroicons/react/20/solid'


const userNavigation = [
	{ name: 'Perfil', href: '#' },
	{ name: 'Cerrar sesión', href: '/logout' },
]
const stats = [
	// TON / día actual * 24
]

const menuOpciones = [
	{
		icon: BanknotesIcon,
		name: 'Nueva venta / ver perfil de cliente',
		description: 'Realiza una nueva venta, elige a tu cliente y carga su orden de compra.',
		iconForeground: 'text-teal-700',
		iconBackground: 'bg-teal-50 dark:bg-slate-800',
		url: '/pos/buscarCliente',
	},
	{
		icon: ReceiptRefundIcon,
		name: 'Mis ventas',
		description: 'Visualiza tus ventas del día',
		iconForeground: 'text-purple-700 dark:text-purple-200',
		iconBackground: 'bg-purple-50 dark:bg-slate-800',
		url: '/pos/',
	},
	{
		icon: UsersIcon,
		name: 'Registrar cobro / abono',
		description: 'Agrega nuevos cobros o abonos a tus clientes deudores',
		iconForeground: 'text-sky-700 dark:text-sky-200',
		iconBackground: 'bg-sky-50 dark:bg-slate-800',
		url: '/pos/',
	},
	{
		icon: BanknotesIcon,
		name: 'Facturar venta',
		description: 'Realiza la factura de una venta concluida',
		iconForeground: 'text-yellow-700 dark:text-yellow-200',
		iconBackground: 'bg-yellow-50 dark:bg-slate-800',
		url: '/pos/',
	},
	{
		icon: ReceiptRefundIcon,
		name: 'Liberar material',
		description: 'Autoriza la liberación de un material comprado por tu cliente que se encuentre pendiente de envío en tu almacen.',
		iconForeground: 'text-rose-700 dark:text-rose-200',
		iconBackground: 'bg-rose-50 dark:bg-slate-800',
		url: '/pos/',
	},
	{
		icon: AcademicCapIcon,
		name: 'Requisición de compra',
		description: 'Realiza la requisición de productos necesarios para tu área de trabajo.',
		iconForeground: 'text-red-700 dark:text-red-200',
		iconBackground: 'bg-red-50 dark:bg-slate-800',
		url: '/pos/',
	},
]

const plans = [
	{
		id: 1,
		name: 'CEMENTO GRIS CPC-40 GRANEL',
		memory: 'CEM 974531',
		cpu: 'TONELADA',
		price: '$3,880.00',
		isCurrent: false,
	},
	{
		id: 2,
		name: 'CEMENTO GRIS CPC 40 BIG BAG',
		memory: 'CEM 41335',
		cpu: 'TONELADA',
		price: '$3,450.00',
		isCurrent: true,
	},
	// More plans...
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			session: {},
			sidebarOpen: false,
			navigation: [
				{ name: 'Inicio', href: '#', icon: HomeIcon, current: true, to: '/pos' },
				{ name: 'Clientes', href: '#', icon: UsersIcon, current: false, to: '/pos/clientes' },
				{ name: 'Ventas', href: '#', icon: BanknotesIcon, current: false, to: '/pos/ventas' },
			]
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);
		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			})
		}
		const { navigation } = this.state;
		const { _this } = this.props;
		let path = _this.props.router.location.pathname;
		let newNav = navigation.map(item => ({
			...item,
			current: (item.to === path) ? true : false
		}))
		this.setState({ navigation: newNav })
	}

	setSideBarOpen = (status) => this.setState({ sidebarOpen: status })

	render() {
		const { sidebarOpen, navigation } = this.state;
		const { children } = this.props
		const { session } = this.state;

		return (
			<>
				<div className={'dark:bg-slate-900'}>
					<Transition.Root show={sidebarOpen} as={Fragment}>
						<Dialog as="div" className="relative z-50 lg:hidden" onClose={() => this.setSideBarOpen(false)}>
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-gray-900/80" />
							</Transition.Child>

							<div className="fixed inset-0 flex">
								<Transition.Child
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transition ease-in-out duration-300 transform"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
										<Transition.Child
											as={Fragment}
											enter="ease-in-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in-out duration-300"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
												<button type="button" className="-m-2.5 p-2.5" onClick={() => this.setSideBarOpen(false)}>
													<span className="sr-only">Close sidebar</span>
													<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
												</button>
											</div>
										</Transition.Child>
										{/* Sidebar component, swap this element with another sidebar if you like */}
										<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
											<div className="flex h-16 shrink-0 items-center">
												<img
													className="h-8 w-auto"
													src="https://app.santori.com.mx/img/logo-santori.png"
													alt="Santori SA De CV"
												/>
											</div>
											<nav className="flex flex-1 flex-col">
												<ul role="list" className="flex flex-1 flex-col gap-y-7">
													<li>
														<ul role="list" className="-mx-2 space-y-1">
															{navigation.map((item) => (
																<li key={item.name}>
																	<NavLink
																		to={item.to}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-red-600 dark:text-red-900'
																				: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden="true"
																		/>
																		{item.name}
																	</NavLink>
																</li>
															))}
														</ul>
													</li>

													<li className="mt-auto">
														<a
															href="#"
															className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
														>
															<Cog6ToothIcon
																className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
																aria-hidden="true"
															/>
															Configuraciones
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition.Root>

					{/* Static sidebar for desktop */}
					<div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
							<div className="flex h-16 shrink-0 items-center">
								<img
									className="h-8 w-auto"
									src="https://app.santori.com.mx/img/logo-santori.png"
									alt="Santori SA De CV"
								/>
							</div>
							<nav className="flex flex-1 flex-col">
								<ul role="list" className="flex flex-1 flex-col gap-y-7">
									<li>
										<ul role="list" className="-mx-2 space-y-1">
											{navigation.map((item) => (
												<li key={item.name}>
													<NavLink
														to={item.to}
														className={classNames(
															item.current
																? 'bg-gray-50 text-red-600 dark:text-red-900'
																: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
														)}
													>
														<item.icon
															className={classNames(
																item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</NavLink>
													{/* <a
														href={item.href}
														className={classNames(
															item.current
																? 'bg-gray-50 text-red-600 dark:text-red-900'
																: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
														)}
													>
														<item.icon
															className={classNames(
																item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</a> */}
												</li>
											))}
										</ul>
									</li>
									<li className="mt-auto">
										<a
											href="#"
											className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
										>
											<Cog6ToothIcon
												className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
												aria-hidden="true"
											/>
											Configuraciones
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>

					<div className="lg:pl-72">
						<div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 dark:bg-slate-800 dark:border-gray-800">
							<button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => this.setSideBarOpen(true)}>
								<span className="sr-only">Abrir menú</span>
								<Bars3Icon className="h-6 w-6" aria-hidden="true" />
							</button>

							{/* Separator */}
							<div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

							<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
								<div className="relative flex flex-1"></div>
								<div className="flex items-center gap-x-4 lg:gap-x-6">
									<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</button>

									{/* Separator */}
									<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

									{/* Profile dropdown */}
									<Menu as="div" className="relative">
										<Menu.Button className="-m-1.5 flex items-center p-1.5">
											<span className="sr-only">Abrir menú de usuario</span>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 h-8 w-8 dark:text-white"><path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
											<span className="hidden lg:flex lg:items-center">
												<span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200" aria-hidden="true">
													{session.nombre} {session.apellidos}
												</span>
												<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
											</span>
										</Menu.Button>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-gray-900">
												{userNavigation.map((item) => (
													<Menu.Item key={item.name}>
														{({ active }) => (
															<a
																href={item.href}
																className={classNames(
																	active ? 'bg-gray-50 dark:bg-gray-500' : '',
																	'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-200'
																)}
															>
																{item.name}
															</a>
														)}
													</Menu.Item>
												))}
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>
						</div>

						<main className={this.props.paddingMain}>
							<div className={this.props.padding}>
								{children}
							</div>
						</main>
					</div>
				</div>
			</>
		)
	}
}

export class StatsWidgets extends React.Component {
	render() {
		const { ordenes_pendientes, por_cobrar, tendencia, toneladas_autorizadas, toneladas_no_autorizadas, facturas, } = this.props.state
		const { state, setState, _this, } = this.props

		return (
			<div>
				<h3 className="text-2xl font-semibold leading-6 text-gray-900 dark:text-gray-200">Actividad de este mes</h3>
				<p className="text-md pt-2 leading-6 text-gray-900 dark:text-gray-200">Se considera solamente todas las operaciones autorizadas por el administrador</p>


				<dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">

					<div
						className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 ring-slate-900/5 dark:bg-slate-800"
					>
						<dt>
							<div className="absolute rounded-md bg-red-500 p-3">
								<ChartBarIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p className="ml-16 truncate text-xs font-medium text-gray-500 dark:text-gray-100 lg:text-base">TON Vendidas</p>
							<p className="ml-16 text-2xl font-semibold pb-0 text-gray-900 dark:text-gray-100">{F.calculadorToneladas(toneladas_autorizadas)}</p>
							<p className="ml-16 text-md font-semibold pb-0 text-gray-600 dark:text-gray-100">Pendiente de autorizar: {F.calculadorToneladas(toneladas_no_autorizadas)}</p>
						</dt>
					</div>

					<div
						className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 ring-slate-900/5 dark:bg-slate-800"
					>
						<dt>
							<div className="absolute rounded-md bg-red-500 p-3">
								<ChartPieIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p className="ml-16 truncate text-xs font-medium text-gray-500 dark:text-gray-100 lg:text-base">Tendencia de ventas</p>
							<p className="ml-16 text-2xl font-semibold pb-0 text-gray-900 dark:text-gray-100">{F.number_format(tendencia, 2)}%</p>
						</dt>
					</div>

					<div
						className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 ring-slate-900/5 dark:bg-slate-800"
						onClick={() => { _this.props.router.navigate('/pos/ventas/') }}
					>
						<dt>
							<div className="absolute rounded-md bg-red-500 p-3">
								<BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p className="ml-16 truncate text-xs font-medium text-gray-500 dark:text-gray-100 lg:text-base">Por cobrar (créditos)</p>
							<p className="ml-16 text-2xl font-semibold pb-0 text-gray-900 dark:text-gray-100">${F.number_format(por_cobrar, 2)}</p>
						</dt>
					</div>

					<div
						className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 ring-slate-900/5 dark:bg-slate-800"
					>
						<dt>
							<div className="absolute rounded-md bg-red-500 p-3">
								<DocumentTextIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p className="ml-16 truncate text-xs font-medium text-gray-500 dark:text-gray-100 lg:text-base">Ventas pendientes de aut.</p>
							<p className="ml-16 text-2xl font-semibold pb-0 text-gray-900 dark:text-gray-100">{F.number_format(ordenes_pendientes, 0)}</p>
						</dt>
					</div>

					<div
						className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6 ring-slate-900/5 dark:bg-slate-800"
					>
						<dt>
							<div className="absolute rounded-md bg-red-500 p-3">
								<DocumentCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
							</div>
							<p className="ml-16 truncate text-xs font-medium text-gray-500 dark:text-gray-100 lg:text-base">Facturas realizadas</p>
							<p className="ml-16 text-2xl font-semibold pb-0 text-gray-900 dark:text-gray-100">{F.number_format(facturas?.total_facturadas, 0)} de {F.number_format(facturas?.total, 0)}</p>
						</dt>
					</div>
				</dl>
			</div>
		)
	}
}

export class ActionsPanel extends React.Component {
	render() {
		const { state, setState, _this, } = this.props

		return (
			<section aria-labelledby="quick-links-title" className="pt-10">
				<div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
					<h2 className="sr-only dark:text-slate-100" id="quick-links-title">
						Menú de opciones
					</h2>
					{menuOpciones.map((action, actionIdx) => (
						<div
							key={action.name}
							className={classNames(
								actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
								actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
								actionIdx === menuOpciones.length - 2 ? 'sm:rounded-bl-lg' : '',
								actionIdx === menuOpciones.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
								'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 cursor-pointer dark:bg-slate-800'
							)}
							onClick={() => {
								F.irASeccion(state, setState, _this, action.url)
							}}
						>
							<div>
								<span
									className={classNames(
										action.iconBackground,
										action.iconForeground,
										'inline-flex rounded-lg p-3 ring-4 ring-white dark:ring-transparent'
									)}
								>
									<action.icon className="h-6 w-6" aria-hidden="true" />
								</span>
							</div>
							<div className="mt-8">
								<h3 className="text-lg font-medium dark:text-gray-200">
									<a href={action.href} className="focus:outline-none">
										{/* Extend touch target to entire panel */}
										<span className="absolute inset-0" aria-hidden="true" />
										{action.name}
									</a>
								</h3>
								<p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
									{action.description}
								</p>
							</div>
							<span
								className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-200"
								aria-hidden="true"
							>
								<svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
									<path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
								</svg>
							</span>
						</div>
					))}
				</div>
			</section>
		)
	}
}

export class Meta extends React.Component {

	render() {
		const { arrMisMetas } = this.props.state

		return (

			<section className="pt-10">
				<div>
					<div className="py-4 bg-white rounded dark:bg-slate-800">
						<div className="px-6 pb-6 border-b border-blue-50 dark:border-slate-700">
							<h3 className="text-xl font-bold dark:text-slate-100" >Mis metas</h3>
							<p className="text-sm text-gray-500 dark:text-slate-400" >A continuación se muestra tu progreso</p>
						</div>
						{

							arrMisMetas.map((t, e) => {
								let semaforo = '',
									valor = parseInt(t.valor) > 100 ? 100 : parseInt(t.valor)

								if (valor < 35)
									semaforo = 'red'
								else if (valor >= 35 && valor < 60)
									semaforo = 'yellow'
								else
									semaforo = 'green'

								const textClass = `text-${semaforo}-500`,
									bg50Class = `bg-${semaforo}-50`,
									bg500Class = `bg-${semaforo}-500`


								return (
									<div className="p-6 border-b border-blue-50 dark:border-slate-900" >
										<div className="flex -mx-4">
											<div className="flex items-center w-1/2 px-4">
												<p className="text-sm font-medium dark:text-slate-200" >{t.nombre}</p>
											</div>
											<div className="w-1/2 px-4">
												<p className={`mb-1 text-xs ${textClass} font-medium`}>{F.number_format(t.valor, 0)}%</p>
												<div className="flex">
													<div className={`relative h-1 w-full ${bg50Class} rounded-full`}>
														<div className={`absolute top-0 left-0 h-full ${bg500Class} rounded-full`} style={{ width: valor + '%' }}></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							})
						}

					</div>
				</div>
			</section >

		)

	}
}

export class Wizard extends React.Component {
	render() {
		const { state, setState, _this, pasoActual, } = this.props
		if (typeof this.props.state.wizard === 'undefined') {
			return <p></p>
		}
		let wizard = F.obtenerWizardPorZona(state.wizard, pasoActual)

		return (
			<nav aria-label="Progress" className={this.props.style}>
				<ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
					{wizard.map((step) => (
						<li key={step.name} className="md:flex-1">
							{step.status === 'complete' ? (
								<a
									href={step.href}
									className="group flex flex-col border-l-4 border-red-600 py-2 pl-4 hover:border-red-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 cursor-default"
								>
									<span className="text-sm font-medium text-red-600 group-hover:text-red-800">{step.id}</span>
									<span className="text-sm font-medium dark:text-gray-200">{step.name}</span>
								</a>
							) : step.status === 'current' ? (
								<a
									href={step.href}
									className="flex flex-col border-l-4 border-red-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 cursor-default"
									aria-current="step"
								>
									<span className="text-sm font-medium text-red-600">{step.id}</span>
									<span className="text-sm font-medium dark:text-gray-200">{step.name}</span>
								</a>
							) : (
								<a
									href={step.href}
									className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 cursor-default"
								>
									<span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
									<span className="text-sm font-medium dark:text-gray-200">{step.name}</span>
								</a>
							)}
						</li>
					))}
				</ol>
			</nav>
		)

	}
}

export class CardHeading extends React.Component {
	render() {
		const { state, setState, _this, pasoActual } = this.props

		if (typeof this.props.state.wizard === 'undefined') {
			return <p></p>
		}

		const { nuevaVenta } = state
		const description = state.wizard[pasoActual - 1].description

		return (
			<>
				<div className="border-b border-gray-20 px-4 py-5 sm:px-6 dark:bg-transparent dark:border-gray-800">
					<div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
						<div className="mt-4">
							{/* <h3 className="text-base font-semibold leading-6 text-gray-900">Job Postings</h3> */}
							<p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
								{description}
							</p>
						</div>
					</div>
				</div>

				<div className="mt-4 flex-shrink-0">
					<form>

						<div className="mb-3">
							<label htmlFor="name" className="ml-px block pl-0 text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
								Nombre del cliente
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="name"
									id="name"
									className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-800 dark:text-gray-200"
									placeholder="Ingresa el nombre de tu cliente"
									onChange={(ev) => { F.buscarCliente(state, setState, _this, 1, ev.target.value) }}
									value={nuevaVenta.nombreCliente}
								/>
							</div>
						</div>

						<div className="mb-3">
							<label htmlFor="name" className="ml-px block pl-0 text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
								Teléfono
							</label>
							<div className="mt-2">
								<input
									type="number"
									name="number"
									id="number"
									className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-800 dark:text-gray-200"
									placeholder="Ingresa el teléfono"
									onChange={(ev) => { F.buscarCliente(state, setState, _this, 2, ev.target.value) }}
									value={nuevaVenta.telefonoCliente}
								/>
							</div>
						</div>

					</form>


					{
						(nuevaVenta.nombreCliente !== '' || nuevaVenta.telefonoCliente !== '') && !nuevaVenta.buscandoCliente
						&&
						<div className="w-100 text-left">
							<button
								onClick={() => { F.buscarCliente(state, setState, _this, 3) }}
								type="button"
								className="inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 mt-3"
							>
								<CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
								Buscar
							</button>
						</div>

					}

					{
						nuevaVenta.buscandoCliente && <svg fill='none' className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
							<path clipRule='evenodd'
								d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
								fill='currentColor' fillRule='evenodd' />
						</svg>
					}

					{
						typeof nuevaVenta.listado !== 'object'
							? null
							: nuevaVenta.listado.length === 0 ?
								<>
									<div className="pt-4 pb-4 mt-4 border-t border-blue-50">
										<h5 className="text-xl font-bold dark:text-gray-200" >No se encontraron clientes con esta búsqueda.</h5>
									</div>

									<button
										onClick={() => F.handleAccionarSlider(state, setState, _this, 'sliderNuevoCliente', true)}
										type="button"
										className="w-full justify-center inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
									>
										<CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
										Registrar un nuevo cliente
									</button>
								</>
								:
								<>
									<div className="pt-4 mt-4 border-t border-blue-50 ">
										<h5 className="text-xl font-bold dark:text-gray-200" >Resultados encontrados ({nuevaVenta.listado.length}).</h5>
									</div>
									<DescriptionList listado={nuevaVenta.listado} state={state} setState={setState} _this={_this} />
									<button
										onClick={() => F.handleAccionarSlider(state, setState, _this, 'sliderNuevoCliente', true)}
										type="button"
										className="w-full justify-center inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
									>
										<CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
										Registrar un nuevo cliente
									</button>
								</>
					}




				</div>
			</>
		)
	}
}

export class DescriptionList extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { state, setState, _this, } = this.props
		const { listado } = this.props;
		return (
			<ul role="list" className="divide-y divide-gray-100 cursor-pointer mb-10 mt-10" >
				{listado.map((person, e) => (
					<li key={`person-${e}`} className="flex justify-between gap-x-6 p-2 hover:bg-gray-200 hover:p-2 dark:bg-gray-800 dark:hover:bg-gray-600" onClick={() => { F.irAFichaCliente(state, setState, _this, person.id_cliente) }}>
						<div className="flex min-w-0 gap-x-4">
							<UserCircleIcon className="-ml-0.5 h-8 w-8" aria-hidden="true" />
							<div className="min-w-0 flex-auto">
								<p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{person.nombre_completo}</p>
								<p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-300">{person.telefono}</p>
								<p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-300">{person.correo}</p>
							</div>
						</div>
						<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
							<p className="text-sm leading-6 text-gray-900">{person.role}</p>
							<p className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-300">
								Sucursal <time dateTime={person.lastSeenDateTime}>{person.nombre_sucursal}</time>
							</p>
						</div>
					</li>
				))}
			</ul>
		)
	}
}

export class SlideOverCliente extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		const { state, setState, _this, } = this.props
		const { sliderNuevoCliente, modalDatosCliente, } = state


		if (typeof sliderNuevoCliente === 'undefined')
			return null


		return (
			<Transition.Root show={sliderNuevoCliente} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "sliderNuevoCliente", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
																Nuevo usuario
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-300">
																Ingresa la información para agregar un nuevo usuario
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "sliderNuevoCliente", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Close panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Nombre del cliente</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.nombre} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'nombre', ev.target.value)} type={'text'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Apellido paterno</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.a_paterno} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'a_paterno', ev.target.value)} type={'text'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Apellido materno</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.a_materno} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'a_materno', ev.target.value)} type={'text'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Teléfono</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.telefono} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'telefono', ev.target.value)} type={'text'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Correo electrónico</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.correo} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'correo', ev.target.value)} type={'text'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Género</label></div>
														<div className="sm:col-span-2">
															<select value={modalDatosCliente.genero}
																onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'genero', ev.target.value)}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															>
																<option value={1}>Masculino</option>
																<option value={2}>Femenino</option>
															</select>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">¿Es un cliente importante?</label></div>
														<div className="sm:col-span-2">
															<select value={modalDatosCliente.prioridad}
																onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'prioridad', ev.target.value)}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															>
																<option value={0}>No</option>
																<option value={1}>Si</option>
															</select>
														</div>
													</div>
													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Solicitar crédito para este cliente</label></div>
														<div className="sm:col-span-2">
															<select value={modalDatosCliente.solicitud_credito}
																onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'solicitud_credito', ev.target.value)}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															>
																<option value={0}>No</option>
																<option value={1}>Si</option>
															</select>
														</div>
													</div>

													{
														modalDatosCliente.solicitud_credito === "1" &&
														<p className="px-5 py-5 text-gray-800 dark:text-red-500">Para ser acreedor de un crédito el cliente deberá enviar una documentación. Registra el perfil y se abrirá el panel de solicitud de crédito.</p>
													}

													<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5" style={{ display: 'none', }}>
														<div><label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Límite de crédito</label></div>
														<div className="sm:col-span-2">
															<input value={modalDatosCliente.credito} onChange={(ev) => F.select__nuevoCliente__cambiaValor(state, setState, _this, 'credito', ev.target.value)} type={'number'}
																className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
															/>
														</div>
													</div>

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "sliderNuevoCliente", false)}
													>
														Cancelar
													</button>
													<button
														type="submit"
														className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
														onClick={(e) => {
															e.preventDefault();
															F.registrarNuevoCliente(state, setState, _this)
														}}
													>
														Registrar
													</button>
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export class PageHeader extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="bg-white shadow">
				<div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
					<div className="py-6 md:flex md:items-center md:justify-between">
						<div className="min-w-0 flex-1">
							{/* Profile */}
							<div className="flex items-center">
								<span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100">
									<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
										<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
									</svg>
								</span>
								<div>
									<div className="flex items-center">
										<img
											className="h-16 w-16 rounded-full sm:hidden"
											src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
											alt=""
										/>
										<h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
											Emmmanuel Domínguez
										</h1>
									</div>
									<dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
										<dt className="sr-only">Company</dt>
										<dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
											<BuildingOfficeIcon
												className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
												aria-hidden="true"
											/>
											Ciudad Valles
										</dd>
										<dt className="sr-only">Account status</dt>
										<dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
											<CheckCircleIcon
												className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
												aria-hidden="true"
											/>
											Cuenta Verificada
										</dd>
									</dl>
								</div>
							</div>
						</div>
						<div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
							<button
								type="button"
								className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							>
								Add money
							</button>
							<button
								type="button"
								className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
							>
								Send money
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export class OverviewProfile extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { state, setState, _this, } = this.props
		const { nuevaVenta } = state;
		return (
			<div className="mt-8">
				<div>
					<h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
					<div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
						{/* Card */}
						<div className="flex items-center overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow cursor-pointer"
							onClick={() => {
								F.obtenerOrdenesSinAutorizar(state, setState, _this)
							}}
						>
							<div className="p-5 w-full">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<DocumentCheckIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
									</div>
									<div className="ml-5 w-0 flex-1">
										<dl>
											<dt className="truncate text-sm font-medium text-gray-500 dark:text-gray-200">Cotizaciones pend. autorización</dt>
											<dd>
												<div className="text-lg font-medium text-gray-900 dark:text-gray-200">{nuevaVenta?.ordenes_pendientes}</div>
											</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-center overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow cursor-pointer"
							onClick={() => {
								F.obtenerUltimasOrdenesPorCliente(state, setState, _this, 110)
							}}
						>
							<div className="p-5 w-full">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<BanknotesIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
									</div>
									<div className="ml-5 w-0 flex-1">
										<dl>
											<dt className="truncate text-sm font-medium text-gray-500 dark:text-gray-200">Ver últimas ventas</dt>
											<dd>
												<div className="text-lg font-medium text-gray-900 dark:text-gray-200">{nuevaVenta?.progreso?.ventas_producidas}</div>
											</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-center overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow cursor-pointer"
							onClick={() => {
								/*F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", true)*/
							}}
						>
							<div className="p-5 w-full">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<CreditCardIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
									</div>
									<div className="ml-5 w-0 flex-1">
										<dl>
											<dt className="truncate text-sm font-medium text-gray-500 dark:text-gray-200">Crédito disponible</dt>
											<dd>
												<div className="text-lg font-medium text-gray-900 dark:text-gray-200">${F.number_format(parseFloat(nuevaVenta?.ficha?.credito) - parseFloat(nuevaVenta.deuda))} <span className="text-xs text-gray-500">de ${F.number_format(nuevaVenta?.ficha?.credito)}</span> </div>
												<div className="text-md font-medium text-gray-700 dark:text-gray-300">Adeuda: ${F.number_format(nuevaVenta?.deuda, 2)}</div>
											</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	}
}

export class ClientInformation extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	render() {

		const { state, setState, _this } = this.props;
		const { nuevaVenta } = state;

		return (
			<div className="mt-8 grid grid-col-12 gap-6 lg:grid-flow-col-dense">
				<div className="space-y-6 lg:col-span-2 lg:col-start-1">
					{/* Description list*/}
					<section aria-labelledby="applicant-information-title">
						<div className="bg-white dark:bg-slate-800 shadow sm:rounded-lg">
							<div className="px-4 py-5 sm:px-6">
								<h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
									{nuevaVenta?.ficha?.nombre_completo}
								</h2>
								<p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-300">Ficha rápida de tu cliente.</p>
							</div>
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Nombre del cliente</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.nombre}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Apellido paterno</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.a_paterno}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Apellido materno</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.a_materno}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Teléfono</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.telefono}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Correo electrónico</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.correo}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Género</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.genero === '1' ? 'Masculino' : 'Femenino'}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Límite de crédito</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{F.formateaCantidad(nuevaVenta?.ficha?.credito)}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500 dark:text-gray-300">¿Es cliente relevante?</dt>
										<dd className="mt-1 text-sm text-gray-900 dark:text-gray-200">{nuevaVenta?.ficha?.prioridad === '1' ? 'Si' : 'No'}</dd>
									</div>
								</dl>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export class SlideOverAumentarCredito extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		const { state, setState, _this, } = this.props
		const { slideAumentarCredito, sidebarInputs, } = state


		if (typeof slideAumentarCredito === 'undefined')
			return null

		return (
			<Transition.Root show={slideAumentarCredito} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
																Aumentar límite de crédito
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-300">
																* Al realizar un cambio en el límite de crédito para tu cliente se notificará a los administradores.
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													{/* Aquí van los subcomponentes */}
													<div className="px-6 py-5">
														<label htmlFor="credito" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
															Crédito
														</label>
														<div className="mt-2">
															<input
																type="number"
																name="credito"
																id="credito"
																className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																placeholder=""
																onChange={(e) => F.input__dinamico__cambiaValor(state, setState, _this, 'limite_credito', e.target.value)}
															/>
														</div>
													</div>

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}
													>
														Cancelar
													</button>
													<button
														type="button"
														className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
														onClick={(e) => { F.fichaCliente__registraCambioCredito(state, setState, _this) }}
													>
														Cambiar límite de crédito
													</button>
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export class SlideOverCotizacionesPendientes extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		const { state, setState, _this, } = this.props
		const { slideCotizacionesPendientes, arrVentasSinAutorizar, id_orden_selected, arrProductosVentas, arrPagos, } = state

		return (
			<Transition.Root show={slideCotizacionesPendientes} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideCotizacionesPendientes", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
																Ventas pendientes de autorización
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-300">
																<span className="text-red-600">Estas ventas no puede ser facturadas hasta ser autorizadas.</span>
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "slideCotizacionesPendientes", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
													{/* Aquí van los subcomponentes */}

													{
														typeof id_orden_selected === 'string' ? (
															<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																	<button
																		onClick={() => { setState({ id_orden_selected: null }) }}
																		type="button"
																		className="my-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
																	>
																		<ArrowLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																		Regresar
																	</button>

																	{/* <p onClick={}
																		className="bg-red-500 w-20 "
																	>Regresar</p> */}
																	<p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">PRODUCTOS</p>
																	<table className="min-w-full divide-y divide-gray-300">
																		<thead>
																			<tr>
																				<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																					Nombre
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Cantidad
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Total $
																				</th>
																			</tr>
																		</thead>
																		<tbody className="divide-y divide-gray-200">
																			{
																				arrProductosVentas.map(t => (
																					<tr>
																						<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																							{t.producto}
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							<span>{F.calculadorToneladas(t.cantidad_conversion)}</span>
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							${F.number_format(t.total_pagar)}
																						</th>
																					</tr>
																				))
																			}
																		</tbody>
																	</table>
																</div>
																<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																	<p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">Historial de pagos</p>
																	<table className="min-w-full divide-y divide-gray-300">
																		<thead>
																			<tr>
																				<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																					Cantidad
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Fecha de pago
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Observación
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-center text-sm font-normal text-gray-900">
																					Cotejada
																				</th>
																			</tr>
																		</thead>
																		<tbody className="divide-y divide-gray-200">
																			{
																				arrPagos.map(t => (
																					<tr>
																						<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																							<p>${F.number_format(t.cantidad)}</p>
																							<p className="text-xs">{F.textFormaPagoCobranza(t.forma_pago)}</p>
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							{moment(t.created_at).format('DD/MM/YYYY HH:mm')}
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							<p>{t.observacion}</p>
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-center text-sm text-gray-900">
																							<p>{t.venta_cotejada === '1' ? "Si" : 'Aún no'}</p>
																							<p>{t.comprobante !== "" ? "Pulsa para ver comprobante" : "No se subió un comprobante"}</p>
																						</th>
																					</tr>
																				))
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														) : (
															<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																	<table className="min-w-full divide-y divide-gray-300">
																		<thead>
																			<tr>
																				<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																					Fecha de venta

																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Nombre del cliente
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Forma de pago
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Total a pagar
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Total abonado
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					KG Totales
																				</th>
																				<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																					Opciones
																				</th>
																			</tr>
																		</thead>
																		<tbody className="divide-y divide-gray-200">
																			{
																				arrVentasSinAutorizar.map(t => (
																					<tr>
																						<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																							{moment(t.created_at).format('DD/MM/YYYY HH:mm')}
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							<span>{t.nombre_cliente} {t.a_paterno} {t.a_materno}</span>
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							{F.textoFormaDePago(t.forma_pago)}
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							${F.number_format(t.total_pagar)}
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							<p>${F.number_format(t.cantidad)}</p>
																							<p className="text-xs text-red-500">Resta: <b>${F.number_format(t.resta)}</b></p>
																						</th>
																						<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																							{F.calculadorToneladas(t.cantidad_conversion)}
																						</th>
																						<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																							<p onClick={() => { F.obtenerInformacionOrden(state, setState, _this, t.id_orden) }} className="cursor-pointer">Ver más</p>
																						</th>
																					</tr>
																				))
																			}

																		</tbody>
																	</table>
																</div>
															</div>
														)
													}

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "slideCotizacionesPendientes", false)}
													>
														Cancelar
													</button>
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export class SlideOverUltimasVentas extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		const { state, setState, _this, } = this.props
		const { slideUltimasVentas, sidebarInputs, id_orden_selected, arrVentas, arrProductosVentas, arrPagos, slideUltimasVentasOnlyOrden, isRegisterAbono, } = state

		let venta = (arrVentas.filter(v => v.id_orden === id_orden_selected))[0]

		if (typeof venta === 'undefined')
			venta = {}

		return (
			<Transition.Root show={slideUltimasVentas} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideUltimasVentas", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
																Últimas ventas realizadas
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-300">
																<span className="text-gray-600">Aquí puedes observar el historial de ventas realizadas.</span>
															</p>

															<p className="text-sm text-gray-500">
																<div className="space-x-2 mt-4 sm:grid sm:grid-cols-3">
																	<div><label htmlFor="nombre_usuario" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 sm:mt-1.5">Total de registros</label></div>
																	<div className="sm:col-span-2">
																		<select value={sidebarInputs.total_ultimos_registros} onChange={(ev) => {
																			F.obtenerUltimasOrdenesPorCliente(state, setState, _this, ev.target.value)
																		}}
																			className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
																			placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																		>
																			<option selected={true} value="10">10</option>
																			<option value="20">20</option>
																			<option value="50">50</option>
																			<option value="100">100</option>

																		</select>
																	</div>
																</div>
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => {
																	setState({ id_orden_selected: null, })
																	F.handleAccionarSlider(state, setState, _this, "slideUltimasVentas", false)
																}}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													{/* Divider container */}
													<div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

														{/* Aquí van los subcomponentes */}
														{
															typeof id_orden_selected === 'string' ? (
																<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																	<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

																		<button
																			onClick={() => {
																				// Evaluamos si viene de una orden de venta directa
																				// Es decir si no viene de fichaCliente -> ultimas ventas
																				if (slideUltimasVentasOnlyOrden) {
																					F.handleAccionarSlider(state, setState, _this, "slideUltimasVentas", false)
																				} else {
																					setState({ id_orden_selected: null })
																				}
																			}}
																			type="button"
																			className="my-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
																		>
																			{
																				slideUltimasVentasOnlyOrden ? (
																					<>
																						<XMarkIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																						Cerrar
																					</>
																				) : (
																					<>
																						<ArrowLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																						Regresar
																					</>
																				)
																			}
																		</button>

																		{/* <p onClick={() => { setState({ id_orden_selected: null }) }}
																			className="bg-red-500 w-20 "
																		>Regresar</p> */}
																		<p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">Historial de productos ({arrProductosVentas.length})</p>
																		<table className="min-w-full divide-y divide-gray-300">
																			<thead>
																				<tr>
																					<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																						Nombre
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Cantidad
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Total $
																					</th>
																				</tr>
																			</thead>
																			<tbody className="divide-y divide-gray-200">
																				{
																					arrProductosVentas.map(t => (
																						<tr>
																							<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																								{t.producto}
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								<span>{F.calculadorToneladas(t.cantidad_conversion)}</span>
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								${F.number_format(t.total_pagar)}
																							</th>
																						</tr>
																					))
																				}
																			</tbody>
																		</table>
																	</div>
																	<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																		<p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">Historial de pagos ({arrPagos.length}) - Adeuda: ${F.number_format((parseFloat(venta.sum_total_pagar) - parseFloat(venta.sum_total_cantidad)), 2)}</p>
																		{
																			state.requiere_auth == "1"
																				? <p className="text-red-900">Lo sentimos, el administrador aún no autoriza esta compra, por ello no puedes registrar abonos.</p>
																				:
																				<button
																					onClick={() => {
																						setState({
																							isRegisterAbono: !state.isRegisterAbono,
																						})
																					}}
																					type="button"
																					className={`my-3 inline-flex items-center gap-x-1.5 rounded-md bg-${isRegisterAbono ? 'red' : 'green'}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${isRegisterAbono ? 'red' : 'green'}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
																				>
																					{
																						isRegisterAbono
																							? 'Cancelar registro de abono'
																							: 'Registrar nuevo abono'
																					}
																				</button>
																		}

																		{
																			isRegisterAbono ? (
																				<>
																					<div>

																						<div className="py-2">
																							<label htmlFor="forma_pago" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																								Forma de pago
																							</label>
																							<select
																								id="forma_pago"
																								name="forma_pago"
																								className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																								value={sidebarInputs.fPago}
																								onChange={(ev) => {
																									sidebarInputs.fPago = ev.target.value
																									setState({ sidebarInputs })
																								}}
																							>
																								<option selected={true} disabled={true}>Elige una forma de pago</option>
																								<option value={'efectivo'}>Efectivo</option>
																								<option value={'t_credito'}>Tarjeta de crédito</option>
																								<option value={'t_debito'}>Tarjeta de débito</option>
																								<option value={'SPEI'}>Transferencia electrónica</option>
																								<option value={'deposito'}>Depósito bancario</option>
																								<option value={'sie'}>Convenio SIE</option>
																							</select>
																						</div>

																						<div className="py-2">
																							<label htmlFor="cantidad" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																								Cantidad <span>{
																									parseFloat(sidebarInputs.cantidad) > 0 &&
																									<b style={{ fontSize: 10, }}>({F.formateaTotalCarrito(parseFloat(sidebarInputs.cantidad))})</b>
																								}</span>
																							</label>
																							<div className="mt-2">
																								<input
																									value={sidebarInputs.cantidad}
																									onChange={(ev) => {
																										sidebarInputs.cantidad = ev.target.value
																										setState({ sidebarInputs })
																									}}
																									type="number"
																									name="cantidad"
																									id="cantidad"
																									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																									placeholder="Ingresa la cantidad recibida"
																								/>
																								<p>Faltante: ${F.number_format((parseFloat(venta.sum_total_pagar) - parseFloat(venta.sum_total_cantidad) - sidebarInputs.cantidad), 2)}</p>
																							</div>
																						</div>

																						<div>
																							<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																								Observaciones
																							</label>
																							<div className="mt-2">
																								<textarea
																									value={sidebarInputs.observacion}
																									onChange={(ev) => {
																										sidebarInputs.observacion = ev.target.value
																										setState({ sidebarInputs })
																									}}
																									rows={4}
																									name="comment"
																									id="comment"
																									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																									placeholder="Ingresa una observación (opcional)"
																								/>
																							</div>
																						</div>

																					</div>

																				</>
																			) : (
																				<table className="min-w-full divide-y divide-gray-300">
																					<thead>
																						<tr>
																							<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																								Cantidad
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																								Fecha de pago
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																								Observación
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-center text-sm font-normal text-gray-900">
																								Cotejada
																							</th>
																						</tr>
																					</thead>
																					<tbody className="divide-y divide-gray-200">
																						{
																							arrPagos.map(t => (
																								<tr>
																									<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																										<p>${F.number_format(t.cantidad)}</p>
																										<p className="text-xs">{F.textFormaPagoCobranza(t.forma_pago)}</p>
																									</th>
																									<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																										{moment(t.created_at).format('DD/MM/YYYY HH:mm')}
																									</th>
																									<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																										<p>{t.observacion}</p>
																									</th>
																									<th scope="col" className="px-3 py-3.5 text-center text-sm text-gray-900">
																										<p>{t.venta_cotejada === '1' ? "Si" : 'Aún no'}</p>
																										<p>{t.comprobante !== "" ? "Pulsa para ver comprobante" : "No se subió un comprobante"}</p>
																									</th>
																								</tr>
																							))
																						}
																					</tbody>
																				</table>
																			)
																		}
																	</div>
																</div>
															) : (
																<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																	<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																		<table className="min-w-full divide-y divide-gray-300">
																			<thead>
																				<tr>
																					<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																						Fecha de venta

																					</th>
																					{/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Nombre del cliente
																					</th> */}
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Forma de pago
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Total a pagar
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Total abonado
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Pagos cotejados
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						KG Totales
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Opciones
																					</th>
																				</tr>
																			</thead>
																			<tbody className="divide-y divide-gray-200">
																				{
																					arrVentas.map(t => (
																						<tr>
																							<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																								{moment(t.created_at).format('DD/MM/YYYY HH:mm')}
																							</th>
																							{/* <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								<span>{t.nombre_cliente} {t.a_paterno} {t.a_materno}</span>
																							</th> */}
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								<p className={t.requiere_auth == "0" ? 'text-green-800' : 'text-red-500'}>{t.requiere_auth == "0" ? 'Autorizado' : 'Sin autorizar'}</p>
																								<p>{F.textoFormaDePago(t.forma_pago)}</p>
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								${F.number_format(t.sum_total_pagar)}
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{
																									F.number_format(parseFloat(t.sum_total_pagar) - parseFloat(t.sum_total_cantidad), 0) > 0
																										? <>
																											<p>${F.number_format(t.sum_total_cantidad)}</p>
																											<p className="text-xs text-red-500">Resta: <b>${F.number_format(parseFloat(t.sum_total_pagar) - parseFloat(t.sum_total_cantidad), 2)}</b></p>
																										</>
																										: <p className="text-xs text-green-800"><b>Liquidado</b></p>
																								}
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.count_cotejado} de {t.count_pagos}
																							</th>
																							<th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{F.calculadorToneladas(t.sum_total_kg_conversion)}
																							</th>
																							<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																								<p onClick={() => { F.obtenerInformacionOrden(state, setState, _this, t.id_orden) }} className="cursor-pointer">Ver más</p>
																							</th>
																						</tr>
																					))
																				}

																			</tbody>
																		</table>
																	</div>
																</div>
															)
														}

													</div>

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													{
														isRegisterAbono ? (
															<>
																{
																	F.number_format((parseFloat(venta.sum_total_pagar) - parseFloat(venta.sum_total_cantidad) - sidebarInputs.cantidad), 0) < 0 ?
																		<p className="text-sm font-semibold text-red-400">La cantidad ingresada supera el valor a pagar del cliente.</p>
																		:
																		<button
																			type="button"
																			className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-gray-300 hover:bg-green-700"
																			onClick={() => F.registrarNuevoAbono(state, setState, _this)}
																		>
																			Registrar abono
																		</button>
																}
															</>
														) : (
															<button
																type="button"
																className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "slideUltimasVentas", false)}
															>
																Cerrar
															</button>
														)
													}
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog >
			</Transition.Root >
		)
	}
}

export class SlideOverDireccionesEntrega extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
		// F.obtenerDireccionesEntrega(this.props.state, this.props.setState, this.props._this)
	}

	render() {

		const { state, setState, _this, closeForm, } = this.props;
		const { slideDireccionesEntrega, arrInputsDireccionEntrega, arrDireccionesEntrega, view, } = state;
		return (
			<Transition.Root show={slideDireccionesEntrega} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesEntrega", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
																Lista de direcciones de entrega
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-400">
																<span className="text-gray-600 dark:text-gray-400">Aquí puedes observar la lista de direcciones de entrega.</span>
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 dark:text-gray-200 hover:text-gray-500 dark:text-gray-200"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesEntrega", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													{
														view === 'tabla' && (
															<React.Fragment>
																<div className="py-3 text-end">
																	<div>
																		<button
																			onClick={() => { setState({ view: 'formulario' }) }}
																			type="button"
																			className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																		>
																			Agregar dirección de entrega
																		</button>
																	</div>
																</div>
																<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																	<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																		<table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
																			<thead>
																				<tr>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						Calle
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						No. exterior
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						No. interior
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						Colonia
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						CP
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						Localidad
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						Municipio
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-gray-300">
																						Estado
																					</th>
																				</tr>
																			</thead>
																			<tbody className="divide-y divide-gray-200">
																				{
																					arrDireccionesEntrega.map((t, e) => (
																						<tr>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.calle}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.no_exterior}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.no_interior}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.colonia}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.cp}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.localidad}
																							</td>
																							<td scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																								{t.municipio}
																							</td>
																							<td scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																								{t.estado}
																							</td>
																						</tr>
																					))
																				}

																			</tbody>
																		</table>
																	</div>
																</div>
															</React.Fragment>
														)
													}

													{
														view === 'formulario' && (
															<React.Fragment>
																{
																	closeForm ? (
																		<button
																			onClick={() => { setState({ slideDireccionesEntrega: false, }) }}
																			type="button"
																			className="my-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																		>
																			<XMarkIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																			Cerrar
																		</button>
																	) : (
																		<button
																			onClick={() => { setState({ view: 'tabla' }) }}
																			type="button"
																			className="my-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																		>
																			<ArrowLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																			Atrás
																		</button>
																	)
																}

																<div className="px-6 py-5">
																	<label htmlFor="calle" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Calle *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="calle"
																			id="calle"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa la calle"
																			value={arrInputsDireccionEntrega.calle}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'calle', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="no_exterior" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Número exterior *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="no_exterior"
																			id="no_exterior"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa el número exterior"
																			value={arrInputsDireccionEntrega.no_exterior}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'no_exterior', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="no_interior" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Número interior
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="no_interior"
																			id="no_interior"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa el número interior"
																			value={arrInputsDireccionEntrega.no_interior}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'no_interior', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="colonia" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Colonia *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="colonia"
																			id="colonia"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa la colonia"
																			value={arrInputsDireccionEntrega.colonia}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'colonia', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="codigo_postal" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Código postal *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="codigo_postal"
																			id="codigo_postal"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa el código postal"
																			value={arrInputsDireccionEntrega.cp}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'cp', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="localidad" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Localidad
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="localidad"
																			id="localidad"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa la localidad"
																			value={arrInputsDireccionEntrega.localidad}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'localidad', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="municipio" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Municipio *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="municipio"
																			id="municipio"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa el municipio"
																			value={arrInputsDireccionEntrega.municipio}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'municipio', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="px-6 py-5">
																	<label htmlFor="estado" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Estado *
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="estado"
																			id="estado"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa el estado"
																			value={arrInputsDireccionEntrega.estado}
																			onChange={(e) => {
																				F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'estado', e.target.value)
																			}}
																		/>
																	</div>
																</div>

																<div className="space-y-2 py-5 px-5">
																	<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Observaciones
																	</label>
																	<div className="mt-2">
																		<textarea
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'arrInputsDireccionEntrega', 'observaciones', e.target.value) }}
																			rows={4}
																			name="observaciones"
																			id="observaciones"
																			placeholder="Ingresa una observación"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			value={arrInputsDireccionEntrega.observaciones}
																		/>
																	</div>
																</div>
															</React.Fragment>
														)
													}

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesEntrega", false)}
													>
														Cerrar
													</button>
													{
														(view === 'formulario') && (
															<button
																type="button"
																className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																onClick={() => { F.registrarDireccionEntrega(state, setState, _this, closeForm) }}
															>
																Registrar
															</button>
														)
													}
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export class SlideOverDireccionesFiscales extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			arrRegimenFiscal: [
				{ "name": "Selecciona una opción", "value": "" },
				{ "name": "General de Ley Personas Morales", "value": "601" },
				{ "name": "Personas Morales con Fines no Lucrativos", "value": "603" },
				{ "name": "Sueldos y Salarios e Ingresos Asimilados a Salarios", "value": "605" },
				{ "name": "Arrendamiento", "value": "606" },
				{ "name": "Régimen de Enajenación o Adquisición de Bienes", "value": "607" },
				{ "name": "Demás ingresos", "value": "608" },
				{ "name": "Residentes en el Extranjero sin Establecimiento Permanente en México", "value": "610" },
				{ "name": "Ingresos por Dividendos (socios y accionistas)", "value": "611" },
				{ "name": "Personas Físicas con Actividades Empresariales y Profesionales", "value": "612" },
				{ "name": "Ingresos por intereses", "value": "614" },
				{ "name": "Régimen de los ingresos por obtención de premios", "value": "615" },
				{ "name": "Sin obligaciones fiscales", "value": "616" },
				{ "name": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", "value": "620" },
				{ "name": "Incorporación Fiscal", "value": "621" },
				{ "name": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", "value": "622" },
				{ "name": "Opcional para Grupos de Sociedades", "value": "623" },
				{ "name": "Coordinados", "value": "624" },
				{ "name": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas", "value": "625" },
				{ "name": "Régimen Simplificado de Confianza", "value": "626" }
			]
		}
	}

	componentDidMount() {
		// F.obtenerDireccionesFiscales(this.props.state, this.props.setState, this.props._this)
	}

	render() {

		const { state, setState, _this } = this.props;
		const { slideDireccionesFiscales, arrRegistroNuevaUbicacion, inputsNuevaRazonSocial, arrDireccionesFiscales, view, } = state;
		const { arrRegimenFiscal } = this.state;
		return (
			<Transition.Root show={slideDireccionesFiscales} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesFiscales", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
																Lista de direcciones fiscales
															</Dialog.Title>
															<p className="text-sm text-gray-500 dark:text-gray-300">
																<span className="text-gray-600">Aquí puedes observar la lista de direcciones fiscales.</span>
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesFiscales", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													{
														view === 'tabla' && (
															<React.Fragment>
																<div className="py-3 text-end">
																	<div>
																		<button
																			onClick={() => { setState({ view: 'formulario' }) }}
																			type="button"
																			className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																		>
																			Registrar nueva razón social
																		</button>
																	</div>
																</div>
																<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
																	<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
																		<table className="min-w-full divide-y divide-gray-300">
																			<thead>
																				<tr>
																					<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
																						Razón social
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Calle
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						No. exterior
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						No. interior
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Colonia
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						CP
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Localidad
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Municipio
																					</th>
																					<th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
																						Estado
																					</th>
																				</tr>
																			</thead>
																			<tbody className="divide-y divide-gray-200">
																				{
																					arrDireccionesFiscales.map((t, e) => (
																						<tr>
																							<td scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
																								{t.razon_social}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.calle}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.no_exterior}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.no_interior}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.colonia}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.cp}
																							</td>
																							<td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
																								{t.localidad}
																							</td>
																							<td scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																								{t.municipio}
																							</td>
																							<td scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
																								{t.estado}
																							</td>
																						</tr>
																					))
																				}
																			</tbody>
																		</table>
																	</div>
																</div>
															</React.Fragment>
														)
													}

													{
														view === 'formulario' && (
															<React.Fragment>
																<button
																	onClick={() => { setState({ view: 'tabla' }) }}
																	type="button"
																	className="my-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																>
																	<ArrowLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
																	Atrás
																</button>

																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="tipo_persona"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Tipo de persona
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'tipo_persona', e.target.value) }}
																			id="tipo_persona"
																			name="tipo_persona"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																			required
																			value={inputsNuevaRazonSocial.tipo_persona}
																		>
																			<option value="1">Persona física</option>
																			<option value="2">Persona física con actividad empresarial</option>
																			<option value="3">Persona moral</option>
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="regimen_fiscal"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Régimen fiscal
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'regimen_fiscal', e.target.value) }}
																			id="regimen_fiscal"
																			name="regimen_fiscal"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																			required
																			value={inputsNuevaRazonSocial.regimen_fiscal}
																		>
																			{
																				arrRegimenFiscal.map((t, e) => (
																					<option value={t.value}>{t.name}</option>
																				))
																			}
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="rfc"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			RFC
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'rfc', e.target.value) }}
																			type="text"
																			name="rfc"
																			id="rfc"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el rfc"
																			required
																			value={inputsNuevaRazonSocial.rfc}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="razon_social"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Razón	social
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'razon_social', e.target.value) }}
																			type="text"
																			name="razon_social"
																			id="razon_social"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la razón social"
																			required
																			value={inputsNuevaRazonSocial.razon_social}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="correo"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Correo electrónico
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'correo', e.target.value) }}
																			type="text"
																			name="correo"
																			id="correo"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el correo electrónico"
																			value={inputsNuevaRazonSocial.correo}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="calle"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Calle
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'calle', e.target.value) }}
																			type="text"
																			name="calle"
																			id="calle"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el nombre de la calle"
																			required
																			value={inputsNuevaRazonSocial.calle}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="no_exterior"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Número exterior
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'no_exterior', e.target.value) }}
																			type="text"
																			name="no_exterior"
																			id="no_exterior"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el número exterior"
																			required
																			value={inputsNuevaRazonSocial.no_exterior}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="no_interior"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Número interior
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'no_interior', e.target.value) }}
																			type="text"
																			name="no_interior"
																			id="no_interior"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el número interior"
																			value={inputsNuevaRazonSocial.no_interior}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="colonia"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Colonia
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'colonia', e.target.value) }}
																			type="text"
																			name="colonia"
																			id="colonia"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la colonia"
																			required
																			value={inputsNuevaRazonSocial.colonia}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="cp"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			CP
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'cp', e.target.value) }}
																			type="text"
																			name="cp"
																			id="cp"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el código postal"
																			required
																			value={inputsNuevaRazonSocial.cp}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="localidad"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Localidad
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'localidad', e.target.value) }}
																			type="text"
																			name="localidad"
																			id="localidad"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la localidad"
																			required
																			value={inputsNuevaRazonSocial.localidad}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="municipio"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Municipio
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'municipio', e.target.value) }}
																			type="text"
																			name="municipio"
																			id="municipio"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el municipio"
																			required
																			value={inputsNuevaRazonSocial.municipio}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="estado"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Estado
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'estado', e.target.value) }}
																			type="text"
																			name="estado"
																			id="estado"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el estado"
																			required
																			value={inputsNuevaRazonSocial.estado}
																		/>
																	</div>
																</div>
																<div className="space-y-2 py-5 px-5">
																	<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Observaciones
																	</label>
																	<div className="mt-2">
																		<textarea
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsNuevaRazonSocial', 'observaciones', e.target.value) }}
																			rows={4}
																			name="observaciones"
																			id="observaciones"
																			placeholder="Ingresa una observación"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			value={inputsNuevaRazonSocial.observaciones}
																		/>
																	</div>
																</div>
															</React.Fragment>
														)
													}

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "slideDireccionesFiscales", false)}
													>
														Cerrar
													</button>
													{
														(view === 'formulario') && (
															<button
																className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																onClick={(e) => { e.preventDefault(); F.registrarNuevaRazonSocial(state, setState, _this) }}
															>
																Registrar
															</button>
														)
													}
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export class BotonContinuar extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {

		const { state, setState, _this } = this.props;
		return (
			<>
				<div>
					<dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
						<a
							href="#"
							className="block bg-green-500 px-4 py-4 mt-4 ml-4 mr-4 text-center text-sm font-medium text-neutral-50 hover:text-neutral-50 rounded"
							onClick={() => F.handleAccionarSlider(this.props.state, this.props.setState, this, "slideDireccionesEntrega", true)}
						>
							Ver lista de direcciones de entrega
						</a>
						<a
							href="#"
							className="block bg-green-500 px-4 py-4 mt-4 ml-4 mr-4 text-center text-sm font-medium text-neutral-50 hover:text-neutral-50 rounded"
							onClick={() => F.handleAccionarSlider(this.props.state, this.props.setState, this, "slideDireccionesFiscales", true)}
						>
							Ver lista de direcciones fiscales
						</a>
					</dl>
				</div>
				<div className="py-6">
					<NavLink
						type="button"
						to={`../pos/carrito/${state.id_cliente}`}
						className="w-full justify-center inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						Crear nueva venta
						<ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
					</NavLink>
					{/* <a
					href="#"
					onClick={() => { console.log('continuar') }}
					type="button"
					className="w-full justify-center inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
				>
					Continuar Cotizacion
					<ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
				</a> */}
				</div>
			</>
		)
	}
}

export class ListaProductos extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchProducto: ''
		}
	}
	render() {

		const { state, setState, _this } = this.props;
		const { arrProductos, inputProducto, arrProductosResults } = state;

		return (
			<>
				<div className="border-b border-gray-200 pb-5 pt-8">
					<h3 className="text-base font-semibold leading-6 text-gray-900">Productos disponibles</h3>
					<p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">
						Los productos mostrados son los más recientes y sus costos están actualizados.
					</p>
				</div>
				<div>
					<div className="pt-5 pb-0">
						<label htmlFor="buscar_producto" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
							Buscar Producto
						</label>
						<div className="mt-2">
							<input
								type="search"
								name="buscar_producto"
								id="buscar_producto"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 dark:bg-gray-800 dark:ring-gray-700 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
								placeholder="Buscar por nombre de producto..."
								value={inputProducto}
								onChange={(e) => {
									// F.handleChangeInput(state,setState,_this,'inputProducto', e.target.value) 
									F.input__buscarProducto(state, setState, _this, e.target.value)
								}}
							/>
						</div>
					</div>
				</div>
				<div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg bg-neutral-50 dark:bg-gray-800 dark:ring-gray-700">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:pl-6">
									Producto
								</th>
								<th
									scope="col"
									className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 lg:table-cell"
								>
									Clave
								</th>
								<th
									scope="col"
									className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 lg:table-cell"
								>
									Venta por
								</th>
								<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
									Precio
								</th>
								<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
									<span className="sr-only">Select</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{
								inputProducto.length === 0 ?
									arrProductos.map((producto, productoIdx) => (
										<tr key={producto.id_producto}>
											<td
												className={classNames(
													productoIdx === 0 ? '' : 'border-t border-transparent',
													'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
												)}
											>
												<div className="font-medium text-gray-900 dark:text-gray-300">
													{producto.producto}
												</div>
												<div className="mt-1 flex flex-col text-gray-500 dark:text-gray-300 sm:block lg:hidden">
													<span>
														{producto.clave} / {producto.unidad}
													</span>
													<span className="hidden sm:inline">·</span>
												</div>
												{productoIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
											</td>
											<td
												className={classNames(
													productoIdx === 0 ? '' : 'border-t border-gray-200',
													'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell dark:text-gray-300'
												)}
											>
												{producto.clave}
											</td>
											<td
												className={classNames(
													productoIdx === 0 ? '' : 'border-t border-gray-200',
													'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell dark:text-gray-300'
												)}
											>
												{producto.unidad}
											</td>
											<td
												className={classNames(
													productoIdx === 0 ? '' : 'border-t border-gray-200',
													'px-3 py-3.5 text-sm text-gray-500 dark:text-gray-300'
												)}
											>
												{/* <div className="sm:hidden">{producto.price}/mo</div> */}
												<div>Contado: ${F.number_format(producto.costo_venta, 2)}</div>
												<div>A 15 días: ${F.number_format(producto.costo_venta_15, 2)}</div>
												<div>A 30 días: ${F.number_format(producto.costo_venta_30, 2)}</div>
											</td>
											<td
												className={classNames(
													productoIdx === 0 ? '' : 'border-t border-transparent',
													'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
												)}
											>
												<button
													type="button"
													className={`inline-flex items-center rounded-md ${producto.selected ? 'bg-red-500' : 'bg-white'} px-2.5 py-1.5 text-sm font-semibold ${producto.selected ? 'text-gray-100' : 'text-gray-900'} shadow-sm ring-1 ring-inset ${producto.selected ? 'ring-red-500' : 'ring-gray-300'} ${producto.selected ? 'hover:bg-red-400' : 'hover:bg-gray-50'} disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white`}
													onClick={() => F.elegirProductoAlCarrito(state, setState, this, 1, productoIdx)}
												>
													{producto.selected ? 'Quitar del carrito' : 'Agregar al carrito'}
												</button>
												{productoIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
											</td>
										</tr>
									))
									: arrProductosResults.length === 0
										? 'No se encontraron resultados con esta búsqueda.'
										: arrProductosResults.map((producto, productoIdx) => (
											<tr key={producto.id_producto}>
												<td
													className={classNames(
														productoIdx === 0 ? '' : 'border-t border-transparent',
														'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
													)}
												>
													<div className="font-medium text-gray-900">
														{producto.producto}
													</div>
													<div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
														<span>
															{producto.clave} / {producto.unidad}
														</span>
														<span className="hidden sm:inline">·</span>
													</div>
													{productoIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
												</td>
												<td
													className={classNames(
														productoIdx === 0 ? '' : 'border-t border-gray-200',
														'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
													)}
												>
													{producto.clave}
												</td>
												<td
													className={classNames(
														productoIdx === 0 ? '' : 'border-t border-gray-200',
														'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
													)}
												>
													{producto.unidad}
												</td>
												<td
													className={classNames(
														productoIdx === 0 ? '' : 'border-t border-gray-200',
														'px-3 py-3.5 text-sm text-gray-500'
													)}
												>
													{/* <div className="sm:hidden">{producto.price}/mo</div> */}
													<div>{producto.precio_venta}</div>
												</td>
												<td
													className={classNames(
														productoIdx === 0 ? '' : 'border-t border-transparent',
														'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
													)}
												>
													<button
														type="button"
														className={`inline-flex items-center rounded-md ${producto.selected ? 'bg-red-500' : 'bg-white'} px-2.5 py-1.5 text-sm font-semibold ${producto.selected ? 'text-gray-100' : 'text-gray-900'} shadow-sm ring-1 ring-inset ${producto.selected ? 'ring-red-500' : 'ring-gray-300'} ${producto.selected ? 'hover:bg-red-400' : 'hover:bg-gray-50'} disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white`}
														onClick={() => F.elegirProductoAlCarrito(state, setState, this, 2, productoIdx)}
													>
														{producto.selected ? 'Quitar del carrito' : 'Agregar al carrito'}
													</button>
													{productoIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
												</td>
											</tr>
										))
							}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}

export class CantidadesProductos extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { state, setState, _this, } = this.props

		const { nuevaVenta } = state
		if (!nuevaVenta.ficha)
			return null

		const { arrCarrito, ficha, } = nuevaVenta
		const { nombre } = ficha

		return (
			<>
				<div className="border-b border-gray-200 pb-5 pt-8">
					<h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">({arrCarrito.length}) Productos elegidos para {nombre}</h3>
					<p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">
						Por favor, indica las cantidades de los productos que quieres agregar al carrito.
					</p>
				</div>

				{
					typeof arrCarrito !== 'object'
						? null
						: arrCarrito.length === 0 ?
							<div>
								<p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">Te haz quedado sin productos en el carrito, <b className="cursor-pointer" onClick={() => F.regresarAElegirProductos(state, setState, _this)}>pulsa aquí</b> para regresar y elegir nuevos productos.</p>
							</div>
							:
							<div className="bg-white dark:bg-gray-800 my-5 py-5 px-5 rounded-md shadow-md">
								<h2 className="pb-5 text-lg dark:text-gray-300">¿Cómo realizará el pago {nombre}?</h2>
								<hr className="pb-5" />
								<div>
									<nav className="flex space-x-4" aria-label="Tabs">
										<a
											className={`${nuevaVenta.fPago === 'contado' ? 'bg-red-500 text-white' : 'text-black-500 hover:text-gray-700 dark:text-gray-300'} cursor-pointer rounded-md px-3 py-2 text-sm font-medium`}
											onClick={() => { F.seleccionaFormaDePago(state, setState, _this, 'contado') }}
										>
											Contado
										</a>
										<a
											className={`${nuevaVenta.fPago === '15' ? 'bg-red-500 text-white' : 'text-black-500 hover:text-gray-700 dark:text-gray-300'} cursor-pointer rounded-md px-3 py-2 text-sm font-medium`}
											onClick={() => { F.seleccionaFormaDePago(state, setState, _this, '15') }}
										>
											15 días
										</a>
										<a
											className={`${nuevaVenta.fPago === '30' ? 'bg-red-500 text-white' : 'text-black-500 hover:text-gray-700 dark:text-gray-300'} cursor-pointer rounded-md px-3 py-2 text-sm font-medium`}
											onClick={() => { F.seleccionaFormaDePago(state, setState, _this, '30') }}
										>
											30 días
										</a>
										<a
											className={`${nuevaVenta.fPago === 'otro' ? 'bg-red-500 text-white' : 'text-black-500 hover:text-gray-700 dark:text-gray-300'} cursor-pointer rounded-md px-3 py-2 text-sm font-medium`}
											onClick={() => { F.seleccionaFormaDePago(state, setState, _this, 'otro') }}
										>
											Otro
										</a>
									</nav>
								</div>

								{
									nuevaVenta.fPago === 'otro' &&
									<div className="pt-4">
										<div>
											<p className="text-gray-600 bg-red-100 p-3 rounded-md dark:text-gray-300 dark:bg-red-700">El costo que definas en los productos deberá autorizarlos administración.</p>
										</div>
									</div>
								}

								<form className="py-5">
									<section>
										<ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">

											{
												arrCarrito.map((t, e) => (
													<li key={e} className="flex flex-col py-6">
														<div className="flex flex-col lg:flex-row w-full">
															<div className="flex-shrink-0">
																<img
																	src={'/img/cemento.png'}
																	alt="producto1"
																	className="h-20 w-20 rounded-md object-cover object-center sm:h-20 sm:w-20"
																/>
															</div>
															<div className="ml-0 pt-5 lg:pt-0 lg:ml-4 flex flex-1 flex-col">
																<div>
																	<div className="flex justify-between">
																		<h4 className="text-sm">
																			<a className="cursor-pointer font-medium text-gray-900 dark:text-gray-100">
																				<p><b>{t.producto}</b></p>
																			</a>
																		</h4>
																		<p className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">{t.unidad}</p>
																	</div>
																	<p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{t.clave}</p>
																</div>

																<div className="mt-4 flex flex-1 items-end justify-between">
																	<p className="flex items-center space-x-2 text-sm text-gray-700"></p>
																	<div className="ml-4">
																		<button type="button" className="text-sm font-medium text-red-600 hover:text-red-500" onClick={() => F.eliminarProductoDeCarrito(state, setState, this, t.indexFilter, e)}>
																			<span>Quitar del carrito</span>
																		</button>
																	</div>
																</div>
															</div>
														</div>
														<div className="flex flex-col">
															<div className="py-5">
																<form>
																	<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

																		<div className="sm:col-span-3">
																			<label htmlFor="cantidad_solicitada" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																				Cantidad solicitada {parseFloat(t.cantidad_solicitada) > 0 && `(${F.calculadorToneladas(F.convertirAKilos(t.cantidad_solicitada, t.unidad))})`}
																			</label>
																			<div className="mt-2">
																				<input
																					type="number"
																					name="cantidad_solicitada"
																					id="cantidad_solicitada"
																					placeholder="¿Cuántas KG/TON desea tu cliente?"
																					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																					value={t.cantidad_solicitada}
																					onChange={(ev) => { F.input__cambiarCantidadSolicitada(state, setState, _this, e, ev.target.value) }} />
																			</div>
																		</div>

																		<div className="sm:col-span-3">
																			<label htmlFor="cantidad_enviar" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																				Cantidad a enviar {parseFloat(t.cantidad_enviar) > 0 && `(${F.calculadorToneladas(F.convertirAKilos(t.cantidad_enviar, t.unidad))})`}
																			</label>
																			<div className="mt-2">
																				<input
																					type="number"
																					name="cantidad_enviar"
																					id="cantidad_enviar"
																					placeholder="¿Cuánto desea recibir?"
																					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																					value={t.cantidad_enviar}
																					onChange={(ev) => { F.input__cambiarCantidadEnviar(state, setState, _this, e, ev.target.value) }} />
																			</div>
																		</div>

																		{
																			parseFloat(t.cantidad_enviar) > 0 &&
																			<div className="sm:col-span-6">
																				<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																					Fecha en que solicita el envío o se lleva el producto
																				</label>
																				<div className="mt-2">
																					<input
																						type="datetime-local"
																						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																						value={t.fecha_solicitada}
																						onChange={(ev) => { F.input__cambiarFechaSolicitadaEnviar(state, setState, _this, e, ev.target.value) }} />
																				</div>
																			</div>
																		}

																		{
																			(parseFloat(t.cantidad_solicitada) - parseFloat(t.cantidad_enviar)) > 0 &&
																			<div className="sm:col-span-6">
																				<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																					Se apartarán en almacén
																				</label>
																				<div className="mt-2">
																					<p style={{ color: '#E64E44', }}>{F.calculadorToneladas(F.convertirAKilos(t.cantidad_solicitada - t.cantidad_enviar, t.unidad))}</p>
																				</div>
																			</div>
																		}

																		<div className="sm:col-span-3">
																			<label htmlFor="precio_venta_usuario" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																				Costo unitario de producto
																			</label>
																			<div className="mt-2">
																				<input
																					type="number"
																					name="precio_venta_usuario"
																					id="precio_venta_usuario"
																					placeholder="Ingresa el costo de producto"
																					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																					value={t.precio_venta_usuario}
																					disabled={nuevaVenta.fPago === 'otro' ? false : true}
																					onChange={(ev) => { F.input__cambiarCostoUnitario(state, setState, _this, e, ev.target.value) }} />
																			</div>
																		</div>

																		<div className="sm:col-span-3">
																			<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																				Total
																			</label>
																			<div className="mt-2 dark:text-gray-200">
																				{F.calculadorPagoProducto(t.cantidad_solicitada, t.precio_venta_usuario)}
																			</div>
																		</div>

																	</div>
																</form>
															</div>
														</div>
													</li>
												))
											}

										</ul>
									</section>
								</form>

								<h1 className="dark:text-slate-200">¿Cómo recibirá esta compra?</h1>

								<div className="flex flex-row justify-between items-center my-5">
									<button
										onClick={() => { F.comoRecibiraCompra(state, setState, _this, 'recoge') }}
										type="button"
										className={`rounded-md bg-sky-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 ${nuevaVenta.fRecibe === 'recoge' && 'bg-red-600'}`}
									>
										Lo recoge en la sucursal
									</button>
									<button
										onClick={() => { F.comoRecibiraCompra(state, setState, _this, 'entrega') }}
										type="button"
										className={`rounded-md bg-sky-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 ${nuevaVenta.fRecibe === 'entrega' && 'bg-red-600'}`}
									>
										Entrega local
									</button>
									<button
										onClick={() => { F.comoRecibiraCompra(state, setState, _this, 'planta') }}
										type="button"
										className={`rounded-md bg-sky-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 ${nuevaVenta.fRecibe === 'planta' && 'bg-red-600'}`}
									>
										Directo de planta
									</button>
								</div>

								{
									(nuevaVenta.fRecibe === 'entrega' || nuevaVenta.fRecibe === 'planta')
									&& (
										<div>
											<hr />

											<div className="sm:col-span-3 mt-5">
												<label htmlFor="ubicacion_entrega" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
													Ubicación de entrega
												</label>
												<div className="mt-2">

													<div>
														<button
															onClick={() => {
																{
																	F.handleAccionarSlider(state, setState, _this, "slideDireccionesEntrega", true)
																	setState({
																		view: 'formulario'
																	})

																}
															}}
															type="button"
															className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
														>
															Agregar ubicación de entrega
														</button>
													</div>

													<select
														onChange={(ev) => F.select__cambiaUbicacionEntrega(state, setState, _this, ev.target.value)}
														id="ubicacion_entrega"
														value={nuevaVenta.ubicacionEntrega}
														name="ubicacion_entrega"
														className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
													>
														<option value={'por_definir'}>Por definir</option>
														{
															nuevaVenta.arrUbicaciones.map(ubicacion =>
																<option value={ubicacion.id_direccion}>{ubicacion.calle}, {ubicacion.no_exterior}, {ubicacion.no_interior}, {ubicacion.colonia}, {ubicacion.cp}, {ubicacion.municipio}, {ubicacion.estado}</option>
															)
														}
													</select>
												</div>
											</div>

											<br />

										</div>
									)
								}

								{
									F.carritoCompletado(state, setState, _this) &&
									<div>
										<hr />
										<button
											onClick={() => F.continuarPasoFinalCompra(state, setState, _this)}
											type="button"
											className="my-6 inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										>
											Continuar
											<ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								}



							</div>
				}
			</>
		)
	}
}

export class ContinuarCarrito extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { state, setState, _this, } = this.props
		const { arrProductos } = state
		let puedeIrACarrito = false

		arrProductos.map((t) => {
			if (t.selected)
				puedeIrACarrito = true
		})
		if (!puedeIrACarrito)
			return null

		return (
			<button
				onClick={() => F.continuarAlCarrito(state, setState, _this)}
				type="button"
				className="fixed right-2.5 bottom-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
			>
				Continuar
				<ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
			</button>
		)
	}
}

export class CobranzaCarrito extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { state, setState, _this, } = this.props

		const { nuevaVenta } = state
		if (!nuevaVenta.ficha)
			return null

		const { arrCarrito, arrRecibosCobrados, credito, deuda, } = nuevaVenta
		let totalPagar = 0
		let totalCobrado = 0

		arrCarrito.map((prod) => {
			totalPagar += (parseFloat(prod.cantidad_solicitada) * parseFloat(prod.precio_venta_usuario))
		})

		arrRecibosCobrados.map(recibos => {
			totalCobrado += parseFloat(recibos.cantidad)
		})

		const residualACredito = totalPagar - totalCobrado,
			cantidadNuevoCreditoRestante = (parseFloat(credito) - parseFloat(deuda)) - residualACredito

		return (
			<>
				<div className="border-b border-gray-200 pb-5 pt-8">
					<h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">Registrar cobranza</h3>
					<p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">
						Para concluir esta operación por favor indica si tu cliente estará realizando algunos pagos.
					</p>
				</div>

				{
					typeof arrCarrito !== 'object'
						? null
						: arrCarrito.length === 0 ?
							<div>
								<p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">Te haz quedado sin productos en el carrito, <b className="cursor-pointer" onClick={() => F.regresarAElegirProductos(state, setState, _this)}>pulsa aquí</b> para regresar y elegir nuevos productos.</p>
							</div>
							:
							<div className="bg-white my-5 py-5 px-5 rounded-md shadow-md dark:bg-slate-800">
								<h1 className="pb-5 font-bold dark:text-gray-300">Total por cobrar: {F.formateaTotalCarrito(totalPagar)}</h1>
								<hr />
								{
									totalCobrado > 0 &&
									<div>
										<h4 className="py-3 dark:text-gray-300">Cantidad abonada: {F.formateaTotalCarrito(totalCobrado)}</h4>
										<h4 className="pb-3 dark:text-gray-300">Cantidad residual a crédito: {F.formateaTotalCarrito(residualACredito)}</h4>
										{
											cantidadNuevoCreditoRestante < 0 && <h4 className="pb-3 dark:text-red-700 font-bold text-lg">
												Esta venta supera el límite de crédito. Solo dispones de: ${F.number_format(parseFloat(credito) - parseFloat(deuda), 2)}.<br />
												Administración deberá autorizar esta venta que supera el crédito total
											</h4>
										}
									</div>
								}

								{
									typeof arrRecibosCobrados !== 'object'
										? null
										: arrRecibosCobrados.length === 0 ? null
											:
											<div>

												<div>
													<hr />
													<div className="py-5">
														<p className="dark:text-gray-300">A continuación se muestran las formas del pago que el cliente confirma</p>
													</div>
												</div>

												{
													arrRecibosCobrados.map((t, e) => (
														<div>
															<div>

																<div className="py-2">
																	<label htmlFor="forma_pago" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Forma de pago
																	</label>
																	<select
																		id="forma_pago"
																		name="forma_pago"
																		className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																		value={t.fPago}
																		onChange={(ev) => F.select__cambiaFPagoCobro(state, setState, _this, e, ev.target.value)}
																	>
																		<option selected={true} disabled={true}>Elige una forma de pago</option>
																		<option value={'efectivo'}>Efectivo</option>
																		<option value={'t_credito'}>Tarjeta de crédito</option>
																		<option value={'t_debito'}>Tarjeta de débito</option>
																		<option value={'SPEI'}>Transferencia electrónica</option>
																		<option value={'deposito'}>Depósito bancario</option>
																		<option value={'sie'}>Convenio SIE</option>
																	</select>
																</div>

																<div className="py-2">
																	<label htmlFor="cantidad" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Cantidad
																	</label>
																	<div className="mt-2">
																		<input
																			value={t.cantidad}
																			onChange={(ev) => F.select__cambiaCantidadRecibida(state, setState, _this, e, ev.target.value)}
																			type="number"
																			name="cantidad"
																			id="cantidad"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa la cantidad recibida"
																		/>
																		{
																			parseFloat(t.cantidad) > 0 &&
																			<b className="dark:text-gray-300" style={{ fontSize: 10, }}>({F.formateaTotalCarrito(parseFloat(t.cantidad))})</b>
																		}
																	</div>
																</div>

																<div>
																	<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
																		Observaciones
																	</label>
																	<div className="mt-2">
																		<textarea
																			value={t.observacion}
																			onChange={(ev) => F.select__cambiaObservacion(state, setState, _this, e, ev.target.value)}
																			rows={4}
																			name="comment"
																			id="comment"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
																			placeholder="Ingresa una observación (opcional)"
																		/>
																	</div>
																</div>

															</div>

															<div>
																<div>

																	<button
																		onClick={
																			() => {

																				nuevaVenta.arrRecibosCobrados.splice(e, 1);
																				this.setState({
																					nuevaVenta,
																				})
																			}
																		}
																		type="button"
																		className="my-4 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																	>
																		Eliminar
																	</button>
																</div>

															</div>

														</div>
													))
												}

											</div>
								}

								{
									nuevaVenta.fPago === 'otro'
										? <div
											className="cursor-default mt-5 mb-5 bg-red-300 rounded-md p-3">
											<p className="underline-offset-2 text-2xl text-center">No puedes registrar abonos a esta orden de venta hasta que el administrador la autorice.</p>
										</div>
										:
										<div
											className="cursor-pointer mt-5 mb-5 bg-red-300 rounded-md p-3"
											onClick={() => F.agregarNuevoReciboDeCobro(state, setState, _this)} >
											{
												arrRecibosCobrados.length === 0
													? <p className="underline-offset-2">Pulsa aquí si deseas registrar un recibo de cobro.</p>
													: <p className="underline-offset-2">Pulsa aquí si deseas agregar otro recibo de cobro.</p>
											}
										</div>
								}

								<hr />

								<div className="py-5">
									<button
										onClick={() => F.registrarVenta(state, setState, _this)}
										type="button"
										className="inline-flex items-center gap-x-1.5 rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Registrar venta
										<CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
									</button>
								</div>


							</div>
				}
			</>
		)
	}
}

export class TablaSeccion extends React.Component {
	render() {
		const { state, setState, _this, arrClientes } = this.props;

		return (
			<></>
		)
	}
}

export class TablaClientes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const { state, setState, _this, } = this.props;
		const { arrClientes } = state;

		return (
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold leading-6 text-gray-900">Clientes</h1>
						<p className="mt-2 text-lg text-gray-700 dark:text-white">
							Lista detallada de los clientes
						</p>
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<button
							type="button"
							className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
						>
							Agregar cliente
						</button>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Nombre del cliente
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Total a pagar
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Total abonado
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												KG Totales
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{arrClientes.map((cliente) => {
											let semaforo = ''
											let valor = F.number_format((parseFloat(cliente.cantidad) / parseFloat(cliente.total_pagar)), 2) * 100
											valor = Number.isNaN(valor) ? 0 : valor

											if (valor < 35)
												semaforo = 'red'
											else if (valor >= 35 && valor < 60)
												semaforo = 'yellow'
											else
												semaforo = 'green'

											const textClass = `text-${semaforo}-500`,
												bg50Class = `bg-${semaforo}-50`,
												bg500Class = `bg-${semaforo}-500`

											return (
												<tr key={cliente.id_orden}>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
														{cliente.nombre_cliente} {cliente.a_paterno} {cliente.a_materno}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														${F.number_format(cliente.total_pagar, 2)}

														<div className="pt-1 border-b border-blue-50 dark:border-slate-900" >
															<div className="flex -mx-2">
																<div className="px-1 w-full">
																	<p className={`mb-1 text-xs ${textClass} font-medium`}> {F.number_format(valor, 0)} %</p>
																	<div className="flex">
																		<div className={`relative h-1 w-full ${bg50Class} rounded-full`}>
																			<div className={`absolute top-0 left-0 h-full ${bg500Class} rounded-full`} style={{ width: valor + '%' }}></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														${F.number_format(cliente.cantidad, 2)}
														<p>Adeuda: ${F.number_format(cliente.resta, 2)}</p>
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{F.calculadorToneladas(cliente.cantidad_conversion)}</td>
													<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<NavLink
															type="button"
															to={`/pos/fichaCliente/${cliente.id_cliente}`}
															className="text-indigo-600 hover:text-indigo-900"
														>
															Ver ficha
														</NavLink>
													</td>
												</tr>
											)
										})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export class TablaVentas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const { state, setState, _this, } = this.props;
		const { arrVentas, fechaInicioVentas } = state;

		return (
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold leading-6 text-gray-900">Ventas</h1>
						<p className="mt-2 text-sm text-gray-700 dark:text-gray-50">
							Lista detallada de las ventas.
						</p>
					</div>
					<input type="date" value={fechaInicioVentas} onChange={(ev) => setState({ fechaInicioVentas: ev.target.value })} />
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<button
							onClick={() => F.obtenerListaVentas(state, setState, _this)}
							type="button"
							className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
						>
							Generar
						</button>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr>
											<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Nombre del cliente
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Forma de pago
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Total a pagar
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Abonado
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												Pagos cotejados
											</th>
											<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
												KG Totales
											</th>
											<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Fecha de venta
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Opciones</span>
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{
											arrVentas.map((venta) => {
												let semaforo = ''
												let valor = F.number_format((parseFloat(venta.sum_total_cantidad) / parseFloat(venta.sum_total_pagar)), 2) * 100
												valor = Number.isNaN(valor) ? 0 : valor

												if (valor < 35)
													semaforo = 'red'
												else if (valor >= 35 && valor < 60)
													semaforo = 'yellow'
												else
													semaforo = 'green'

												const textClass = `text-${semaforo}-500`,
													bg50Class = `bg-${semaforo}-50`,
													bg500Class = `bg-${semaforo}-500`

												return (
													<tr key={venta.id_orden}>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
															{venta.nombre_completo}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{F.textoFormaDePago(venta.forma_pago)}</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															<p>${F.number_format(venta.sum_total_pagar, 2)}</p>
															<div className="pt-1 border-b border-blue-50 dark:border-slate-900" >
																<div className="flex -mx-2">
																	<div className="px-1 w-full">
																		<p className={`mb-1 text-xs ${textClass} font-medium`}> {F.number_format(valor, 2)} %</p>
																		<div className="flex">
																			<div className={`relative h-1 w-full ${bg50Class} rounded-full`}>
																				<div className={`absolute top-0 left-0 h-full ${bg500Class} rounded-full`} style={{ width: valor + '%' }}></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															{
																F.number_format(parseFloat(venta.sum_total_pagar) - parseFloat(venta.sum_total_cantidad), 0) > 0
																	? <>
																		<p>${F.number_format(venta.sum_total_cantidad)}</p>
																		<p className="text-xs text-red-500">Resta: <b>${F.number_format(parseFloat(venta.sum_total_pagar) - parseFloat(venta.sum_total_cantidad), 2)}</b></p>
																	</>
																	: <p className="text-xs text-green-800"><b>Liquidado</b></p>
															}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
															{venta.count_cotejado} de {venta.count_pagos}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{F.calculadorToneladas(venta.sum_total_kg_conversion)}</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(venta.created_at).format("DD/MM/YYYY HH:mm")}</td>
														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => { F.obtenerHistorialOrden(state, setState, _this, venta) }}>
																Ver más
															</a>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export class Notificacion extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		const { state, setState, _this, } = this.props
		const { notificacion, } = state

		return (
			<div
				ariaLive="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={notificacion.titulo ? true : false}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										{notificacion.icono}
									</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-medium text-gray-900">{notificacion.titulo}</p>
										<p className="mt-1 text-sm text-gray-500">{notificacion.descripcion}</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={() => {
												setState({ notificacion: {} })
											}}
										>
											<span className="sr-only">Cerrar</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		)
	}
}