import React from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Login from "./Login/login.render";
import Logout from "./Login/logout.render";
import Loading from "./Loading";
import LoadingProcess from "./LoadingProcess";
import Error404 from "./Error404";
import * as Pos from "./PoS/pos.clases";
import * as Sup from "./Supervisor/sup.clases";
import * as Cont from "./Contabilidad/cont.clases";
import * as Admin from "./Administrador/admin.clases";
import * as Clientes from "./Clientes/clientes.clases";
import Admin1 from "./Administrador/pos.admin.render";
import { StateAdministrador, StateSupervisor, StatePos, StateContabilidad, StateClientes, } from "./States";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: false, }
  }

  componentDidMount() {
    let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false)

    const routeNavigator = window.location.pathname
    if (routeNavigator.indexOf('/clientes/') > -1) {
      const stateToUse = StateClientes
      this.setState({ ...stateToUse, isLoading: true })

    } else {

      if (auth) {
        auth = JSON.parse(localStorage.ws_santori_auth).type
        const stateToUse = parseInt(auth) === 2 ? StatePos : parseInt(auth) === 1 ? StateAdministrador : parseInt(auth) === 3 ? StateSupervisor : parseInt(auth) === 4 ? StateContabilidad : {}
        this.setState({ ...stateToUse, isLoading: true })
      } else {

        const routeNavigator = window.location.pathname
        if (routeNavigator.indexOf('/clientes/') > -1) {
          const stateToUse = StateClientes
          this.setState({ ...stateToUse, isLoading: true })

        } else {
          if (window.location.pathname !== '/login')
            window.location = '/login'
        }

        this.setState({ isLoading: true })
      }
    }
  }

  render() {
    const { isLoading } = this.state;

    if (!isLoading)
      return null

    if (this.state.showNotificacion) {
      setTimeout(() => {
        this.setState({
          showNotificacion: false
        })
      }, 3500)
    }

    return (

      <>
        <div className={'w-full h-full dark:bg-slate-800'}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Loading initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/procesando" element={<LoadingProcess initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/login" element={<Login initState={this.state} initsetState={(param) => this.setState(param)} />} />

              {/* POS ROUTES */}
              <Route path="/pos" element={<Pos.Route_Pos initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/buscarCliente" element={<Pos.Route_PosBuscarCliente initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/fichaCliente/:id_cliente" element={<Pos.Route_PosFichaCliente initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/carrito/:id_cliente" element={<Pos.Route_PosCarrito initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/carrito/cantidades/:id_cliente" element={<Pos.Route_PosCarritoCantidades initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/carrito/cobranza/:id_cliente" element={<Pos.Route_PosCarritoCobranza initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/clientes" element={<Pos.Route_PosClientes initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/pos/ventas" element={<Pos.Route_PosVentas initState={this.state} initsetState={(param) => this.setState(param)} />} />

              {/* ADMIN ROUTES */}
              <Route path="/admin" element={<Admin.Route_Admin initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/admin/porAutorizar" element={<Admin.Route_AdminPorAutorizar initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/admin/inventarios" element={<Admin.Route_AdminInventarios initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/admin/creditos" element={<Admin.Route_AdminCreditos initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/admin/configuraciones" element={<Admin.Route_AdminConfiguraciones initState={this.state} initsetState={(param) => this.setState(param)} />} />

              <Route path="/ordenes/:typeAuth/:id_orden" element={<Admin.Route_AdminOrdenAutorizaciones initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/ordenes/:typeAuth/:id_orden" element={<Admin.Route_AdminOrdenAutorizaciones initState={this.state} initsetState={(param) => this.setState(param)} />} />


              {/* SUPERVISOR ROUTES */}
              <Route path="/supervisor" element={<Sup.Route_Sup initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/supervisor/venta" element={<Sup.Route_SupVentaDia initState={this.state} initsetState={(param) => this.setState(param)} />} />

              {/* CONTABILIDAD ROUTES */}
              <Route path="/contabilidad" element={<Cont.Route_Cont initState={this.state} initsetState={(param) => this.setState(param)} />} />

              <Route path="/logout" element={<Logout initState={this.state} initsetState={(param) => this.setState(param)} />} />

              <Route path="/clientes/:id_cliente" element={<Clientes.Route initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/clientes/:id_cliente/firmar" element={<Clientes.Route initState={this.state} initsetState={(param) => this.setState(param)} />} />
              <Route path="/clientes/:id_cliente/enProceso" element={<Clientes.Route initState={this.state} initsetState={(param) => this.setState(param)} />} />

              <Route path="*" element={<Error404 />} />
            </Routes>
          </BrowserRouter>

        </div>

      </>
    )
  }

}

const withNavigation = (Component) => {
  return props => <Component {...props} />;
}

export default withNavigation(App)