import * as C from './pos.components';

export const render__homePos = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      {/* Aquí van ir los microcomponentes de la vista */}
      <C.StatsWidgets state={state} setState={setState} _this={_this} />
      <C.Meta state={state} setState={setState} _this={_this} />
      <C.ActionsPanel state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__buscarCliente = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.Wizard state={state} setState={setState} _this={_this} pasoActual={1} />
      <C.CardHeading state={state} setState={setState} _this={_this} pasoActual={1} />
      <C.SlideOverCliente state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__fichaCliente = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.Wizard state={state} setState={setState} _this={_this} pasoActual={2} />
      <C.OverviewProfile state={state} setState={setState} _this={_this} />
      <C.ClientInformation state={state} setState={setState} _this={_this} />
      <C.SlideOverAumentarCredito state={state} setState={setState} _this={_this} />
      <C.SlideOverCotizacionesPendientes state={state} setState={setState} _this={_this} />
      <C.SlideOverUltimasVentas state={state} setState={setState} _this={_this} />
      <C.SlideOverDireccionesEntrega state={state} setState={setState} _this={_this} />
      <C.SlideOverDireccionesFiscales state={state} setState={setState} _this={_this} />
      <C.BotonContinuar state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__carrito = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.Wizard state={state} setState={setState} _this={_this} pasoActual={3} />
      <C.ListaProductos state={state} setState={setState} _this={_this} />
      <C.ContinuarCarrito state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__carritoCantidades = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.Wizard state={state} setState={setState} _this={_this} pasoActual={4} />
      <C.CantidadesProductos state={state} setState={setState} _this={_this} />

      <C.SlideOverDireccionesEntrega state={state} setState={setState} _this={_this} closeForm={true} />
      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__carritoCobranza = (state, setState, _this) => {

  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.Wizard state={state} setState={setState} _this={_this} pasoActual={4} />
      <C.CobranzaCarrito state={state} setState={setState} _this={_this} />

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )

}

export const render__clientes = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.TablaClientes state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}

export const render__ventas = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-4 sm:px-6 lg:px-8'} paddingMain={'py-10'} _this={_this}>
      <C.TablaVentas state={state} setState={setState} _this={_this} />
      <C.SlideOverUltimasVentas state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}