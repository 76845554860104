import { HTTP_POST, HTTP_UPLOAD, } from "../http";
import moment from 'moment'
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

export const mostrarNotificacion = (state, setState, _this, notificacion) => {
    setState({ notificacion })
    setTimeout(() => {
        setState({
            notificacion: {},
        })
    }, notificacion.timeout)
}

export const textoFormaDePago = (forma_pago) => {
    if (forma_pago === 'contado')
        return 'Pago de contado'
    else if (forma_pago === '15')
        return 'Crédito a 15 días'
    if (forma_pago === '30')
        return 'Crédito a 30 días'
    if (forma_pago === 'otro')
        return 'A convenir del vendedor'
    else
        return ''

}

export const textFormaPagoCobranza = (forma_pago) => {
    if (forma_pago === 'efectivo')
        return 'Efectivo'
    else if (forma_pago === 't_credito')
        return 'T. Crédito'
    else if (forma_pago === 't_debito')
        return 'T. Débito'
    else if (forma_pago === 'SPEI')
        return 'Transferencia E.'
    else if (forma_pago === 'deposito')
        return 'Depósito'
    else if (forma_pago === 'sie')
        return 'Convenio SIE'
    else
        return ''

}

export const number_format = (str, long) => {
    if (str == null) str = 0;
    return parseFloat(str)
        .toFixed(typeof long === 'undefined' ? 2 : long)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const calculadorToneladas = (cantidad) => {
    return `${(cantidad < 1000) ? (parseFloat(cantidad).toFixed(2) + ' KG') : ((cantidad / 1000).toFixed(2) + ' TON')}`
}

export const initData = (state, setState, _this) => {
    const url = `supervisor/obtenerMiInformacionDeUsuario`
    const data = {}
    HTTP_POST(url, data, response => {
        if (response === 'error')
            return false

        let { arrProductos, data, ordenes_pendientes, por_cobrar, tendencia, toneladas_al_dia, toneladas_autorizadas, toneladas_no_autorizadas, facturas, meta_ton_mes, meta_ton_dia, arrVendedores, } = response

        setState({
            internet: true,
            arrProductos,
            session: data,
            ordenes_pendientes,
            por_cobrar,
            tendencia,
            toneladas_al_dia,
            toneladas_autorizadas,
            toneladas_no_autorizadas,
            facturas,
            arrVendedores,
            arrMisMetas: [
                {
                    nombre: 'Meta del día (TON)',
                    valor: (toneladas_al_dia / meta_ton_dia) * 100,
                },
                {
                    nombre: 'Meta del mes (TON)',
                    valor: (toneladas_autorizadas / meta_ton_mes) * 100,
                }
            ]
        })
    })
}

export const obtenerVendedoresPorSupervisor = (_this) => {
    const url = `supervisor/obtenerVendedoresPorSupervisor`
    const data = {}
    HTTP_POST(url, data, response => {
        if (response === 'error')
            return false
        _this.props.initsetState({
            arrVendedores: response.data
        })
    })
}

export const getOrdenesDeVenta = (state, setState, _this) => {
    const { id_vendedor, fecha_pagos, } = state;
    const url = `supervisor/obtenerOrdenesDelVendedor`
    const data = { created_at: fecha_pagos }
    HTTP_POST(url, data, response => {
        if (response === 'error') {
            return false;
        }
        setState({
            arrOrdenesVenta: response.data
        })
    })
}

export const actualizarVentaCotejada = (state, setState, _this, id_orden_pago) => {
    if (!state.input_comprobante.file) {
        alert('Es necesario subir un comprobante para cotejar el pago.')
        return false
    }

    let formData = new FormData()
    const form = state.input_comprobante

    if (typeof form.file !== 'undefined') {
        formData.append(form.value, form.file)
    }

    console.log(form, [...formData])

    if ([...formData].length > 0) {
        const url0 = `supervisor/actualizarTipoVentaCotejada`
        const data0 = formData
        const { arrOrdenesVenta } = state;
        HTTP_UPLOAD(url0, data0, response => {
            if (response === 'error') {
                return false;
            }

            arrOrdenesVenta.filter(venta => {
                if (venta.id_orden_pago === id_orden_pago) {
                    venta.venta_cotejada = 1
                }
            })


            setState({
                alertConfirmarComprobante: false,
                arrOrdenesVenta,
                input_comprobante: {},
            })

            mostrarNotificacion(
                state, setState, _this,
                {
                    icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
                    titulo: 'Aviso',
                    descripcion: '¡Venta cotejada con éxito!',
                    timeout: 5000
                }
            )
        })
    }

    return false
    const url = `supervisor/actualizarTipoVentaCotejada`;
    const data = { id_orden_pago, status: 1 }

    HTTP_POST(url, data, response => {

    })
}

export const eliminaOrdenDePagoPorTipo = (state, setState, _this, orden) => {
    const url = `supervisor/eliminaOrdenDePagoPorTipo`;
    const data = { id_orden_pago: orden.id_orden_pago, type: orden.type, }

    HTTP_POST(url, data, response => {
        if (response === 'error') {
            return false;
        }

        getOrdenesDeVenta(state, setState, _this)
        setState({
            pre_orden_pago: {},
            alertConfirmarOrden: false,
        })

        mostrarNotificacion(
            state, setState, _this,
            {
                icono: <CheckCircleIcon className="h-6 w-6 text-red-800" aria-hidden="true" />,
                titulo: 'Aviso',
                descripcion: orden.type == 1 ? 'Pago eliminado con éxito, se notificó al administrador' : 'Venta eliminada con éxito. Se notificó al administrador',
                timeout: 5000
            }
        )
    })
}


