import React, { } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";


class Error404 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { navigate } = this.props.router

    return (
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-black">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600 dark:text-white">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-white">La aplicación no encontró tu solicitud</h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-white">Lo sentimos, no pudimos comprender tu solicitud. Creemos que estás intentando realizar una acción no permitida, por favor regresa al inicio.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <p
              className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => { navigate('/') }}
            >
              Regresar al inicio
            </p>
          </div>
        </div>
      </main>
    )

  }

}


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(Error404)