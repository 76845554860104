import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import moment from 'moment'
import * as F from './sup.functions';
import * as view from './sup.views';

moment.locale('es');

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class _SupIndex extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		F.initData(this.props.initState, this.props.initsetState, this)
	}

	render() {
		const { navigate } = this.props.router
		return (
			<div>
				{view.render__homeSup(this.props.initState, this.props.initsetState, this)}
			</div>
		);
	}
}

class _SupVentaDia extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		F.obtenerVendedoresPorSupervisor(this)
	}

	render() {
		const { navigate } = this.props.router
		return (
			<div>
				{view.render__ventaDiaSup(this.props.initState, this.props.initsetState, this)}
			</div>
		);
	}
}

const Route_Sup = withRouter(_SupIndex)
const Route_SupVentaDia = withRouter(_SupVentaDia)

export {
	Route_Sup,
	Route_SupVentaDia
}