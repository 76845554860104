import React, { Fragment } from "react";
import * as F from './admin.functions';
import { Disclosure, Dialog, Menu, Transition } from '@headlessui/react'
import moment from 'moment'
import {
  Bars3Icon,
  BellIcon,
  UsersIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  ChartBarIcon,
  CubeIcon,
  HomeModernIcon,
} from '@heroicons/react/24/outline'

const userNavigation = [
  { name: 'Perfil', href: '#' },
  { name: 'Cerrar sesión', href: '/logout' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    const { navigationIndex } = this.props

    const tabHeaderNavigation = [
      { name: 'Progreso', href: '/admin', current: false },
      { name: 'Por autorizar', href: '/admin/porAutorizar', current: false },
      { name: 'Inventarios', href: '/admin/inventarios', current: false },
      { name: 'Créditos', href: '/admin/creditos', current: false },
      { name: 'Configuraciones', href: '/admin/configuraciones', current: false }
    ]
    tabHeaderNavigation[navigationIndex - 1].current = true
    this.state = {
      session: {},
      sidebarOpen: false,
      tabHeaderNavigation,
    }
  }

  componentDidMount() {
    let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);
    const { _this } = this.props;
    if (auth) {
      let session = JSON.parse(localStorage.ws_santori_auth)
      this.setState({
        session
      }, () => {
        const { tabHeaderNavigation } = this.state;
        let newNav = tabHeaderNavigation.map(item => ({
          ...item,
        }));
        this.setState({ tabHeaderNavigation: newNav })
      })
    }
  }

  setSideBarOpen = (status) => this.setState({ sidebarOpen: status })

  render() {
    const { tabHeaderNavigation } = this.state;
    const { children } = this.props
    const { session } = this.state;

    if (!session.nombre)
      return null

    return (
      <>
        <div className={'dark:bg-slate-900'}>
          <Disclosure as="nav" className="border-b border-gray-200 bg-white">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          className="block h-8 w-auto lg:hidden"
                          src="https://app.santori.com.mx/img/logo-santori.png"
                          alt="Santori SA De CV"
                        />
                        <img
                          className="hidden h-8 w-auto lg:block"
                          src="https://app.santori.com.mx/img/logo-santori.png"
                          alt="Santori SA De CV"
                        />
                      </div>
                      <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        {tabHeaderNavigation.map((item) => {
                          return (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'border-indigo-500 text-gray-900'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          )
                        })}
                      </div>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                      <button
                        type="button"
                        className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src="https://acyn.wsoft.com.mx/agentes/img/profile.png" alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>

                            ))}
                            <Menu.Item>
                              <a
                                href={'#'}
                                className={classNames(
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Admin
                              </a>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="-mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 pb-3 pt-2">
                    {tabHeaderNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                            : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                          'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pb-3 pt-4">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={"https://acyn.wsoft.com.mx/agentes/img/profile.png"} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">{session.nombre}</div>
                        <div className="text-sm font-medium text-gray-500">{session.correo}</div>
                      </div>
                      <button
                        type="button"
                        className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <div className="">
            <main className={this.props.paddingMain}>
              <div className={this.props.padding}>
                {children}
              </div>
            </main>
          </div>
        </div>
      </>
    )
  }
}

export class Saludo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      session: {},
    }
  }

  componentDidMount() {
    let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);

    if (auth) {
      let session = JSON.parse(localStorage.ws_santori_auth)
      this.setState({
        session
      })
    }

  }

  render() {
    const { session } = this.state;
    let hourOfDay = moment().format('H')

    let bienvenida = ''
    if (hourOfDay < 12)
      bienvenida = 'Buenos días'
    else if (hourOfDay >= 12 && hourOfDay < 19)
      bienvenida = 'Buenas tardes'
    else if (hourOfDay >= 19 && hourOfDay <= 23)
      bienvenida = 'Buenas noches'

    return (
      <div className="mb-3">
        <h1 className="text-lg font-semibold dark:text-gray-300">{bienvenida}, {session.nombre} {session.apellidos}</h1>
        <hr className="mt-4 mb-4" />
      </div>
    )
  }
}

export class ProgresoCorporativo extends React.Component {

  render() {
    const { state, setState, _this, } = this.props

    const { arrProgresoCorporativo } = this.props.state

    const listProgresoCorporativo = [
      {
        concepto: <span>TON vendidas</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6">{F.calculadorToneladas(arrProgresoCorporativo.toneladas_autorizadas_dia)}</div>
            <div class="relative h-4 w-full rounded bg-meta-9 dark:bg-meta-4 bg-gray-100 mt-2">
              <div class={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-500`} style={{ width: `${arrProgresoCorporativo.progreso_toneladas_dia > 100 ? 100 : arrProgresoCorporativo.progreso_toneladas_dia}%` }}>
                {arrProgresoCorporativo.progreso_toneladas_dia}%
              </div>
            </div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6">{F.calculadorToneladas(arrProgresoCorporativo.toneladas_autorizadas_mes)}</div>
            <div class="relative h-4 w-full rounded bg-meta-9 dark:bg-meta-4 bg-gray-100 mt-2">
              <div class={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-500`} style={{ width: `${arrProgresoCorporativo.progreso_toneladas_mes > 100 ? 100 : arrProgresoCorporativo.progreso_toneladas_mes}%` }}>
                {arrProgresoCorporativo.progreso_toneladas_mes}%
              </div>
            </div>
          </div>
        ),
      },
      /*
      {
        concepto: <span>Tendencia ventas</span>,
        dia: (
          <div>
            <div class="relative h-4 w-full rounded bg-meta-9 dark:bg-meta-4 bg-gray-100 mt-2">
              <div class={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-500`} style={{ width: '23%' }}>
                23%
              </div>
            </div>
          </div>
        ),
        mes: (
          <div>
            <div class="relative h-4 w-full rounded bg-meta-9 dark:bg-meta-4 bg-gray-100 mt-2">
              <div class={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-500`} style={{ width: '67%' }}>
                67%
              </div>
            </div>
          </div>
        ),
      },
      */
      {
        concepto: <span>Cobranza</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.sum_pagos_dia, 2)}</div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.sum_pagos_mes, 2)}</div>
          </div>
        ),
      },
      {
        concepto: <span>Gastos fijos</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-xs">${F.number_format(arrProgresoCorporativo.gastos_dia, 2)}</div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.gastos_mes, 2)}</div>
          </div>
        ),
      },
      {
        concepto: <span>Utilidad bruta *</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-xs">${F.number_format(arrProgresoCorporativo.utilidad_bruta_dia, 2)}</div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.utilidad_bruta_mes, 2)}</div>
          </div>
        ),
      },
      {
        concepto: <span>Utilidad neta *</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-xs">${F.number_format(arrProgresoCorporativo.utilidad_neta_dia, 2)}</div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.utilidad_neta_mes, 2)}</div>
          </div>
        ),
      },
      {
        concepto: <span>Utilidad neta - gastos op *</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-xs">${F.number_format(arrProgresoCorporativo.utilidad_menos_gastos_dia, 2)}</div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6 text-sm">${F.number_format(arrProgresoCorporativo.utilidad_menos_gastos_mes, 2)}</div>
          </div>
        ),
      },
      {
        concepto: <span>Por facturar</span>,
        dia: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6"></div>
          </div>
        ),
        mes: (
          <div>
            <div className="text-gray-900 dark:text-gray-300 text-center px-6">{F.number_format(arrProgresoCorporativo.count_facturadas_mes, 0)} de {F.number_format(arrProgresoCorporativo.count_por_facturar_mes, 0)}</div>
          </div>
        ),
      },

    ]

    return (

      <section className="py-1">
        <div>
          <p className="pb-4 font-medium dark:text-gray-300">Progreso corporativo</p>
          <input type={'date'} value={state.fecha_progreso} onChange={(ev) => {
            setState({ fecha_progreso: ev.target.value });
            F.initData(state, setState, _this, ev.target.value)
          }} />
          <div className="bg-white px-2 rounded dark:bg-slate-800 shadow-[0px_3px_5px_#0000000b] ">
            <table className="min-w-full divide-y divide-gray-100">
              <thead>
                <tr>
                  <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50 sm:pl-0">
                    Concepto
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm text-center font-semibold text-gray-900 dark:text-gray-50 pl-5">
                    Hoy {moment().format('DD')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm text-center font-semibold text-gray-900 dark:text-gray-50 pl-5">
                    Mes
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-800">
                {listProgresoCorporativo.map(t => this.makeTRProgresoCorporativo(t))}
              </tbody>
            </table>

          </div>

        </div>
      </section >
    )
  }

  makeTRProgresoCorporativo(item) {
    return (
      <tr>
        <td className="pb-5 pl-2 sm:pl-0 p-0 m-0">
          <div className="font-medium text-gray-900 dark:text-gray-300 text-xs p-0 m-0">{item.concepto}</div>
        </td>
        <td className="pb-5 px-1 py-2 text-xs">
          {item.dia}
        </td>
        <td className="pb-5 px-1 py-2 text-xs">
          {item.mes}
        </td>
      </tr>
    )
  }

}

export class MisPendientes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    const { arrProgresoCorporativo } = this.props.state

    return (
      <>
        <p className="font-medium dark:text-gray-300">Mis pendientes</p>
        <div class="grid grid-cols-6 gap-6 mt-3 mb-5">
          <div class="col-span-3 sm:col-span-3 xl:col-span-3 intro-y">
            <div
              class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

              <div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
                <div class="flex">
                  <ChartBarIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
                </div>
                <div class="mt-6 text-2xl font-medium leading-8 dark:text-white">{F.number_format(arrProgresoCorporativo.total_ordenes_no_autorizadas_mes, 0)}</div>
                <p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200">Total posible: ${F.number_format(arrProgresoCorporativo.sum_dinero_por_autorizar_mes, 2)}</p>
                <div class="mt-1 text-base text-slate-500 dark:text-gray-200">Por autorizar</div>
              </div>

            </div>
          </div>
          <div class="col-span-3 sm:col-span-3 xl:col-span-3 intro-y">
            <div
              class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded-md before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-100 hover:scale-105 ease-in-out duration-300">

              <div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default">
                <div class="flex">
                  <ChartBarIcon className="h-6 w-6 text-red dark:text-white" aria-hidden="true" />
                </div>
                <div class="mt-6 text-2xl font-medium leading-8 dark:text-white">{F.calculadorToneladas(arrProgresoCorporativo.toneladas_resguardo_mes)}</div>
                <p className="text-xs font-semibold pb-0 text-gray-600 dark:text-gray-200 opacity-0">-</p>
                <div class="mt-1 text-base text-slate-500 dark:text-gray-200">TON en resguardo</div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}

export class ListaVendedores extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    const { state, setState, _this, } = this.props
    const { arrVendedores } = state

    return (
      <section className="py-3">
        <div>
          <div className="py-4 bg-white rounded dark:bg-slate-800 shadow-[0px_3px_5px_#0000000b]">
            <div className="px-6 pb-2">
              <h3 className="text-xl font-bold dark:text-slate-100">Mis vendedores</h3>
              <p className="text-sm text-gray-500 dark:text-slate-400">A continuación se muestran tus vendedores asignados a tu sucursal</p>
            </div>
          </div>
        </div>

        <div class="space-y-2.5 md:p-2 xl:p-7.5">

          {
            arrVendedores.map((t, e) => {
              let porcentajeDia = (t.toneladas_al_dia / t.meta_ton_dia) * 100
              let porcentajeMes = (t.toneladas_autorizadas / t.meta_ton_mes) * 100

              return (

                <div class="grid grid-cols-0 gap-0 mt-1 mb-5">
                  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                    <div
                      class="relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_5px_#0000000b] before:bg-white/30 bg-white before:h-full before:mt-2.5 before:absolute rounded before:mx-auto before:inset-x-0 before:dark:bg-gray-600/10 dark:bg-black text-black scale-95 hover:scale-100 ease-in-out duration-300">

                      <div class="p-5 box relative bg-white dark:bg-gray-800 cursor-default rounded">
                        <div
                          class="flex flex-col items-center p-5 border-b lg:flex-row border-slate-200/60 dark:border-darkmode-400">
                          <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                            <img
                              alt=""
                              class="rounded-full"
                              src="https://acyn.wsoft.com.mx/agentes/img/profile.png" />
                          </div>
                          <div class="mt-3 text-center lg:ml-2 lg:mr-auto lg:text-left lg:mt-0">
                            <a href="" class="font-medium text-2xl dark:text-gray-300">{t.nombre} {t.apellidos}</a>
                            <div class="text-red-500 dark:text-red-300 text-xs mt-0.5">
                              <div class="cursor-pointer flex text-xs font-medium text-danger">
                                Tendencia de ventas: {F.number_format(t.tendencia, 0)}%
                              </div>
                            </div>
                          </div>
                          <div class="flex mt-3 -ml-2 lg:ml-0 lg:justify-end lg:mt-0 opacity-0">
                            Más información
                          </div>
                        </div>


                        <div className="box">
                          <div class="flex flex-wrap items-center justify-center p-5 lg:flex-nowrap">
                            <div class="w-full mb-4 mr-auto lg:w-1/2 lg:mb-0 mx-4 ml-0">
                              <div class="flex text-xs text-slate-500">
                                <div class="mr-auto dark:text-gray-300">Meta del día</div>
                                <div>{F.number_format(porcentajeDia, 0)}%</div>
                              </div>
                              <div class="w-full bg-slate-200 rounded dark:bg-black/20 h-1 mt-2">
                                <div
                                  style={{ background: 'red', width: `${porcentajeDia > 100 ? 100 : porcentajeDia}%`, }}
                                  class="h-full rounded text-xs text-white flex justify-center items-center w-1/4 bg-green"
                                  role="progressbar"
                                  aria-valuenow="80"
                                  aria-valuemin="0"
                                  aria-valuemax="100"></div>
                              </div>
                            </div>
                            <div class="w-full mb-4 mr-auto lg:w-1/2 lg:mb-0 mx-4">
                              <div class="flex text-xs text-slate-500">
                                <div class="mr-auto">Meta del mes</div>
                                <div>{F.number_format(porcentajeMes, 0)}%</div>
                              </div>
                              <div class="w-full bg-slate-200 rounded dark:bg-black/20 h-1 mt-2">
                                <div
                                  style={{ background: 'red', width: `${porcentajeMes > 100 ? 100 : porcentajeMes}%`, }}
                                  class="h-full rounded text-xs text-white flex justify-center items-center w-1/4 bg-green"
                                  role="progressbar"
                                  aria-valuenow="60"
                                  aria-valuemin="0"
                                  aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-span-12 intro-y md:col-span-6">
                          <div class="grid grid-cols-2 gap-4 px-2">
                            <div
                              class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700 mb-6">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Pagos faltantes por cotejar</p>
                              <p class="text-base font-medium text-navy-700 dark:text-white">{F.number_format(t.count_sin_cotejar, 0)}</p>
                            </div>
                            <div
                              class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700 mb-6">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Toneladas por autorizar</p>
                              <p class="text-base font-medium text-navy-700 dark:text-white">{F.calculadorToneladas(t.toneladas_no_autorizadas)}</p>
                            </div>

                            <div
                              class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Toneladas producidas</p>
                              {this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">{F.calculadorToneladas(t.toneladas_autorizadas)}</p>)}
                            </div>
                            <div
                              class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Por cobrar (créditos)</p>
                              {this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">${F.number_format(t.por_cobrar, 2)}</p>)}
                            </div>
                            <div
                              class="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Ventas pendientes de autorización</p>
                              {this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">{F.number_format(t.ordenes_pendientes, 0)}</p>)}
                            </div>
                            <div
                              class="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none dark:bg-gray-700">
                              <p class="text-sm text-gray-600 dark:text-gray-400">Facturas realizadas</p>
                              {this.badgePorcentaje(2, <p class="text-base font-medium text-navy-700 dark:text-white">{F.number_format(t.facturas?.total_facturadas, 0)} de {F.number_format(t.facturas?.total, 0)}</p>)}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              )
            }
            )
          }

        </div>
      </section >
    )
  }

  badgePorcentaje(porcentaje, children) {
    return (
      <div class="cursor-pointer flex text-xs font-medium">
        {children}
        <span>
          <div class="cursor-pointer flex ml-2 text-md relative font-medium text-black dark:text-white">
            {porcentaje}%
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="stroke-[1.3] w-4 h-4 ml-0.5">
              {
                porcentaje > 50
                  ? <polyline points="18 15 12 9 6 15"></polyline>
                  : <polyline points="6 9 12 15 18 9"></polyline>
              }
            </svg>
          </div>
        </span>
      </div>
    )
  }
}

export class ListaPorAutorizar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    const { state, setState, _this, } = this.props
    const { arrListaVentasPorAutorizar } = state

    return (
      <>
        <main className="py-4 overflow-x-auto lg:px-0 lg:py-12 w-full">

          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">Ventas pendientes de autorización</h1>
                <p className="mt-2 text-sm text-gray-700">
                  Lista de todas las ventas que no han sido autorizadas o rechazadas.
                </p>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Detalles
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Sucursal
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Cantidad
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Fecha solicitud
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Autorizado por
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {arrListaVentasPorAutorizar.map((venta) => (
                        <tr key={venta.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                            <p className="font-medium text-sm">{venta.nombre_completo}</p>
                            <p className="font-light text-xs text-gray-500">{venta.nombre_completo_vendedor}</p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <p>{venta.nombre_sucursal}</p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <p>{F.calculadorToneladas(venta.sum_total_kg_conversion)}</p>
                            <p>${F.number_format(venta.sum_total_pagar, 2)}</p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <p>{moment(venta.created_at).format('DD/MM/YYYY HH:mm')}</p>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => F.obtenerInformacionOrden(state, setState, _this, venta.id_orden)}>
                              Ver<span className="sr-only"></span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </main>
      </>
    )
  }
}

export class SlideOverInformacionVenta extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { state, setState, _this, } = this.props
    const { slideInformacionVenta, arrProductosVentas, arrPagos, } = state

    return (
      <Transition.Root show={slideInformacionVenta} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideInformacionVenta", false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Información de venta
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                <span className="text-gray-600">Aquí puedes observar la infromación registrada de esta venta.</span>
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                  setState({ id_orden_selected: null, })
                                  F.handleAccionarSlider(state, setState, _this, "slideInformacionVenta", false)
                                }}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Cerrar panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                          {/* Divider container */}
                          <div className="px-3 space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                            {/* Aquí van los subcomponentes */}
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

                                {/* <p onClick={() => { setState({ id_orden_selected: null }) }}
																			className="bg-red-500 w-20 "
																		>Regresar</p> */}
                                <p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">Historial de productos ({arrProductosVentas.length})</p>
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
                                        Nombre
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                        Cantidad
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                        Total $
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {
                                      arrProductosVentas.map(t => (
                                        <tr>
                                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
                                            {t.producto}
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                                            <span>{F.calculadorToneladas(t.cantidad_conversion)}</span>
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                                            <p className="font-bold text-sm">${F.number_format(t.total_pagar)}</p>
                                            <p className="font-light text-xs text-gray-500">${F.number_format(parseFloat(t.total_pagar) / parseFloat(t.cantidad))}</p>
                                          </th>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <p className="px-3 py-3.5 text-center text-sm font-bold text-gray-900">Historial de pagos ({arrPagos.length})</p>
                                {
                                  state.requiere_auth == "1" && <p className="text-red-900">No es posible registrar abonos a esta orden de venta hasta que el administrador la autorice.</p>
                                }

                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0">
                                        Cantidad
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                        Fecha de pago
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                        Observación
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-normal text-gray-900">
                                        Cotejada
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {
                                      arrPagos.map(t => (
                                        <tr>
                                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0">
                                            <p>${F.number_format(t.cantidad)}</p>
                                            <p className="text-xs">{F.textFormaPagoCobranza(t.forma_pago)}</p>
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                                            {moment(t.created_at).format('DD/MM/YYYY HH:mm')}
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                                            <p>{t.observacion}</p>
                                          </th>
                                          <th scope="col" className="px-3 py-3.5 text-center text-sm text-gray-900">
                                            <p>{t.venta_cotejada === '1' ? "Si" : 'Aún no'}</p>
                                            <p>{t.comprobante !== "" ? "Pulsa para ver comprobante" : "No se subió un comprobante"}</p>
                                          </th>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>


                          </div>

                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">

                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => F.handleAccionarSlider(state, setState, _this, "slideInformacionVenta", false)}
                          >
                            Cerrar
                          </button>
                          <button
                            type="button"
                            className="ml-4 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-gray-300 hover:bg-green-700"
                            onClick={() => F.autorizarVenta(state, setState, _this)}
                          >
                            Autorizar venta
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export class Notificacion extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    const { state, setState, _this, } = this.props
    const { notificacion, } = state

    return (
      <div
        ariaLive="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={notificacion.titulo ? true : false}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {notificacion.icono}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notificacion.titulo}</p>
                    <p className="mt-1 text-sm text-gray-500">{notificacion.descripcion}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setState({ notificacion: {} })
                      }}
                    >
                      <span className="sr-only">Cerrar</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    )
  }
}

export class Configuraciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      automaticTimezoneEnabled: false,
      view: 'productos',
      openSlideOverProducto: false,
      openSlideOverSucursal: false,
      openSlideOverUsuarios: false,
      openModalResetPassword: false,
      openModalSuspenderCuenta: false,
      openSlideOverEditarMeta: false,
      openSlideActualizarProducto: false,
      infoProducto: {},
    }
  }

  componentDidMount() {
    // F.initData();
  }

  render() {
    const { automaticTimezoneEnabled, view, openSlideOverProducto, openSlideOverSucursal, openSlideOverUsuarios,
      openModalResetPassword,
      openModalSuspenderCuenta,
      openSlideOverEditarMeta,
      openSlideActualizarProducto,
      infoProducto,
    } = this.state

    const secondaryNavigation = [
      { id: 'productos', name: 'Productos', href: '#', icon: CubeIcon, current: true },
      { id: 'sucursales', name: 'Sucursales', href: '#', icon: HomeModernIcon, current: false },
      { id: 'usuarios', name: 'Usuarios', href: '#', icon: UsersIcon, current: false }
    ]

    const productos = []

    const { state, setState, _this, } = this.props
    const { arrProductos, arrUsuarios, arrSucursales, } = state


    return (
      <>
        <div className="min-h-full">

          <div className="py-10">
            <header>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Configuraciones</h1>
              </div>
            </header>
            <main>
              <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-12">
                  <nav className="flex-none px-4 sm:px-6 lg:px-0">
                    <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                      {secondaryNavigation.map((item) => (
                        <li key={item.name}
                          onClick={(e) => this.setState({ view: item.id })}
                        >
                          <a
                            href={item.href}
                            className={classNames(
                              item.id === view
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.id === view ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </aside>

                <main className="px-4 py-16 overflow-x-auto sm:px-6 lg:px-0 lg:py-12 w-full">

                  {
                    view === 'productos' && (
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                          <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Productos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                              Lista de todos los productos
                            </p>
                          </div>
                          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                              type="button"
                              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => this.setState({ openSlideOverProducto: true })}
                            >
                              Agregar producto
                            </button>
                          </div>
                        </div>
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <input
                                type="text"
                                name="clave_producto"
                                id="clave_producto"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Buscar un producto..."
                              />
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                      Nombre
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Unidad
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Costo adquisición
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Costo venta
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {arrProductos.map((item) => (
                                    <tr key={item.id_producto}>
                                      <td className="whitespace-nowrap text-sm font-medium text-gray-900 sm:pl-0">
                                        <p>{item.producto}</p>
                                        <p>{item.clave}</p>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <p>{item.unidad}</p>
                                        <p>{item.cve_prod}</p>
                                        <p>{item.cve_unid}</p>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <p>${F.number_format(item.costo_compra, 2)}</p>
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <p>Contado: <span>${F.number_format(item.costo_venta, 2)}</span></p>
                                        <p>15 d: <span>${F.number_format(item.costo_venta_15, 2)}</span></p>
                                        <p>30 d: <span>${F.number_format(item.costo_venta_30, 2)}</span></p>
                                      </td>
                                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <a href="#" className="text-red-600 hover:text-red-900">
                                          Eliminar
                                        </a>
                                        <br />
                                        <a href="#" onClick={() => { this.setState({ openSlideActualizarProducto: true, infoProducto: item }) }} className="text-indigo-600 hover:text-indigo-900">
                                          Actualizar
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    view === 'sucursales' && (
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                          <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sucursales</h1>
                            <p className="mt-2 text-sm text-gray-700">
                              Lista de todas las sucursales
                            </p>
                          </div>
                          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                              type="button"
                              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => {
                                this.setState({ openSlideOverSucursal: true })
                              }}
                            >
                              Agregar sucursal
                            </button>
                          </div>
                        </div>
                        <div className="mt-8 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                  <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                      Nombre
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                      Tipo
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                  {arrSucursales.map((person) => (
                                    <tr key={person.email}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {person.nombre}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.type == "1" ? 'Normal' : 'Almacén'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    view === 'usuarios' && (
                      <>
                        <div className="sm:flex sm:items-center">
                          <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Usuarios</h1>
                            <p className="mt-2 text-sm text-gray-700">
                              Lista de todos las usuarios
                            </p>
                          </div>
                          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                              type="button"
                              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => {
                                this.setState({ openSlideOverUsuarios: true })
                              }}
                            >
                              Agregar usuario
                            </button>
                          </div>
                        </div>
                        <div className="w-full overflow-x-auto shadow-md sm:rounded-lg mt-5" style={{ minHeight: '250px' }}>
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th scope="col" className="px-6 py-3">
                                  Nombre
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Usuario
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Sucursal
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Tipo
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  Opciones
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                arrUsuarios.map(item => (
                                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                      <p>{item.nombre} {item.apellidos}</p>
                                    </th>
                                    <td className="px-6 py-4">
                                      <p>{item.usuario}</p>
                                    </td>
                                    <td className="px-6 py-4">
                                      <p>{this.obtenerSucursal(arrSucursales, item.id_sucursal)}</p>
                                    </td>
                                    <td className="px-6 py-4">
                                      <p>{item.type == "1" ? 'Administrador' : item.type == "2" ? 'Punto de venta' : item.type == '3' ? 'Supervisor' : item.type == "4" ? 'Contador' : ''}</p>
                                    </td>
                                    <td className="px-6 py-4">
                                      <p>Ver más</p>
                                    </td>
                                  </tr>
                                ))
                              }

                            </tbody>
                          </table>
                        </div>
                      </>
                    )
                  }

                </main>
              </div>
            </main>
          </div>
        </div>

        <Transition.Root show={openSlideActualizarProducto} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideActualizarProducto: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Actualizar producto
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Ingresa las nuevas cantidades del producto
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideActualizarProducto: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="costo_contado"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Costo de contado
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="costo_contado"
                                  id="costo_contado"
                                  value={infoProducto.costo_venta}
                                  onChange={(ev) => { infoProducto.costo_venta = ev.target.value; this.setState({ infoProducto, }) }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="costo_15"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Costo a 15 días
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="costo_15"
                                  id="costo_15"
                                  value={infoProducto.costo_venta_15}
                                  onChange={(ev) => { infoProducto.costo_venta_15 = ev.target.value; this.setState({ infoProducto }) }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="costo_30"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Costo a 30 días
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="costo_30"
                                  id="costo_30"
                                  value={infoProducto.costo_venta_30}
                                  onChange={(ev) => { infoProducto.costo_venta_30 = ev.target.value; this.setState({ infoProducto }) }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="costo_adquisicion"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Costo de adquisición
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="costo_adquisicion"
                                  id="costo_adquisicion"
                                  value={infoProducto.costo_compra}
                                  onChange={(ev) => { infoProducto.costo_compra = ev.target.value; this.setState({ infoProducto, }) }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideActualizarProducto: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                F.actualizarCostoProducto(this, infoProducto)
                              }}
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Actualizar costos
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openSlideOverProducto} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideOverProducto: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Nuevo producto
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Ingresa la información para agregar un nuevo producto.
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideOverProducto: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            {/* Project name */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="clave_producto"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Clave del producto
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="clave_producto"
                                  id="clave_producto"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="nombre_producto"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Nombre del producto
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="nombre_producto"
                                  id="nombre_producto"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="unidad_producto"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Unidad
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="unidad_producto"
                                  id="unidad_producto"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="cve_producto"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Cve Producto
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="cve_producto"
                                  name="cve_producto"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="opcion1">Opción 1</option>
                                  <option value="opcion2">Opción 2</option>
                                  <option value="opcion3">Opción 3</option>
                                </select>
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="cve_unidad"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Cve Unidad
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="cve_unidad"
                                  name="cve_unidad"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="opcion1">Opción 1</option>
                                  <option value="opcion2">Opción 2</option>
                                  <option value="opcion3">Opción 3</option>
                                </select>
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="producto_iva"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  IVA
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="number"
                                  name="producto_iva"
                                  id="producto_iva"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideOverProducto: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Agregar
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openSlideOverSucursal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideOverSucursal: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Nuevo sucursal
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Ingresa la información para agregar una nueva sucursal.
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideOverSucursal: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="nombre_sucursal"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Nombre de la sucursal
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="nombre_sucursal"
                                  id="nombre_sucursal"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="tipo_sucursal"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Tipo
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  id="tipo_sucursal"
                                  name="tipo_sucursal"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="1">Sucursal Normal</option>
                                  <option value="2">Almacén General</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideOverSucursal: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Agregar
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openSlideOverUsuarios} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideOverUsuarios: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Nuevo usuario
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Ingresa la información para agregar un nuevo usuario
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideOverUsuarios: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_nombre"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Nombre
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="text"
                                  name="usuario_nombre"
                                  id="usuario_nombre"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe el nombre del usuario"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_apellidos"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Apellidos
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="text"
                                  name="usuario_apellidos"
                                  id="usuario_apellidos"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe los apellidos del usuario"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_nombre_usuario"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Usuario
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="text"
                                  name="usuario_nombre_usuario"
                                  id="usuario_nombre_usuario"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe un nombre de usuario. Ej. jose91"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_password"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Contraseña
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="password"
                                  name="usuario_password"
                                  id="usuario_password"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe la contraseña del usuario"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_tipo"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Tipo
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  onChange={(e) => { console.log(e.target.value) }}
                                  id="usuario_tipo"
                                  name="usuario_tipo"
                                  className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="1">Administrador</option>
                                  <option value="2">Vendedor</option>
                                  <option value="3">Supervisor</option>
                                  <option value="4">Contabilidad</option>
                                </select>
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_sucursal"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Sucursal
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  onChange={(e) => { console.log(e.target.value) }}
                                  id="usuario_sucursal"
                                  name="usuario_sucursal"
                                  className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value="valles">Ciudad Valles</option>
                                  <option value="tamazunchale">Tamazunchale</option>
                                  <option value="tampico">Tampico</option>
                                </select>
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_meta_kg"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Meta en toneladas
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="number"
                                  name="usuario_meta_kg"
                                  id="usuario_meta_kg"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe la meta del usuario"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="usuario_meta_dinero"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Meta en dinero
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="number"
                                  name="usuario_meta_dinero"
                                  id="usuario_meta_dinero"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe la meta de dinero"
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideOverUsuarios: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Agregar
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openModalResetPassword} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openModalResetPassword: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Reiniciar contraseña
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            ¿Estás seguro que deseas reiniciar la contraseña del usuario?<br />El proceso para volver a acceder a su portal será ingresando en inicio de sesión y dejando en blanco el campo contraseña
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => this.setState({ openModalResetPassword: false })}
                      >
                        Si, reiniciar
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => this.setState({ openModalResetPassword: false })}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openModalSuspenderCuenta} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openModalSuspenderCuenta: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Suspender cuenta
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            ¿Estás seguro que deseas suspender la cuenta del usuario, si lo realizas ya no podrá acceder a su portal?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => this.setState({ openModalSuspenderCuenta: false })}
                      >
                        Si, cancelar
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => this.setState({ openModalSuspenderCuenta: false })}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={openSlideOverEditarMeta} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideOverEditarMeta: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Editar meta
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Ingresa la información para editar la meta del usuario
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideOverEditarMeta: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Divider container */}
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="meta_toneladas"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Meta en toneladas
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="number"
                                  name="meta_toneladas"
                                  id="meta_toneladas"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe la meta del usuario"
                                />
                              </div>
                            </div>

                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="meta_dinero"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Meta en dinero
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  onChange={(e) => { console.log(e.target.value) }}
                                  type="number"
                                  name="meta_dinero"
                                  id="meta_dinero"
                                  className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Escribe la meta de dinero"
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideOverEditarMeta: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Guardar cambios
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </>
    )
  }

  obtenerSucursal(arrSucursales, id_sucursal) {

    const item = arrSucursales.find(sucursal => sucursal.id_sucursal == id_sucursal)

    if (typeof item !== 'undefined')
      return item.nombre

    return ''
  }
}


export class TablaInventarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSlideEntradaAlmacen: false,
    }
  }

  componentDidMount() {
  }

  render() {

    const { state, setState, _this, } = this.props
    const { arrSucursales, arrGeneral, } = state
    const { openSlideEntradaAlmacen } = this.state

    return (
      <>
        <div className="min-h-full">

          <div className="py-10">
            <header>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Inventarios</h1>

                <select
                  id="cve_producto"
                  name="cve_producto"
                  onChange={(ev) => F.obtenerInventariosYStockPorSucursal(state, setState, _this, ev.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {
                    arrSucursales.map(item => (
                      <option value={item.id_sucursal}>{item.nombre}</option>
                    ))
                  }
                </select>
              </div>
            </header>
            <main>

              <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Productos</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Lista de todos los productos con stock y cantidades disponibles.
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                      type="button"
                      className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => this.setState({ openSlideEntradaAlmacen: true })}
                    >
                      Agregar entrada de almacén
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                              Nombre
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Disponible
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Stock mínimo
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {arrGeneral.map((item) => {
                            item.total_kg = item.total_kg ? item.total_kg : 0
                            return (
                              <tr key={item.id_producto}>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 sm:pl-0">
                                  <p>{item.producto}</p>
                                  <p className="text-xs text-slate-500">{item.clave}</p>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <p className="text-slate-900 font-bold text-base">{F.calculadorToneladas(item.cantidad - item.total_kg)}</p>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <p className="text-slate-900 font-bold text-base">{F.calculadorToneladas(item.minimo)}</p>
                                  {
                                    item.minimo - (item.cantidad - item.total_kg) > 0
                                    && <p className="bg-red-600 text-white text-center rounded-lg m-0 p-0">Faltante: {F.calculadorToneladas(item.minimo - (item.cantidad - item.total_kg))}</p>

                                  }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                                </td>
                              </tr>
                            )

                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </main>
          </div>
        </div>

        <Transition.Root show={openSlideEntradaAlmacen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => this.setState({ openSlideEntradaAlmacen: false })}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1">
                          {/* Header */}
                          <div className="bg-gray-50 px-4 py-6 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                  Nueva entrada al almacén {
                                    this.obtenerSucursal(arrSucursales, state.id_sucursal_selected)
                                  }
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Rellena los siguientes campos para registrar una nueva entrada al amacén
                                </p>
                              </div>
                              <div className="flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-gray-400 hover:text-gray-500"
                                  onClick={() => this.setState({ openSlideEntradaAlmacen: false })}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="concepto"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Concepto
                                </label>
                              </div>
                              <div classNxame="sm:col-span-2">
                                <input
                                  type="text"
                                  name="concepto"
                                  id="concepto"
                                  value={state.conceptoEntradaAlmacen}
                                  onChange={(ev) => {
                                    setState({
                                      conceptoEntradaAlmacen: ev.target.value
                                    })
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Observación
                                </label>
                              </div>
                              <div classNxame="sm:col-span-2">
                                <textarea
                                  value={state.observacionEntradaAlmacen}
                                  onChange={(ev) => {
                                    setState({
                                      observacionEntradaAlmacen: ev.target.value
                                    })
                                  }}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>


                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0 bg-slate-200">
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Producto
                                </label>
                              </div>
                              <div classNxame="sm:col-span-2">
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Nueva cantidad
                                </label>
                              </div>
                              <div classNxame="sm:col-span-2">
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                >
                                  Disponible
                                </label>
                              </div>
                            </div>
                          </div>

                          {
                            arrGeneral.map((t, e) => {
                              if (t.producto) {
                                return <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                  <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                      >
                                        {t.producto}
                                      </label>
                                    </div>
                                    <div classNxame="sm:col-span-2">
                                      <input
                                        type="number"
                                        value={t.nueva_cantidad}
                                        onChange={(ev) => {
                                          arrGeneral[e].nueva_cantidad = ev.target.value
                                          setState({
                                            arrGeneral
                                          })
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div classNxame="sm:col-span-2">
                                      <p>Actual: {t.cantidad - t.total_kg}</p>
                                      {
                                        t.nueva_cantidad && <p>Ahora: {F.calculadorToneladas(parseFloat(t.cantidad - t.total_kg) + parseFloat(t.nueva_cantidad))}</p>
                                      }
                                    </div>
                                  </div>
                                </div>

                              }
                              return null
                            })
                          }

                        </div>

                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => this.setState({ openSlideEntradaAlmacen: false })}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();

                                F.registrarEntradaAlmacen(state, setState, _this, () => {
                                  this.setState({
                                    openSlideEntradaAlmacen: false
                                  })
                                })

                              }}
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Registrar entrada
                            </button>
                          </div>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </>
    )
  }

  obtenerSucursal(arrSucursales, id_sucursal) {

    const item = arrSucursales.find(sucursal => sucursal.id_sucursal == id_sucursal)

    if (typeof item !== 'undefined')
      return item.nombre

    return ''
  }
}


export class TablaCreditos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    const { state, setState, _this, } = this.props
    const { arrCreditos, arrSucursales, index_vendedor_creditos, } = state

    return (
      <>
        <div className="min-h-full">

          <div className="py-10">
            <header>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Créditos</h1>
              </div>
            </header>
            <main>

              <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">

                  <section class="py-8">
                    <div class="mx-auto">
                      <div class="flex flex-wrap -mx-4">
                        <div class="w-full lg:w-3/3 px-4 mb-8 lg:mb-0">
                          <div class="pt-4 bg-white shadow rounded">
                            <div class="px-6 border-b border-blue-50">
                              <div class="flex flex-wrap items-center mb-10">
                                {
                                  index_vendedor_creditos === ''
                                  && <div>
                                    <h3 class="text-xl font-bold">Vendedores / Clientes</h3>
                                    <p class="text-sm text-gray-500 font-medium">Lista de vendedores, cantidad autorizada de créditos y porcentaje de uso</p>
                                  </div>
                                }
                                {
                                  index_vendedor_creditos !== ''
                                  && <div>
                                    <h3 class="text-xl font-bold">Clientes de {arrCreditos[index_vendedor_creditos].nombre} {arrCreditos[index_vendedor_creditos].apellidos}</h3>
                                    <p class="text-sm text-gray-500 font-medium">Lista de clientes, cantidad autorizada de créditos y porcentaje de uso</p>
                                    {this.makeCantidadesPorCreditos(arrCreditos[index_vendedor_creditos])}
                                  </div>
                                }
                              </div>
                              <div>
                                <a onClick={() => { setState({ index_vendedor_creditos: '', }) }} className={`inline-block px-4 pb-2 text-sm font-medium border-b-2 ${index_vendedor_creditos === '' ? 'text-indigo-500 border-indigo-500' : 'text-gray-300 hover:border-gray-300'}`} href="#">Vendedores</a>
                                <a onClick={() => { }} className={`inline-block px-4 pb-2 text-sm font-medium border-b-2 border-transparent ${index_vendedor_creditos !== '' ? 'text-indigo-500 border-indigo-500' : 'text-gray-300 hover:border-gray-300'}`} href="#">Clientes</a>
                              </div>
                            </div>
                            <div class="overflow-x-auto">
                              <table class="table-auto w-full">
                                <thead class="bg-gray-50">
                                  {
                                    index_vendedor_creditos === ''
                                    && <tr class="text-xs text-gray-500 text-left">
                                      <th class="flex items-center pl-6 py-4 font-medium">
                                        <span>Vendedor</span>
                                      </th>
                                      <th class="py-4 font-medium">Sucursal</th>
                                      <th class="py-4 font-medium">Crédito permitido</th>
                                      <th class="py-4 font-medium">Crédito usado</th>
                                      <th class="py-4 font-medium">% Utilizado</th>
                                    </tr>
                                  }
                                  {
                                    index_vendedor_creditos !== ''
                                    && <tr class="text-xs text-gray-500 text-left">
                                      <th class="flex items-center pl-6 py-4 font-medium">
                                        <span>Cliente</span>
                                      </th>
                                      <th class="py-4 font-medium">Crédito</th>
                                      <th class="py-4 font-medium">% representado</th>
                                      <th class="py-4 font-medium">Opciones</th>
                                    </tr>
                                  }
                                </thead>
                                <tbody>
                                  {index_vendedor_creditos === '' && this.makeListaVendedores(arrCreditos, arrSucursales, state, setState, _this)}
                                  {index_vendedor_creditos !== '' && this.makeListaClientes(arrCreditos, arrSucursales, state, setState, _this)}
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </section>

                </div>
              </div>

            </main>
          </div>
        </div>

      </>
    )
  }

  makeCantidadesPorCreditos(t) {
    let { cantidadAutorizada, pendienteValidar } = this.obtenerCantidadUsada(t.arrClientes)

    return (
      <>
        <p class="text-sm text-gray-800 font-medium mt-10">Crédito total autorizado: <b>${F.number_format(t.limite_credito, 2)}</b></p>
        <p class="text-sm text-gray-800 font-medium">Crédito usado: <b>${F.number_format(cantidadAutorizada, 2)}</b></p>
      </>
    )

  }

  makeListaVendedores(arrCreditos, arrSucursales, state, setState, _this) {
    return <>
      {
        arrCreditos.map((t, e) => {
          let { cantidadAutorizada, pendienteValidar } = this.obtenerCantidadUsada(t.arrClientes),
            cantidadDisponible = parseFloat(t.limite_credito) - parseFloat(cantidadAutorizada),
            porcentajeUsado = (parseFloat(cantidadAutorizada) / parseFloat(t.limite_credito)) * 100,
            semaforo = ''

          if (porcentajeUsado < 35)
            semaforo = 'green'
          else if (porcentajeUsado >= 35 && porcentajeUsado < 60)
            semaforo = 'yellow'
          else
            semaforo = 'red'

          const bg50Class = `bg-${semaforo}-50`,
            bg500Class = `bg-${semaforo}-500`

          return (
            <tr class="border-b border-blue-50 hover:bg-slate-100 cursor-pointer" onClick={() => { setState({ index_vendedor_creditos: e }) }}>
              <td class="flex items-center py-4 px-6 font-medium">
                <div class="flex px-4 py-3">
                  <img class="w-8 h-8 mr-4 object-cover rounded-md" src="https://acyn.wsoft.com.mx/agentes/img/profile.png" alt="" />
                  <div>
                    <p class="text-sm font-medium">{t.nombre} {t.apellidos}</p>
                    <p class="text-xs text-gray-500">{t.usuario}</p>
                    {pendienteValidar && <span class="inline-block px-2 py-1 text-white bg-yellow-500 rounded-full text-xs">Pendiente validar</span>}
                  </div>
                </div>
              </td>
              <td class="font-medium">
                <p class="text-sm">{this.obtenerSucursal(arrSucursales, t.id_sucursal)}</p>
              </td>
              <td class="font-light">
                <p class="text-sm">
                  ${F.number_format(t.limite_credito, 2)}
                  <p className="text-xs pl-2 pt-2 text-blue-600 cursor-pointer"
                    onClick={() => {
                      F.input__dinamico__cambiaValor(state, setState, _this, 'id_vendedor', t.id_usuario)
                      F.input__dinamico__cambiaValor(state, setState, _this, 'limite_credito', t.limite_credito)
                      F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", true)
                      setTimeout(() => {
                        setState({
                          index_vendedor_creditos: ''
                        })
                      }, 10)
                    }}
                  >Aumentar</p>
                </p>
              </td>
              <td class="font-medium">
                <p class="text-sm">
                  ${F.number_format(cantidadAutorizada, 2)}
                </p>
                <p class="text-xs text-gray-500">
                  Disponible: ${F.number_format(cantidadDisponible, 2)}
                </p>
              </td>
              <td class="pr-6">
                <p class="mb-1 text-xs text-slate-800 font-medium">{parseInt(porcentajeUsado)}%</p>
                <div class="flex">
                  <div class={`relative h-2 w-48 ${bg50Class} rounded-full`}>
                    <div class={`absolute top-0 left-0 h-full ${bg500Class} rounded-full`} style={{ width: (porcentajeUsado > 100 ? 100 : porcentajeUsado) + '%' }}></div>
                  </div>
                </div>
              </td>
            </tr>
          )

        })
      }
    </>
  }

  makeListaClientes(arrCreditos, arrSucursales, state, setState, _this) {
    const t = arrCreditos[state.index_vendedor_creditos]

    return (
      <>
        {
          t.arrClientes.map(t0 => {
            let { cantidadAutorizada, pendienteValidar } = this.obtenerCantidadUsada(t.arrClientes),
              cantidadDisponible = parseFloat(t.limite_credito) - parseFloat(cantidadAutorizada),
              porcentajeUsado = ((t0.credito / t.limite_credito) * 100),
              semaforo = ''
            if (porcentajeUsado < 35)
              semaforo = 'green'
            else if (porcentajeUsado >= 35 && porcentajeUsado < 60)
              semaforo = 'yellow'
            else
              semaforo = 'red'

            const bg50Class = `bg-${semaforo}-50`,
              bg500Class = `bg-${semaforo}-500`

            return (

              <tr class="border-b border-blue-50">
                <td class="flex items-center py-4 px-6 font-medium">
                  <div class="flex px-4 py-3">
                    <div>
                      <p class="text-sm font-medium">{t0.nombre} {t0.a_paterno} {t0.a_materno}</p>
                      <p class="text-xs text-gray-500">{t0.correo}</p>
                      {t0.solicitud_credito === "2" && <span class="inline-block px-2 py-1 text-white bg-yellow-500 rounded-full text-xs">Pendiente validar</span>}
                      {t0.solicitud_credito === "3" && <span class="inline-block px-2 py-1 text-white bg-green-500 rounded-full text-xs">Crédito autorizado</span>}
                      {t0.solicitud_credito === "4" && <span class="inline-block px-2 py-1 text-white bg-red-500 rounded-full text-xs">Crédito no autorizado</span>}
                    </div>
                  </div>
                </td>
                <td class="font-light">
                  <p class="text-sm">
                    {
                      t0.solicitud_credito === "2"
                        ? <p className="text-md text-blue-600 cursor-pointer" onClick={() => {
                          F.input__dinamico__cambiaValor(state, setState, _this, 'id_cliente', t0.id_cliente)
                          F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", true)
                          F.obtenerSolicitudCreditoCliente(state, setState, _this, t0.id_cliente)
                        }}>Validar documentos</p>
                        : <p>${F.number_format(t0.credito, 2)}</p>
                    }
                  </p>
                </td>
                <td class="pr-6">
                  <p class="mb-1 text-xs text-slate-800 font-medium">{parseInt(porcentajeUsado)}%</p>
                  <div class="flex">
                    <div class={`relative h-2 w-48 ${bg50Class} rounded-full`}>
                      <div class={`absolute top-0 left-0 h-full ${bg500Class} rounded-full`} style={{ width: (porcentajeUsado > 100 ? 100 : porcentajeUsado) + '%' }}></div>
                    </div>
                  </div>
                </td>
                <td class="pr-6">
                  {
                    t0.solicitud_credito === "3" &&
                    <p class="mb-1 text-xs text-slate-800 font-medium cursor-pointer hover:text-blue-400" onClick={() => {
                      F.input__dinamico__cambiaValor(state, setState, _this, 'id_cliente', t0.id_cliente)
                      F.input__dinamico__cambiaValor(state, setState, _this, 'limite_credito', t0.credito)
                      F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", true)
                    }}>Aumentar crédito</p>
                  }
                </td>
              </tr>

            )
          })
        }
      </>
    )
  }

  obtenerSucursal(arrSucursales, id_sucursal) {
    const item = arrSucursales.find(sucursal => sucursal.id_sucursal == id_sucursal)
    if (typeof item !== 'undefined')
      return item.nombre
    return ''
  }

  obtenerCantidadUsada(arrClientes) {
    let cantidad = 0, pendienteValidar = false
    arrClientes.map(cliente => {
      cantidad += parseFloat(cliente.credito)
      if (cliente.solicitud_credito === "2") {
        pendienteValidar = true
      }
    })
    return {
      cantidadAutorizada: cantidad,
      pendienteValidar
    }
  }

}

export class SlideOverAumentarCredito extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { state, setState, _this, } = this.props
    const { slideAumentarCredito, sidebarInputs, } = state


    if (typeof slideAumentarCredito === 'undefined')
      return null

    return (
      <Transition.Root show={slideAumentarCredito} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 dark:bg-slate-800 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
                                Aumentar límite de crédito
                              </Dialog.Title>
                              <p className="text-sm text-gray-500 dark:text-gray-300">
                                * Al realizar un cambio en el límite de crédito para tu cliente se notificará a los administradores.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-gray-400 hover:text-gray-500"
                                onClick={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Cerrar panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                          {/* Aquí van los subcomponentes */}
                          <div className="px-6 py-5">
                            <label htmlFor="credito" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                              Nuevo crédito
                            </label>
                            <div className="mt-2">
                              <input
                                type="number"
                                name="credito"
                                id="credito"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:ring-gray-700 dark:text-gray-300"
                                value={state.limite_credito}
                                onChange={(e) => F.input__dinamico__cambiaValor(state, setState, _this, 'limite_credito', e.target.value)}
                              />
                            </div>
                          </div>

                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => F.handleAccionarSlider(state, setState, _this, "slideAumentarCredito", false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            onClick={(e) => { F.fichaCliente__registraCambioCredito(state, setState, _this) }}
                          >
                            Cambiar límite de crédito
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export class SlideOverValidarDocumentacion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { state, setState, _this, } = this.props
    const { sliderValidarDocumentacion, sidebarInputs, arrSolicitud, id_cliente, } = state


    if (typeof sliderValidarDocumentacion === 'undefined')
      return null

    return (
      <Transition.Root show={sliderValidarDocumentacion} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Validar solicitud de crédito
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                A continuación se muestra toda la documentación que el cliente ha proporcionado.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="relative text-gray-400 hover:text-gray-500"
                                onClick={() => F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", false)}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Cerrar panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                          {/* Aquí van los subcomponentes */}
                          <div className="mt-6 border-t border-gray-100">

                            <dl className="bg-white divide-y divide-gray-100">
                              {
                                arrSolicitud.map((t, e) => {
                                  return (
                                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">{t.nombre}</dt>
                                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {
                                          t.type === 'file'
                                            ? <div>
                                              {
                                                t.value === ""
                                                  ? <p className=" text-red-500 ">El cliente no subió el documento</p>
                                                  : <a href={`https://app.santori.com.mx/REST/docs/${t.value}`} target={'_blank'}>Pulsa para visualizar</a>
                                              }
                                            </div>
                                            : <p>{t.value}</p>
                                        }
                                      </dd>
                                    </div>
                                  )
                                })
                              }

                            </dl>
                          </div>
                        </div>

                        <img src={`https://app.santori.com.mx/REST/firmas/${id_cliente}.png`} />


                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", false)}
                          >
                            Cancelar
                          </button>
                          <button
                            type="button"
                            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-500"
                            onClick={() => {
                              F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", false)
                              F.autorizarLineaCredito(state, setState, _this, 4)
                            }}
                          >
                            Rechazar
                          </button>
                          <button
                            type="button"
                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-500"
                            onClick={() => {
                              F.handleAccionarSlider(state, setState, _this, "sliderValidarDocumentacion", false)
                              F.autorizarLineaCredito(state, setState, _this, 3)
                            }}
                          >
                            Autorizar
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root >
    )
  }
}
