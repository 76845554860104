import * as C from './admin.components';

export const render__homeAdmin = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-3 sm:px-6 lg:px-8'} paddingMain={'py-4'} _this={_this} navigationIndex={1}>
      <C.Saludo state={state} setState={setState} _this={_this} />
      {/* <C.StatsWidgets state={state} setState={setState} _this={_this} /> */}
      <C.MisPendientes state={state} setState={setState} _this={_this} />
      <C.ProgresoCorporativo state={state} setState={setState} _this={_this} />
      {/* <C.Meta state={state} setState={setState} _this={_this} /> */}
      {/* <C.ListaVendedores state={state} setState={setState} _this={_this} /> */}

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}

export const render__porAutorizar = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-3 sm:px-6 lg:px-8'} paddingMain={'py-4'} _this={_this} navigationIndex={2}>
      <C.ListaPorAutorizar state={state} setState={setState} _this={_this} />
      <C.SlideOverInformacionVenta state={state} setState={setState} _this={_this} />

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}

export const render__Inventarios = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-3 sm:px-6 lg:px-8'} paddingMain={'py-4'} _this={_this} navigationIndex={3}>
      <C.TablaInventarios state={state} setState={setState} _this={_this} />

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}

export const render__Creditos = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-3 sm:px-6 lg:px-8'} paddingMain={'py-4'} _this={_this} navigationIndex={4}>
      <C.TablaCreditos state={state} setState={setState} _this={_this} />
      <C.SlideOverAumentarCredito state={state} setState={setState} _this={_this} />
      <C.SlideOverValidarDocumentacion state={state} setState={setState} _this={_this} />

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}

export const render__Configuraciones = (state, setState, _this) => {
  return (
    <C.Dashboard padding={'px-3 sm:px-6 lg:px-8'} paddingMain={'py-4'} _this={_this} navigationIndex={5}>
      <C.Configuraciones state={state} setState={setState} _this={_this} />

      <C.Notificacion state={state} setState={setState} _this={_this} />
    </C.Dashboard>
  )
}