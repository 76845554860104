import { HTTP_POST, HTTP_UPLOAD, } from "../http";
import moment from 'moment'
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'

export const mostrarNotificacion = (state, setState, _this, notificacion) => {
    setState({ notificacion })
    setTimeout(() => {
        setState({
            notificacion: {},
        })
    }, notificacion.timeout)
}

export const textoFormaDePago = (forma_pago) => {
    if (forma_pago === 'contado')
        return 'Pago de contado'
    else if (forma_pago === '15')
        return 'Crédito a 15 días'
    if (forma_pago === '30')
        return 'Crédito a 30 días'
    if (forma_pago === 'otro')
        return 'A convenir del vendedor'
    else
        return ''

}

export const textFormaPagoCobranza = (forma_pago) => {
    if (forma_pago === 'efectivo')
        return 'Efectivo'
    else if (forma_pago === 't_credito')
        return 'T. Crédito'
    else if (forma_pago === 't_debito')
        return 'T. Débito'
    else if (forma_pago === 'SPEI')
        return 'Transferencia E.'
    else if (forma_pago === 'deposito')
        return 'Depósito'
    else if (forma_pago === 'sie')
        return 'Convenio SIE'
    else
        return ''

}

export const number_format = (str, long) => {
    if (str == null) str = 0;
    return parseFloat(str)
        .toFixed(typeof long === 'undefined' ? 2 : long)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const calculadorToneladas = (cantidad) => {
    return `${(cantidad < 1000) ? (parseFloat(cantidad).toFixed(2) + ' KG') : ((cantidad / 1000).toFixed(2) + ' TON')}`
}

export const uniqid = () => {
    // Obtener el tiempo actual en milisegundos
    const now = Date.now();

    // Generar un número aleatorio
    const random = Math.floor(Math.random() * 1000000);

    // Combinar ambos y convertir a hexadecimal
    return now.toString(16) + random.toString(16);
}

export const formateaCantidad = (cantidad, noMostrarPesos) => {
    if (typeof cantidad !== 'undefined')
        return (noMostrarPesos ? '' : '$') + parseFloat(cantidad).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    else
        return 0
}

export const initData = (state, setState, _this) => {
    const id_cliente = _this.props.router.params.id_cliente
    const url = `clientes/obtenerFichaCliente`
    const data = { id_cliente }
    HTTP_POST(url, data, response => {
        if (response === 'error')
            return false

        let { ficha, solicitud_credito, autorizado } = response

        setState({
            internet: true,
            ficha,
            arrSolicitud: solicitud_credito
        })
    })
}

export const handleAccionarSlider = (state, setState, _this, slider, opt) => {
    setState({ [slider]: opt })
}

export const handleChangeInput = (state, setState, _this, input, value) => {
    setState({
        [input]: value
    })
}

export const handleChangeInputMulti = (state, setState, _this, param, input, value) => {
    setState(prevState => ({
        ...prevState,
        [param]: {
            ...prevState[param],
            [input]: value
        }
    }))
}

export const firmarSolicitudCredito = (state, setState, _this, base64) => {
    const { ficha } = state
    const { id_cliente } = _this.props.router.params

    const { navigate } = _this.props.router

    const url = `clientes/firmarSolicitudCredito`
    const data = { firma: base64, id_cliente, }
    HTTP_POST(url, data, response => {
        if (typeof response === 'error')
            return false

        mostrarNotificacion(
            state, setState, _this,
            {
                icono: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
                titulo: 'Aviso',
                descripcion: 'Se envió la solicitud de crédito con éxito, pronto te contactaremos.',
                timeout: 10000
            }
        )

        navigate(`/clientes/${id_cliente}/enProceso`)
    })

}

export const registrarSolicitudCredito = (state, setState, _this, opt, coordinates) => {
    const { arrSolicitud, ficha, } = state
    const { id_cliente } = _this.props.router.params
    const { navigate } = _this.props.router

    if (opt === 1) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude, accuracy } = position.coords
                registrarSolicitudCredito(state, setState, _this, 2, { latitude, longitude, accuracy })
            });
        } else {
            mostrarNotificacion(
                state, setState, _this,
                {
                    icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
                    titulo: 'Se requiere permitir acceso al GPS',
                    descripcion: 'Para continuar es necesario que permitas encontrar tu ubicación actual.',
                    timeout: 5000
                }
            )
            return false
        }
    } else if (opt === 2) {
        // Validamos si subirá documentos
        let formData = new FormData()
        let canUpload = false
        arrSolicitud.map((form, index) => {
            if (form.type == 'file' && typeof form.file !== 'undefined') {
                formData.append(form.value, form.file)
                canUpload = true
            }
        })

        if ([...formData].length > 0) {
            const url0 = `clientes/uploadSolicitudDeCredito`
            const data0 = formData
            HTTP_UPLOAD(url0, data0, response => {
                registrarSolicitudCredito(state, setState, _this, 3, coordinates)
            })
        } else {
            registrarSolicitudCredito(state, setState, _this, 3, coordinates)
        }
    } else if (opt === 3) {
        // Enviamos el formulario
        const url = `clientes/registrarSolicitudDeCredito`
        const data = { solicitud: arrSolicitud, id_cliente, coordinates }
        HTTP_POST(url, data, response => {
            if (typeof response.error !== 'undefined') {
                mostrarNotificacion(
                    state, setState, _this,
                    {
                        icono: <ShieldCheckIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
                        titulo: 'Aviso',
                        descripcion: 'Ha ocurrido un error',
                        timeout: 5000
                    }
                )
                return false;
            }

            if (!response.existsFirma)
                navigate(`/clientes/${id_cliente}/firmar`)
            else
                navigate(`/clientes/${id_cliente}/enProceso`)
        })
    }


}