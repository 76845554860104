import React, { Fragment } from "react";
import { StatePos } from "../States"
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import moment from 'moment'
import * as F from '../PoS/pos.functions';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

import {
	ChevronDownIcon,
	PencilSquareIcon,
	NoSymbolIcon,
	KeyIcon
} from '@heroicons/react/20/solid';

const user = {
	name: 'Prueba',
	email: 'tom@example.com',
	imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
	{ name: 'Progreso', href: '#', current: true },
	{ name: 'Por autorizar', href: '#', current: false },
	{ name: 'Configuraciones', href: '#', current: false }
]
const userNavigation = [
	{ name: 'Ajustes', href: '#' },
	{ name: 'Cerrar sesión', href: '/logout' },
]

const tabs = [
	{ name: 'Sucursales', href: '#', current: true },
	{ name: 'Supervisores', href: '#', current: false },
	{ name: 'Vendedores', href: '#', current: false }
]

const locations = [
	{
		sucursal: 'Valles',
		usuarios: [
			{ nombre: 'Emmanuel Domínguez Izaguirre', kg_vendidos: '100 kg', total_vendido: '$5,000', total_cobrado: '$5,000.00' },
			{ nombre: 'Eleuterio Aquino Flores', kg_vendidos: '200 kg', total_vendido: '$10,000.00', total_cobrado: '$10,000.00' },
		],
	},
	{
		sucursal: 'Tamazunchale',
		usuarios: [
			{ nombre: 'Baldomero Vidales Mateos', kg_vendidos: '100 kg', total_vendido: '$5,000', total_cobrado: '$5,000.00' },
			{ nombre: 'Rigoberto Trejo Larraga', kg_vendidos: '200 kg', total_vendido: '$10,000.00', total_cobrado: '$10,000.00' },
		],
	},
	// More people...
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		F.initData(this);
	}

	render() {
		return (
			<>
				{/*
					This example requires updating your template:
	
					```
					<html className="h-full">
					<body className="h-full">
					```
				*/}
				<div className="min-h-full">
					<Disclosure as="nav" className="border-b border-gray-200 bg-white">
						{({ open }) => (
							<>
								<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
									<div className="flex h-16 justify-between">
										<div className="flex">
											<div className="flex flex-shrink-0 items-center">
												<img
													className="block h-8 w-auto lg:hidden"
													src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
													alt="Your Company"
												/>
												<img
													className="hidden h-8 w-auto lg:block"
													src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
													alt="Your Company"
												/>
											</div>
											<div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
												{navigation.map((item) => (
													<a
														key={item.name}
														href={item.href}
														className={classNames(
															item.current
																? 'border-indigo-500 text-gray-900'
																: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
															'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
														)}
														aria-current={item.current ? 'page' : undefined}
													>
														{item.name}
													</a>
												))}
											</div>
										</div>
										<div className="hidden sm:ml-6 sm:flex sm:items-center">
											<button
												type="button"
												className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											>
												<span className="absolute -inset-1.5" />
												<span className="sr-only">View notifications</span>
												<BellIcon className="h-6 w-6" aria-hidden="true" />
											</button>

											{/* Profile dropdown */}
											<Menu as="div" className="relative ml-3">
												<div>
													<Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
														<span className="absolute -inset-1.5" />
														<span className="sr-only">Open user menu</span>
														<img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-200"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
														{userNavigation.map((item) => (
															<Menu.Item key={item.name}>
																{({ active }) => (
																	<a
																		href={item.href}
																		className={classNames(
																			active ? 'bg-gray-100' : '',
																			'block px-4 py-2 text-sm text-gray-700'
																		)}
																	>
																		{item.name}
																	</a>
																)}
															</Menu.Item>
														))}
													</Menu.Items>
												</Transition>
											</Menu>
										</div>
										<div className="-mr-2 flex items-center sm:hidden">
											{/* Mobile menu button */}
											<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
												<span className="absolute -inset-0.5" />
												<span className="sr-only">Open main menu</span>
												{open ? (
													<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
												) : (
													<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
												)}
											</Disclosure.Button>
										</div>
									</div>
								</div>

								<Disclosure.Panel className="sm:hidden">
									<div className="space-y-1 pb-3 pt-2">
										{navigation.map((item) => (
											<Disclosure.Button
												key={item.name}
												as="a"
												href={item.href}
												className={classNames(
													item.current
														? 'border-indigo-500 bg-indigo-50 text-indigo-700'
														: 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
													'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</Disclosure.Button>
										))}
									</div>
									<div className="border-t border-gray-200 pb-3 pt-4">
										<div className="flex items-center px-4">
											<div className="flex-shrink-0">
												<img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
											</div>
											<div className="ml-3">
												<div className="text-base font-medium text-gray-800">{user.name}</div>
												<div className="text-sm font-medium text-gray-500">{user.email}</div>
											</div>
											<button
												type="button"
												className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											>
												<span className="absolute -inset-1.5" />
												<span className="sr-only">View notifications</span>
												<BellIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
										<div className="mt-3 space-y-1">
											{userNavigation.map((item) => (
												<Disclosure.Button
													key={item.name}
													as="a"
													href={item.href}
													className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
												>
													{item.name}
												</Disclosure.Button>
											))}
										</div>
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>

					<div className="py-10">
						<header>
							<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
								<h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Santori SA  De CV</h1>
							</div>
						</header>
						<main>
							<div className="mx-auto max-w-7xl sm:px-6 lg:px-8">

								{/* TABS */}
								<div className="pt-10">
									<div className="sm:hidden">
										<label htmlFor="tabs" className="sr-only">
											Select a tab
										</label>
										{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
										<select
											id="tabs"
											name="tabs"
											className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
											defaultValue={tabs.find((tab) => tab.current).name}
										>
											{tabs.map((tab) => (
												<option key={tab.name}>{tab.name}</option>
											))}
										</select>
									</div>
									<div className="hidden sm:block">
										<nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
											{tabs.map((tab, tabIdx) => (
												<a
													key={tab.name}
													href={tab.href}
													className={classNames(
														tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
														tabIdx === 0 ? 'rounded-l-lg' : '',
														tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
														'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
													)}
													aria-current={tab.current ? 'page' : undefined}
												>
													<span>{tab.name}</span>
													<span
														aria-hidden="true"
														className={classNames(
															tab.current ? 'bg-indigo-500' : 'bg-transparent',
															'absolute inset-x-0 bottom-0 h-0.5'
														)}
													/>
												</a>
											))}
										</nav>
									</div>
								</div>
								{/* TABLA DE BARRA DE PROGRESO */}
								<section className="py-8">
									<div className="container px-4 mx-auto">
										<div className="py-4 bg-white rounded">
											<div className="px-6 pb-6 border-b border-blue-50">
												<h3 className="text-xl font-bold">Sucursales</h3>
												<p className="text-sm text-gray-500">Mide el progreso de cada sucursal</p>
											</div>
											<div class="space-y-2.5 border-t border-stroke p-4 dark:border-strokedark md:p-6 xl:p-7.5">
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[35%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															35%
														</div>
													</div>
												</div>
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[26%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															26%
														</div>
													</div>
												</div>
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[18%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															18%
														</div>
													</div>
												</div>
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[14%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															14%
														</div>
													</div>
												</div>
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[10%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															10%
														</div>
													</div>
												</div>
												<div class="items-center sm:flex">
													<div class="flex w-[35%] items-center gap-2">
														<p class="font-medium text-black dark:text-white">Ciudad Valles</p>
													</div>
													<div class="relative block h-4 w-full rounded bg-meta-9 dark:bg-meta-4 h-6 bg-gray-100">
														<div class="absolute left-0 top-0 flex h-full w-[7%] items-center justify-center rounded bg-primary text-xs font-medium text-white bg-green-800">
															7%
														</div>
													</div>
												</div>
											</div>
											<div className="p-6 border-b border-blue-50">
												<div className="flex -mx-4">
													<div className="flex items-center w-1/2 px-4">
														<img className="mr-2 h-6" src="artemis-assets/mini-logos/instagram.svg" alt="" />
														<p className="text-sm font-medium">Valles</p>
													</div>
													<div className="w-1/2 px-4">
														<p className="mb-1 text-xs text-emerald-500 font-medium">90%</p>
														<div className="flex">
															<div className="relative h-1 w-full bg-emerald-50 rounded-full">
																<div className="absolute top-0 left-0 h-full w-10/12 bg-emerald-500 rounded-full"></div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="p-6 border-b border-blue-50">
												<div className="flex -mx-4">
													<div className="flex items-center w-1/2 px-4">
														<img className="mr-2 h-6" src="artemis-assets/mini-logos/twitter.svg" alt="" />
														<p className="text-sm font-medium">Tampico</p>
													</div>
													<div className="w-1/2 px-4">
														<p className="mb-1 text-xs text-emerald-500 font-medium">82%</p>
														<div className="flex">
															<div className="relative h-1 w-full bg-emerald-50 rounded-full">
																<div className="absolute top-0 left-0 h-full w-9/12 bg-emerald-500 rounded-full"></div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="p-6 border-b border-blue-50">
												<div className="flex -mx-4">
													<div className="flex items-center w-1/2 px-4">
														<img className="mr-2 h-6" src="artemis-assets/mini-logos/twitter.svg" alt="" />
														<p className="text-sm font-medium">Tamazunchale</p>
													</div>
													<div className="w-1/2 px-4">
														<p className="mb-1 text-xs text-emerald-500 font-medium">98%</p>
														<div className="flex">
															<div className="relative h-1 w-full bg-emerald-50 rounded-full">
																<div className="absolute top-0 left-0 h-full w-11/12 bg-emerald-500 rounded-full"></div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</section>
								{/* TABLA AGRUPADA DE DATOS */}
								<div className="px-4 sm:px-6 lg:px-8">
									<div className="sm:flex sm:items-center">
										<div className="sm:flex-auto">
											{/* <h1 className="text-base font-semibold leading-6 text-gray-900">Usuarios</h1> */}
											<h3 className="text-xl font-bold">Usuarios</h3>
											<p className="mt-2 text-sm text-gray-500">
												Lista de usuarios por sucursales
											</p>
										</div>
										<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
											<button
												type="button"
												className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
											>
												Agregar usuario
											</button>
										</div>
									</div>
									<div className="mt-8 flow-root">
										<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
											<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
												<table className="min-w-full">
													<thead className="bg-white">
														<tr>
															<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
																Sucursal / Usuarios
															</th>
															<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																Total KG vendidos
															</th>
															<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																Total vendido
															</th>
															<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
																Total cobrado
															</th>

															<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
																<span className="sr-only">Editar</span>
															</th>
														</tr>
													</thead>
													<tbody className="bg-white">
														{locations.map((location) => (
															<Fragment key={location.sucursal}>
																<tr className="border-t border-gray-200">
																	<th
																		colSpan={5}
																		scope="colgroup"
																		className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
																	>
																		{location.sucursal}
																	</th>
																</tr>
																{location.usuarios.map((person, personIdx) => (
																	<tr
																		key={person.personIdx}
																		className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
																	>
																		<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
																			{person.nombre}
																		</td>
																		<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.kg_vendidos}</td>
																		<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.total_vendido}</td>
																		<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.total_cobrado}</td>
																		<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">

																			<a href="#" className="text-indigo-600 hover:text-indigo-900">
																				Editar<span className="sr-only">, {person.name}</span>
																			</a>

																			{/* <Menu as="div" className="relative inline-block text-left">
																				<div>
																					<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
																						Opciones
																						<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
																					</Menu.Button>
																				</div>

																				<Transition
																					as={Fragment}
																					enter="transition ease-out duration-100"
																					enterFrom="transform opacity-0 scale-95"
																					enterTo="transform opacity-100 scale-100"
																					leave="transition ease-in duration-75"
																					leaveFrom="transform opacity-100 scale-100"
																					leaveTo="transform opacity-0 scale-95"
																				>
																					<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																						<div className="py-1">
																							<Menu.Item>
																								{({ active }) => (
																									<a
																										href="#"
																										className={classNames(
																											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																											'group flex items-center px-4 py-2 text-sm'
																										)}
																									>
																										<KeyIcon
																											className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
																											aria-hidden="true"
																										/>
																										Cambiar contraseña
																									</a>
																								)}
																							</Menu.Item>
																							<Menu.Item>
																								{({ active }) => (
																									<a
																										href="#"
																										className={classNames(
																											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																											'group flex items-center px-4 py-2 text-sm'
																										)}
																									>
																										<NoSymbolIcon
																											className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
																											aria-hidden="true"
																										/>
																										Suspender cuenta
																									</a>
																								)}
																							</Menu.Item>
																							<Menu.Item>
																								{({ active }) => (
																									<a
																										href="#"
																										className={classNames(
																											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																											'group flex items-center px-4 py-2 text-sm'
																										)}
																									>
																										<PencilSquareIcon
																											className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
																											aria-hidden="true"
																										/>
																										Editar meta
																									</a>
																								)}
																							</Menu.Item>
																						</div>
																					</Menu.Items>
																				</Transition>
																			</Menu> */}
																		</td>
																	</tr>
																))}
															</Fragment>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

							</div>
						</main>
					</div>
				</div>
			</>
		)
	}
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

export default withRouter(Admin)