import * as C from './clientes.components';

export const render__fichaCliente = (state, setState, _this) => {
    return (
        <C.Dashboard padding={'px-4 sm:px-0 lg:px-0'} paddingMain={'mt-10 py-10'} _this={_this}>
            <C.StatsWidgets state={state} setState={setState} _this={_this} />
            <C.Notificacion state={state} setState={setState} _this={_this} />
        </C.Dashboard>
    )
}

export const render__firmar = (state, setState, _this) => {
    return (
        <C.Dashboard padding={'px-4 sm:px-0 lg:px-0'} paddingMain={'mt-10 py-10'} _this={_this}>
            <C.FirmarSolicitudCredito state={state} setState={setState} _this={_this} />
            <C.Notificacion state={state} setState={setState} _this={_this} />
        </C.Dashboard>
    )
}

export const render__enProceso = (state, setState, _this) => {
    return (
        <C.Dashboard padding={'px-4 sm:px-0 lg:px-0'} paddingMain={'mt-10 py-10'} _this={_this}>
            <C.SolicitudEnProceso state={state} setState={setState} _this={_this} />
            <C.Notificacion state={state} setState={setState} _this={_this} />
        </C.Dashboard>
    )
}