import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import * as F from './cont.functions';
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
	Bars3Icon,
	BellIcon,
	CalendarIcon,
	Cog6ToothIcon,
	FolderIcon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
	ExclamationTriangleIcon,
	AcademicCapIcon,
	BanknotesIcon,
	ReceiptRefundIcon,
	UserCircleIcon,
	ScaleIcon
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, BuildingOfficeIcon, CreditCardIcon, HandThumbUpIcon, ArrowRightIcon, CheckIcon } from '@heroicons/react/20/solid'
import moment from 'moment'

const userNavigation = [
	{ name: 'Perfil', href: '#' },
	{ name: 'Cerrar sesión', href: '/logout' },
]

// const tabs = [
//   { name: 'My Account', href: '#', current: false },
//   { name: 'Company', href: '#', current: false },
//   { name: 'Team Members', href: '#', current: true },
//   { name: 'Billing', href: '#', current: false },
// ]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			session: {},
			sidebarOpen: false,
			navigation: [
				{ name: 'Inicio', href: '#', icon: HomeIcon, current: true, to: '/contabilidad' },
			]
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);
		const { _this } = this.props;
		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			}, () => {
				let rutaActual = _this.props.router.location;
				const { navigation } = this.state;
				let newNav = navigation.map(item => ({
					...item,
					current: item.to === rutaActual.pathname ? true : false,
				}));
				this.setState({ navigation: newNav })
			})
		}
	}

	setSideBarOpen = (status) => this.setState({ sidebarOpen: status })

	render() {
		const { sidebarOpen, navigation } = this.state;
		const { children } = this.props
		const { session } = this.state;

		return (
			<>
				<div className={'dark:bg-slate-900'}>
					<Transition.Root show={sidebarOpen} as={Fragment}>
						<Dialog as="div" className="relative z-50 lg:hidden" onClose={() => this.setSideBarOpen(false)}>
							<Transition.Child
								as={Fragment}
								enter="transition-opacity ease-linear duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity ease-linear duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="fixed inset-0 bg-gray-900/80" />
							</Transition.Child>

							<div className="fixed inset-0 flex">
								<Transition.Child
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transition ease-in-out duration-300 transform"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
										<Transition.Child
											as={Fragment}
											enter="ease-in-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in-out duration-300"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
												<button type="button" className="-m-2.5 p-2.5" onClick={() => this.setSideBarOpen(false)}>
													<span className="sr-only">Close sidebar</span>
													<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
												</button>
											</div>
										</Transition.Child>
										{/* Sidebar component, swap this element with another sidebar if you like */}
										<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
											<div className="flex h-16 shrink-0 items-center">
												<img
													className="h-8 w-auto"
													src="https://app.santori.com.mx/img/logo-santori.png"
													alt="Santori SA De CV"
												/>
											</div>
											<nav className="flex flex-1 flex-col">
												<ul role="list" className="flex flex-1 flex-col gap-y-7">
													<li>
														<ul role="list" className="-mx-2 space-y-1">
															{navigation.map((item) => (
																<li key={item.name}>

																	<NavLink
																		to={item.to}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-red-600 dark:text-red-900'
																				: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden="true"
																		/>
																		{item.name}
																	</NavLink>
																	{/* <a
																		href={item.href}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-red-600 dark:text-red-900'
																				: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden="true"
																		/>
																		{item.name}
																	</a> */}
																</li>
															))}
														</ul>
													</li>

													<li className="mt-auto">
														<a
															href="#"
															className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
														>
															<Cog6ToothIcon
																className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
																aria-hidden="true"
															/>
															Configuraciones
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition.Root>

					{/* Static sidebar for desktop */}
					<div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 dark:bg-slate-800 dark:border-gray-900">
							<div className="flex h-16 shrink-0 items-center">
								<img
									className="h-8 w-auto"
									src="https://app.santori.com.mx/img/logo-santori.png"
									alt="Santori SA De CV"
								/>
							</div>
							<nav className="flex flex-1 flex-col">
								<ul role="list" className="flex flex-1 flex-col gap-y-7">
									<li>
										<ul role="list" className="-mx-2 space-y-1">
											{navigation.map((item) => (
												<li key={item.name}>
													<NavLink
														to={item.to}
														className={classNames(
															item.current
																? 'bg-gray-50 text-red-600 dark:text-red-900'
																: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
														)}
													>
														<item.icon
															className={classNames(
																item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</NavLink>
													{/* <a
														href={item.href}
														className={classNames(
															item.current
																? 'bg-gray-50 text-red-600 dark:text-red-900'
																: 'text-gray-700 hover:text-red-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-slate-500'
														)}
													>
														<item.icon
															className={classNames(
																item.current ? 'text-red-600' : 'text-gray-400 group-hover:text-red-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden="true"
														/>
														{item.name}
													</a> */}
												</li>
											))}
										</ul>
									</li>
									<li className="mt-auto">
										<a
											href="#"
											className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600 dark:text-slate-500"
										>
											<Cog6ToothIcon
												className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
												aria-hidden="true"
											/>
											Configuraciones
										</a>
									</li>
								</ul>
							</nav>
						</div>
					</div>

					<div className="lg:pl-72">
						<div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 dark:bg-slate-800 dark:border-gray-800">
							<button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => this.setSideBarOpen(true)}>
								<span className="sr-only">Abrir menú</span>
								<Bars3Icon className="h-6 w-6" aria-hidden="true" />
							</button>

							{/* Separator */}
							<div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

							<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
								<div className="relative flex flex-1"></div>
								<div className="flex items-center gap-x-4 lg:gap-x-6">
									<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</button>

									{/* Separator */}
									<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

									{/* Profile dropdown */}
									<Menu as="div" className="relative">
										<Menu.Button className="-m-1.5 flex items-center p-1.5">
											<span className="sr-only">Abrir menú de usuario</span>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 h-8 w-8 dark:text-white"><path stroke-linecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
											<span className="hidden lg:flex lg:items-center">
												<span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200" aria-hidden="true">
													{session.nombre} {session.apellidos}
												</span>
												<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
											</span>
										</Menu.Button>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-gray-900">
												{userNavigation.map((item) => (
													<Menu.Item key={item.name}>
														{({ active }) => (
															<a
																href={item.href}
																className={classNames(
																	active ? 'bg-gray-50 dark:bg-gray-500' : '',
																	'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-200'
																)}
															>
																{item.name}
															</a>
														)}
													</Menu.Item>
												))}
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>
						</div>

						<main className={this.props.paddingMain}>
							<div className={this.props.padding}>
								{children}
							</div>
						</main>
					</div>
				</div>
			</>
		)
	}
}

export class Saludo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			session: {},
		}
	}

	componentDidMount() {
		let auth = (typeof localStorage.ws_santori_auth !== 'undefined' ? localStorage.ws_santori_auth : false);

		if (auth) {
			let session = JSON.parse(localStorage.ws_santori_auth)
			this.setState({
				session
			})
		}

	}

	render() {
		const { session } = this.state;
		let hourOfDay = moment().format('H')

		let bienvenida = ''
		if (hourOfDay < 12)
			bienvenida = 'Buenos días'
		else if (hourOfDay >= 12 && hourOfDay < 19)
			bienvenida = 'Buenas tardes'
		else if (hourOfDay >= 19 && hourOfDay <= 23)
			bienvenida = 'Buenas noches'

		return (
			<div className="mb-3">
				<h1 className="text-lg font-semibold dark:text-gray-300">{bienvenida}, {session.nombre} {session.apellidos}</h1>
				<hr className="mt-4 mb-4" />
			</div>
		)
	}
}

export class HistorialVentas extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {

		const { state, setState, _this } = this.props;
		const { arrHistorialOrdenesVenta, arrSucursales, id_sucursal, fecha, } = state;

		return (
			<React.Fragment>
				<form className="bg-white p-5 rounded-md shadow-sm mb-3">
					<div className="mb-3">
						<label htmlFor="input_historial_pago" className="block text-sm font-medium leading-6 text-gray-900">
							Fecha
						</label>
						<div className="mt-2">
							<input
								type="date"
								name="input_historial_pago"
								id="input_historial_pago"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								placeholder=""
								value={fecha}
								onChange={(e) => {
									setState({
										fecha: e.target.value
									})
								}}
							/>
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="select_sucursales" className="block text-sm font-medium leading-6 text-gray-900">
							Sucursales
						</label>
						<select
							id="select_sucursales"
							name="select_sucursales"
							className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
							value={id_sucursal}
							onChange={(e) => {
								setState({
									id_sucursal: e.target.value
								})
							}}
						>
							<option value="" selected disabled>Selecciona una sucursal</option>
							{
								arrSucursales.map((t, e) => (
									<option value={t.id_sucursal} selected>{t.nombre}</option>
								))
							}
						</select>
					</div>
					<button
						onClick={() => { F.getHistorialOrdenesVentas(state, setState, _this) }}
						type="button"
						className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Buscar
					</button>
				</form>
				<h3 className="text-base font-semibold leading-6 text-gray-900 mb-3">Historial de ventas</h3>
				<div className="w-full overflow-x-auto shadow-md sm:rounded-lg mt-5" style={{ minHeight: '250px' }}>
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
						<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
							<tr>
								<th scope="col" className="px-6 py-3">
									Nombre del cliente
								</th>
								<th scope="col" className="px-6 py-3">
									¿Fué autorizada?
								</th>
								<th scope="col" className="px-6 py-3">
									Total a pagar
								</th>
								<th scope="col" className="px-6 py-3">
									Total abonado
								</th>
								<th scope="col" className="px-6 py-3">
									Por pagar
								</th>
								<th scope="col" className="px-6 py-3">
									Pagos cotejados
								</th>
								<th scope="col" className="px-6 py-3">
									Fecha venta
								</th>
								<th scope="col" className="px-6 py-3">
									Facturado
								</th>
								<th scope="col" className="px-6 py-3">
									Opciones
								</th>
							</tr>
						</thead>
						<tbody>
							{
								arrHistorialOrdenesVenta.length > 0 ?
									arrHistorialOrdenesVenta.map((t, e) => (
										<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
											<th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
												{t.nombre_completo}
											</th>
											<td className="px-6 py-4">
												{t.requiere_auth == "0" ? "Si" : "No"}
											</td>
											<td className="px-6 py-4">
												${F.number_format(t.sum_total_pagar, 2)}
											</td>
											<td className="px-6 py-4">
												${F.number_format(t.sum_total_cantidad, 2)}
											</td>
											<td className="px-6 py-4">
												${F.number_format(parseFloat(t.sum_total_pagar) - parseFloat(t.sum_total_cantidad), 2)}
											</td>
											<td className="px-6 py-4">
												{t.count_cotejado} de {t.count_pagos}
											</td >
											<td className="px-6 py-4">
												{moment(t.created_at).format('DD/MM/YYYY HH:mm')}
											</td>
											<td class="items-center px-6 py-4">
												{
													t.url_pdf == '' ?
														<p>Sin facturar</p>
														:
														<div className="relative">
															<Menu as="div" className="relative inline-block text-left">
																<div>
																	<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
																		Ver
																		<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
																	</Menu.Button>
																</div>

																<Transition
																	as={Fragment}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95"
																>
																	<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																		<div className="py-1">
																			<Menu.Item>
																				{({ active }) => (
																					<a
																						href={t.url_pdf}
																						target="_blank"
																						className={classNames(
																							active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																							'block px-4 py-2 text-sm'
																						)}
																						onClick={() => {

																						}}
																					>
																						Descargar PDF
																					</a>
																				)}
																			</Menu.Item>
																			<Menu.Item>
																				{({ active }) => (
																					<a
																						href={t.url_xml}
																						target="_blank"
																						className={classNames(
																							active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																							'block px-4 py-2 text-sm'
																						)}
																						onClick={() => {

																						}}
																					>
																						Descargar XML
																					</a>
																				)}
																			</Menu.Item>
																		</div>
																	</Menu.Items>
																</Transition>
															</Menu>
														</div>
												}

											</td>
											<td class="flex items-center px-6 py-4">
												{
													t.url_pdf == '' &&
													<div className="relative">
														<Menu as="div" className="relative inline-block text-left">
															<div>
																<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
																	Opciones
																	<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
																</Menu.Button>
															</div>

															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	<div className="py-1">
																		<Menu.Item>
																			{({ active }) => (
																				<a
																					href="#"
																					className={classNames(
																						active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																						'block px-4 py-2 text-sm'
																					)}
																					onClick={() => {
																						F.obtenerDireccionesFiscales(state, setState, _this, t.id_cliente)
																						setState(prevState => ({
																							openSliderFacturarVenta: true,
																							inputsFacturarVenta: {
																								...prevState.inputsFacturarVenta,
																								id_orden: t.id_orden,
																								id_cliente: t.id_cliente
																							}
																						}))
																					}}
																				>
																					Facturar venta
																				</a>
																			)}
																		</Menu.Item>
																	</div>
																</Menu.Items>
															</Transition>
														</Menu>
													</div>
												}
											</td>

										</tr >
									))
									:
									<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
										<th colSpan={9} className="text-center p-4">No hay resultados</th>
									</tr>
							}
						</tbody>
					</table >
				</div >


			</React.Fragment >
		)
	}
}

export class SlideOverFacturarVenta extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabs: [
				{ id: '1', name: 'Facturar venta', href: '#', current: true },
				{ id: '2', name: 'Factura libre', href: '#', current: false }
			],
			formas_pago: [
				{ "name": "Efectivo", "value": "01" },
				{ "name": "Cheque nominativo", "value": "02" },
				{ "name": "Transferencia electrónica de fondos", "value": "03" },
				{ "name": "Tarjeta de crédito", "value": "04" },
				{ "name": "Monedero electrónico", "value": "05" },
				{ "name": "Dinero electrónico", "value": "06" },
				{ "name": "Vales de despensa", "value": "08" },
				{ "name": "Dación en pago", "value": "12" },
				{ "name": "Pago por subrogación", "value": "13" },
				{ "name": "Pago por consignación", "value": "14" },
				{ "name": "Condonación", "value": "15" },
				{ "name": "Compensación", "value": "17" },
				{ "name": "Novación", "value": "23" },
				{ "name": "Confusión", "value": "24" },
				{ "name": "Remisión de deuda", "value": "25" },
				{ "name": "Prescripción o caducidad", "value": "26" },
				{ "name": "A satisfacción del acreedor", "value": "27" },
				{ "name": "Tarjeta de débito", "value": "28" },
				{ "name": "Tarjeta de servicios", "value": "29" },
				{ "name": "Aplicación de anticipos", "value": "30" },
				{ "name": "Intermediarios", "value": "31" },
				{ "name": "Por definir", "value": "99" }
			],
			metodos_pago: [
				{ "name": "Pago en parcialidades ó diferido", "value": "PPD" },
				{ "name": "Pago en una sola exhibición", "value": "PUE" }
			],
			arrRegimenFiscal: [
				{ "name": "General de Ley Personas Morales", "value": "601" },
				{ "name": "Personas Morales con Fines no Lucrativos", "value": "603" },
				{ "name": "Sueldos y Salarios e Ingresos Asimilados a Salarios", "value": "605" },
				{ "name": "Arrendamiento", "value": "606" },
				{ "name": "Régimen de Enajenación o Adquisición de Bienes", "value": "607" },
				{ "name": "Demás ingresos", "value": "608" },
				{ "name": "Residentes en el Extranjero sin Establecimiento Permanente en México", "value": "610" },
				{ "name": "Ingresos por Dividendos (socios y accionistas)", "value": "611" },
				{ "name": "Personas Físicas con Actividades Empresariales y Profesionales", "value": "612" },
				{ "name": "Ingresos por intereses", "value": "614" },
				{ "name": "Régimen de los ingresos por obtención de premios", "value": "615" },
				{ "name": "Sin obligaciones fiscales", "value": "616" },
				{ "name": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", "value": "620" },
				{ "name": "Incorporación Fiscal", "value": "621" },
				{ "name": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", "value": "622" },
				{ "name": "Opcional para Grupos de Sociedades", "value": "623" },
				{ "name": "Coordinados", "value": "624" },
				{ "name": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas", "value": "625" },
				{ "name": "Régimen Simplificado de Confianza", "value": "626" }
			],
			arrUsoCFDI: [
				{ "Natural": true, "Moral": false, "name": "Nómina", "value": "CN01" },
				{ "Natural": true, "Moral": true, "name": "Pagos", "value": "CP01" },
				{ "Natural": true, "Moral": false, "name": "Honorarios médicos, dentales y gastos hospitalarios.", "value": "D01" },
				{ "Natural": true, "Moral": false, "name": "Gastos médicos por incapacidad o discapacidad", "value": "D02" },
				{ "Natural": true, "Moral": false, "name": "Gastos funerales.", "value": "D03" },
				{ "Natural": true, "Moral": false, "name": "Donativos.", "value": "D04" },
				{ "Natural": true, "Moral": false, "name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).", "value": "D05" },
				{ "Natural": true, "Moral": false, "name": "Aportaciones voluntarias al SAR.", "value": "D06" },
				{ "Natural": true, "Moral": false, "name": "Primas por seguros de gastos médicos.", "value": "D07" },
				{ "Natural": true, "Moral": false, "name": "Gastos de transportación escolar obligatoria.", "value": "D08" },
				{ "Natural": true, "Moral": false, "name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.", "value": "D09" },
				{ "Natural": true, "Moral": false, "name": "Pagos por servicios educativos (colegiaturas)", "value": "D10" },
				{ "Natural": true, "Moral": true, "name": "Adquisición de mercancias", "value": "G01" },
				{ "Natural": true, "Moral": true, "name": "Devoluciones, descuentos o bonificaciones", "value": "G02" },
				{ "Natural": true, "Moral": true, "name": "Gastos en general", "value": "G03" },
				{ "Natural": true, "Moral": true, "name": "Construcciones", "value": "I01" },
				{ "Natural": true, "Moral": true, "name": "Mobilario y equipo de oficina por inversiones", "value": "I02" },
				{ "Natural": true, "Moral": true, "name": "Equipo de transporte", "value": "I03" },
				{ "Natural": true, "Moral": true, "name": "Equipo de computo y accesorios", "value": "I04" },
				{ "Natural": true, "Moral": true, "name": "Dados, troqueles, moldes, matrices y herramental", "value": "I05" },
				{ "Natural": true, "Moral": true, "name": "Comunicaciones telefónicas", "value": "I06" },
				{ "Natural": true, "Moral": true, "name": "Comunicaciones satelitales", "value": "I07" },
				{ "Natural": true, "Moral": true, "name": "Otra maquinaria y equipo", "value": "I08" },
				{ "Natural": true, "Moral": true, "name": "Por Definir", "value": "P01" },
				{ "Natural": true, "Moral": true, "name": "Sin efectos fiscales", "value": "S01" }
			],
			selectedTab: '1'
		}
	}

	convertRegimenFiscal(regimen_fiscal) {
		const { arrRegimenFiscal } = this.state;
		let nameRegimenFiscal = arrRegimenFiscal.find(item => item.value === regimen_fiscal)
		return nameRegimenFiscal?.name
	}

	render() {

		const { state, setState, _this, } = this.props
		const { openSliderFacturarVenta, inputsFacturarVenta, arrDireccionesFiscales } = state
		const { tabs, selectedTab, formas_pago, metodos_pago, arrRegimenFiscal, arrUsoCFDI, } = this.state;

		if (typeof openSliderFacturarVenta === 'undefined')
			return null

		return (
			<Transition.Root show={openSliderFacturarVenta} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => F.handleAccionarSlider(state, setState, _this, "openSliderFacturarVenta", false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
										<form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
											<div className="flex-1">
												{/* Header */}
												<div className="bg-gray-50 px-4 py-6 sm:px-6">
													<div className="flex items-start justify-between space-x-3">
														<div className="space-y-1">
															<Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
																Facturar venta
															</Dialog.Title>
															<p className="text-sm text-gray-500">
																Completa el siguiente formulario para facturar una venta.
															</p>
														</div>
														<div className="flex h-7 items-center">
															<button
																type="button"
																className="relative text-gray-400 hover:text-gray-500"
																onClick={() => F.handleAccionarSlider(state, setState, _this, "openSliderFacturarVenta", false)}
															>
																<span className="absolute -inset-2.5" />
																<span className="sr-only">Cerrar panel</span>
																<XMarkIcon className="h-6 w-6" aria-hidden="true" />
															</button>
														</div>
													</div>
												</div>

												{/* Divider container */}
												<div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

													{/* Aquí van los subcomponentes */}
													<div>
														<div className="sm:hidden  px-3">
															<label htmlFor="tabs" className="sr-only">
																Select a tab
															</label>
															{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
															<select
																id="tabs"
																name="tabs"
																className="px-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
																value={selectedTab}
																onChange={(e) => {
																	let newTab = tabs.map(item => ({
																		...item,
																		current: item.id === e.target.value ? true : false,
																	}));
																	this.setState({
																		tabs: newTab,
																		selectedTab: e.target.value
																	})
																}}
															>
																{tabs.map((tab) => (
																	<option key={tab.id} value={tab.id}>{tab.name}</option>
																))}
															</select>
														</div>
														<div className="hidden sm:block">
															<div className="border-b border-gray-200">
																<nav className="-mb-px flex space-x-8" aria-label="Tabs">
																	{tabs.map((tab) => (
																		<a
																			key={tab.name}
																			href={tab.href}
																			className={classNames(
																				tab.current
																					? 'border-red-500 text-red-600'
																					: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
																				'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
																				tab.id == "1" ? 'pl-4' : ''
																			)}
																			aria-current={tab.current ? 'page' : undefined}
																			onClick={() => {
																				let newTab = tabs.map(item => ({
																					...item,
																					current: item.id === tab.id ? true : false,
																				}));

																				inputsFacturarVenta.id_razon = tab.id === '1' ? inputsFacturarVenta.id_razon : ''

																				this.setState({
																					tabs: newTab,
																					selectedTab: tab.id,
																				})
																				setState({ inputsFacturarVenta, })
																			}}
																		>
																			{tab.name}
																		</a>
																	))}
																</nav>
															</div>
														</div>
													</div>

													{

														selectedTab === "1" && (
															<>

																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="direccion_fiscal"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Direcciones fiscales
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.selectRazonSocial(state, setState, _this, e.target.value) }}
																			id="direccion_fiscal"
																			name="direccion_fiscal"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																		>
																			{
																				[{ id_razon: '', rfc: 'Elige una opción', }, ...arrDireccionesFiscales].map((t, e) => (
																					<option value={t.id_razon}>{t.id_razon === '' ? 'Elige una opción' : `${t.rfc}, ${t.razon_social}, ${t.calle}, ${t.colonia}, ${t.no_exterior}, ${t.cp}, ${t.municipio}, ${t.estado}, ${this.convertRegimenFiscal(t.regimen_fiscal)}`}</option>
																				))
																			}
																		</select>
																	</div>
																</div>

																{
																	inputsFacturarVenta.id_razon !== "" && (
																		<>
																			{
																				/*
																				
																			<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																				<div>
																					<label
																						htmlFor="tipo_comprobante_fiscal"
																						className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																					>
																						Tipo de comprobante fiscal
																					</label>
																				</div>
																				<div className="sm:col-span-2">
																					<select
																						onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'tipo_comprobante_fiscal', e.target.value) }}
																						id="tipo_comprobante_fiscal"
																						name="tipo_comprobante_fiscal"
																						className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																						value={inputsFacturarVenta.tipo_comprobante_fiscal}
																					>
																						<option value="I">Ingreso</option>
																						<option value="E">Egreso</option>
																						<option value="T">Traslado</option>
																						<option value="N">Investigar</option>
																						<option value="P">Investigar</option>
																					</select>
																				</div>
																			</div>
																			*/
																			}
																			<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																				<div>
																					<label
																						htmlFor="uso_cfdi"
																						className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																					>
																						Uso de CFDI
																					</label>
																				</div>
																				<div className="sm:col-span-2">
																					<select
																						onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'uso_cfdi', e.target.value) }}
																						id="uso_cfdi"
																						name="uso_cfdi"
																						className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																						required
																						value={inputsFacturarVenta.uso_cfdi}
																					>
																						{
																							arrUsoCFDI.map((t, e) => (
																								<option value={t.value}>{t.value} - {t.name}</option>
																							))
																						}
																					</select>
																				</div>
																			</div>
																			<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																				<div>
																					<label
																						htmlFor="formas_de_pago"
																						className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																					>
																						Formas de Pago
																					</label>
																				</div>
																				<div className="sm:col-span-2">
																					<select
																						onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'forma_pago', e.target.value) }}
																						id="formas_de_pago"
																						name="formas_de_pago"
																						className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																						value={inputsFacturarVenta.forma_pago}
																					>
																						{
																							formas_pago.map((t, e) => (
																								<option value={t.value}>{t.value} - {t.name}</option>
																							))
																						}
																					</select>
																				</div>
																			</div>
																			<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																				<div>
																					<label
																						htmlFor="metodos_de_pago"
																						className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																					>
																						Metodos de pago
																					</label>
																				</div>
																				<div className="sm:col-span-2">
																					<select
																						onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'metodo_pago', e.target.value) }}
																						id="metodos_de_pago"
																						name="metodos_de_pago"
																						className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																						value={inputsFacturarVenta.metodo_pago}
																					>
																						{
																							metodos_pago.map((t, e) => (
																								<option value={t.value}>{t.name}</option>
																							))
																						}
																					</select>
																				</div>
																			</div>
																			<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																				<div>
																					<label
																						htmlFor="correo1"
																						className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																					>
																						Correo electrónico
																					</label>
																				</div>
																				<div className="sm:col-span-2">
																					<input
																						onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'correo', e.target.value) }}
																						type="text"
																						name="correo1"
																						id="correo1"
																						className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																						placeholder="Ingresa el correo de envío"
																						required
																						value={inputsFacturarVenta.correo}
																					/>
																				</div>
																			</div>

																			{
																				inputsFacturarVenta.observaciones !== "" && (
																					<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																						<div>
																							<label
																								htmlFor="observaciones"
																								className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																							>
																								Observaciones
																							</label>
																						</div>
																						<div className="sm:col-span-2">
																							<div>
																								<label
																									htmlFor="observaciones"
																									className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																								>
																									{inputsFacturarVenta.observaciones}
																								</label>
																							</div>
																						</div>
																					</div>
																				)
																			}

																		</>
																	)
																}
															</>
														)
													}

													{
														selectedTab === "2" && (
															<>

																{
																	/*
																	<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="tipo_comprobante_fiscal"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Tipo de comprobante fiscal
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'tipo_comprobante_fiscal', e.target.value) }}
																			id="tipo_comprobante_fiscal"
																			name="tipo_comprobante_fiscal"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			value={inputsFacturarVenta.tipo_comprobante_fiscal}
																		>
																			<option value="I">Ingreso</option>
																			<option value="E">Egreso</option>
																			<option value="T">Traslado</option>
																			<option value="N">Investigar</option>
																			<option value="P">Investigar</option>
																		</select>
																	</div>
																</div>
																*/
																}
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="uso_cfdi"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Uso de CFDI
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'uso_cfdi', e.target.value) }}
																			id="uso_cfdi"
																			name="uso_cfdi"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																			required
																			value={inputsFacturarVenta.uso_cfdi}
																		>
																			{
																				arrUsoCFDI.map((t, e) => (
																					<option value={t.value}>{t.value} - {t.name}</option>
																				))
																			}
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="formas_de_pago"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Formas de Pago
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'forma_pago', e.target.value) }}
																			id="formas_de_pago"
																			name="formas_de_pago"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			value={inputsFacturarVenta.forma_pago}
																		>
																			{
																				formas_pago.map((t, e) => (
																					<option value={t.value}>{t.name}</option>
																				))
																			}
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="metodos_de_pago"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Metodos de pago
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'metodo_pago', e.target.value) }}
																			id="metodos_de_pago"
																			name="metodos_de_pago"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			value={inputsFacturarVenta.metodo_pago}
																		>
																			{
																				metodos_pago.map((t, e) => (
																					<option value={t.value}>{t.name}</option>
																				))
																			}
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="tipo_persona"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Tipo de persona
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'tipo_persona', e.target.value) }}
																			id="tipo_persona"
																			name="tipo_persona"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																			required
																			value={inputsFacturarVenta.tipo_persona}
																		>
																			<option value="1">Persona física</option>
																			<option value="2">Persona física con actividad empresarial</option>
																			<option value="3">Persona moral</option>
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="regimen_fiscal"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Régimen fiscal
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<select
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'regimen_fiscal', e.target.value) }}
																			id="regimen_fiscal"
																			name="regimen_fiscal"
																			className="mt-2 block w-full rounded-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-red-600 sm:text-sm sm:leading-6"
																			required
																			value={inputsFacturarVenta.regimen_fiscal}
																		>
																			{
																				arrRegimenFiscal.map((t, e) => (
																					<option value={t.value}>{t.name}</option>
																				))
																			}
																		</select>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="rfc"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			RFC
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'rfc', e.target.value) }}
																			type="text"
																			name="rfc"
																			id="rfc"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el rfc"
																			required
																			value={inputsFacturarVenta.rfc}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="razon_social"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Razón	social
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'razon_social', e.target.value) }}
																			type="text"
																			name="razon_social"
																			id="razon_social"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la razón social"
																			required
																			value={inputsFacturarVenta.razon_social}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="correo"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Correo electrónico
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'correo', e.target.value) }}
																			type="text"
																			name="correo"
																			id="correo"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el correo electrónico"
																			value={inputsFacturarVenta.correo}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="calle"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Calle
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'calle', e.target.value) }}
																			type="text"
																			name="calle"
																			id="calle"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el nombre de la calle"
																			required
																			value={inputsFacturarVenta.calle}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="no_exterior"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Número exterior
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'no_exterior', e.target.value) }}
																			type="text"
																			name="no_exterior"
																			id="no_exterior"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el número exterior"
																			required
																			value={inputsFacturarVenta.no_exterior}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="no_interior"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Número interior
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'no_interior', e.target.value) }}
																			type="text"
																			name="no_interior"
																			id="no_interior"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el número interior"
																			value={inputsFacturarVenta.no_interior}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="colonia"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Colonia
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'colonia', e.target.value) }}
																			type="text"
																			name="colonia"
																			id="colonia"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la colonia"
																			required
																			value={inputsFacturarVenta.colonia}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="cp"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			CP
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'cp', e.target.value) }}
																			type="text"
																			name="cp"
																			id="cp"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el código postal"
																			required
																			value={inputsFacturarVenta.cp}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="localidad"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Localidad
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'localidad', e.target.value) }}
																			type="text"
																			name="localidad"
																			id="localidad"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa la localidad"
																			required
																			value={inputsFacturarVenta.localidad}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="municipio"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Municipio
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'municipio', e.target.value) }}
																			type="text"
																			name="municipio"
																			id="municipio"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el municipio"
																			required
																			value={inputsFacturarVenta.municipio}
																		/>
																	</div>
																</div>
																<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
																	<div>
																		<label
																			htmlFor="estado"
																			className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
																		>
																			Estado
																		</label>
																	</div>
																	<div className="sm:col-span-2">
																		<input
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'estado', e.target.value) }}
																			type="text"
																			name="estado"
																			id="estado"
																			className="block w-full rounded-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			placeholder="Ingresa el estado"
																			required
																			value={inputsFacturarVenta.estado}
																		/>
																	</div>
																</div>
																<div className="space-y-2 py-5 px-5">
																	<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
																		Observaciones
																	</label>
																	<div className="mt-2">
																		<textarea
																			onChange={(e) => { F.handleChangeInputMulti(state, setState, _this, 'inputsFacturarVenta', 'observaciones', e.target.value) }}
																			rows={4}
																			name="observaciones"
																			id="observaciones"
																			placeholder="Ingresa una observación"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
																			value={inputsFacturarVenta.observaciones}
																		/>
																	</div>
																</div>
															</>
														)
													}

												</div>
											</div>

											{/* Action buttons */}
											<div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
												<div className="flex justify-end space-x-3">
													<button
														type="button"
														className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
														onClick={() => F.handleAccionarSlider(state, setState, _this, "openSliderFacturarVenta", false)}
													>
														Cancelar
													</button>
													<button
														type="button"
														className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
														onClick={() => { F.facturarVenta(state, setState, _this, 1) }}
													>
														Emitir factura
													</button>
													{
														selectedTab === "2" && (
															<button
																type="button"
																className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
																onClick={() => { F.facturarVenta(state, setState, _this, 2) }}
															>
																Emitir y registrar
															</button>
														)
													}
												</div>
											</div>
										</form>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}