import React from "react";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import moment from 'moment'
import * as F from './admin.functions';
import * as view from './admin.views';

moment.locale('es');

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

class _Index extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    F.initData(this.props.initState, this.props.initsetState, this)
  }

  render() {
    return (
      <div>
        {view.render__homeAdmin(this.props.initState, this.props.initsetState, this)}
      </div>
    );
  }
}

class _PorAutorizar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    F.obtenerHistorialDeVentasPorAutorizar(this.props.initState, this.props.initsetState, this)
  }

  render() {
    return (
      <div>
        {view.render__porAutorizar(this.props.initState, this.props.initsetState, this)}
      </div>
    );
  }
}

class _Inventarios extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    F.obtenerSucursales(this.props.initState, this.props.initsetState, this)
  }

  render() {
    return (
      <div>
        {view.render__Inventarios(this.props.initState, this.props.initsetState, this)}
      </div>
    );
  }
}

class _Creditos extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    F.obtenerSucursales(this.props.initState, this.props.initsetState, this)
    F.obtenerCreditos(this.props.initState, this.props.initsetState, this)
  }

  render() {
    return (
      <div>
        {view.render__Creditos(this.props.initState, this.props.initsetState, this)}
      </div>
    );
  }
}

class _Configuraciones extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    F.obtenerInformacionConfiguraciones(this.props.initState, this.props.initsetState, this)
  }

  render() {
    return (
      <div>
        {view.render__Configuraciones(this.props.initState, this.props.initsetState, this)}
      </div>
    );
  }
}

class _OrdenesAutorizaciones extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { typeAuth, id_orden } = this.props.router.params
    console.log(typeAuth, id_orden)

    F.realizarAccionSobreOrden(typeAuth, id_orden)
  }

  render() {
    const { typeAuth } = this.props.router.params
    return (
      <div>
        <p>Orden {typeAuth === "autorizar" ? "autorizada" : "rechazada"} con éxito.</p>
      </div>
    );
  }
}

const Route_Admin = withRouter(_Index)
const Route_AdminPorAutorizar = withRouter(_PorAutorizar)
const Route_AdminInventarios = withRouter(_Inventarios)
const Route_AdminCreditos = withRouter(_Creditos)
const Route_AdminConfiguraciones = withRouter(_Configuraciones)
const Route_AdminOrdenAutorizaciones = withRouter(_OrdenesAutorizaciones)

export {
  Route_Admin,
  Route_AdminPorAutorizar,
  Route_AdminInventarios,
  Route_AdminCreditos,
  Route_AdminConfiguraciones,
  Route_AdminOrdenAutorizaciones,
}